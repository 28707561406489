import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { WorksContext } from "../../providers/works";

const Signup = (props) => {
  const [searchParam,setSearchParam]=useState({
    senderId: null,
    transferCode: null
  });
  const {transfer_code}=useContext(WorksContext)
  const location = useLocation();
  const {
    formik,
    setEnableSignUp,
    invitedByName,
    setHasSenderId,
    setHasTransferCode,
    getCertificateData
  } = props;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const senderId = urlParams.get("sender_id");
    const transferCode = urlParams.get("transfer_code");

    if (senderId) {
      setSearchParam(prevState => ({
        ...prevState,
        senderId
      }));
    }

    if (transferCode) {
      // localStorage.setItem('transfer_code', transferCode)
      setSearchParam(prevState => ({
        ...prevState,
        transferCode
      }));
    }
    console.log("transfer",transfer_code)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Optionally save the current path in localStorage or state management
    localStorage.setItem('prevPath', location.pathname);
  }, [location]);
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      sx={{
        mt: 1,
      }}
    >
      <Box style={{ textAlign: "center" }} m={3} fontSize="1.7rem">
        
      </Box>
      {searchParam.transferCode !== null && (
        <>
         <Typography align="center">
          <strong>{getCertificateData[0]?.holder_name}</strong><br />
          {" wants to transfer to you the Artis.app Certificate Of Authenticity for: "}
          <strong><br /><br />{getCertificateData[0]?.work_name}</strong><br />
          {"by "}<br />
          {getCertificateData[0]?.collaborators
            .map((collaborator) => collaborator?.collaborator_name)
            .join(", ")}<br /><br />
        </Typography>
   
        <Typography align="center">
          To accept and to have your name placed
          on it as the holder, sign up for a free Artis.app&nbsp;account... or log in below.
        </Typography>
        </>
      )}
     {searchParam.senderId !== null && (
        <Typography align="center">
          <strong>{invitedByName}</strong> <br />invited you to join their creative community on Artis.app. If you don't have an account, fill out your name and email to start the sign up. Or if you have an account, sign in below.
        </Typography>
      )}
      <TextField
        variant="standard"
        style={{ textTransform: "none" }}
        margin="normal"
        required
        fullWidth
        id="name"
        label="Your Name"
        name="name"
        autoComplete="name"
        value={formik.values.name}
        onChange={(e) => {
          formik.setFieldValue('name', e.target.value.toLowerCase());
        }}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        InputProps={{
          style: {
            fontSize: "1.3rem",
          },
        }}
      />
      <TextField
        variant="standard"
        style={{ textTransform: "none" }}
        margin="normal"
        required
        fullWidth
        InputProps={{
          style: {
            fontSize: "1.3rem",
          },
        }}
        id="email"
        label="Your email"
        name="email"
        autoComplete="email"
        value={formik.values.email}
        onChange={(e) => {
          formik.setFieldValue('email', e.target.value.toLowerCase());
        }}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        InputLabelProps={{ required: false, sx: { color: "#9E9E9E" } }}
      />
      <Button
        type="submit"
        className="normal"
        fullWidth
        style={{
          backgroundColor: "transparent",
          fontSize: "1.5em",
          hover: "none",
          color: "teal",
          textTransform: "none",
          fontFamily: "Bellefair, serif",
        }}
        variant="text"
        sx={{ mt: 3, mb: 2 }}
      >
        Begin Sign Up
      </Button>
      <Button
        className="normal"
        fullWidth
        style={{
          backgroundColor: "transparent",
          fontSize: "1.5em",
          hover: "none",
          color: "#03B6EF",
          textTransform: "none",
          fontFamily: "Bellefair, serif",
        }}
        variant="text"
        sx={{ mb: 2 }}
        onClick={() => {
          setEnableSignUp(false);
          setHasSenderId(false);
          setHasTransferCode(false);
        }}
      >
        Already a member? Click here to log in.
      </Button>
    </Box>
  );
}

export default Signup;
