import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Slide from "@mui/material/Slide";
import LinearProgress from "@mui/material/LinearProgress";
import { Typography, Modal } from "@mui/material";

import WorkService from "../api/service";
import UserService from "../api/auth";
import ArtisFooter from "../components/footer";
import DetailLoadingBox from "../components/work/detail-box";
import DetailedNoFound from "../components/work/detail-nofound";
import useCheckMobileScreen from "../hooks/isMobile";

import utils from "../utils";
import { FameUserItemWrapper } from "../components/tabs/FameUserItem";
import Bio from "../components/work/edit/collapsibles/artist-bio";
import Fade from "@mui/material/Fade";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Sell from "../components/work/edit/collapsibles/sell";
import Transfer from "../components/work/edit/collapsibles/transfer";
import Settings from "../components/work/edit/collapsibles/settings";
import LinksAndQrCodes from "../components/work/edit/collapsibles/LinksAndQrCodes";
import Exhibition from "../components/work/edit/collapsibles/exhibition";

import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CertDetails(props) {
  const isMobile = useCheckMobileScreen(505);

  const [work, setWork] = useState(null);
  const [profile, setProfile] = useState(null);
  const [openId, setOpenId] = useState("");
  const [connections, setConnections] = useState(null);
  const [allCollabs, setAllCollabs] = useState(null);
  const [isExist, setIsExist] = useState(null);
  const [allCollabsbyWork, setAllCollabsbyWork] = useState([]);
  const { artis_code } = useParams();
  const [allCommunity, setAllCommunity] = useState([]);
  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState("false");
  const [incollection, setIncollection] = useState([]);
  const [inCertificates, setInCertificates] = useState([]);
  const [certificateHolderFameData, setCertificateHolderFameData] = useState(
    {}
  );
  const [allcertificateHoldersFameData, setAllCertificatesHolderFameData] =
    useState([]);
  const [certificateHolderIndex, setCertificateHolderIndex] = useState(-1);
  const [certificateHolderConnections, setCertificateHolderConnections] =
    useState([]);
  const location = useLocation();

  const [workCollaboratorsExhibitions, setWorkCollaboratorsExhibitions] = useState({})

  // Manually extract the value after "?"
  const lastValue = location.search ? location.search.substring(1) : null;

  const getInCollection = async () => {
    try {
      // get incollection data using artis-code
      const response = await WorkService.getInCollection({
        artis_code: artis_code,
      });
      setIncollection(response.result);
    } catch (error) {
      console.error("Error fetching collections:", error);
    }
  };

  useEffect(() => {
    getInCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artis_code]);

  const fetchWork = async (artis_code) => {
    try {
      const response = await WorkService.getWork(artis_code);
      const { work, user } = response?.result;
      return {
        work,
        user,
      };
    } catch (error) {}
  };

  const fetchFameData = async (query, id, method) => {
    try {
      const response = await UserService.getFameConnections(query);
      if (response.result) {
        const verified_connections = [];
        const unverified_connections = [];
        
        if (method === "detail") {
          var all_community_detail = [];

          let toUpdate = {}
          toUpdate[id] = response.result.exhibitions
          setWorkCollaboratorsExhibitions(toUpdate)

          response.result.all_fame.forEach((fame) => {
            if (fame.status === 4) {
              return;
            }

            const customerUser =
              id === fame?.sender[0].user_id
                ? fame?.invitee[0]
                : fame?.sender[0];            

            if(Number(id) !== customerUser.id) {
              if (customerUser.is_kyced) {
                verified_connections.push({ ...fame, user: customerUser });
              } else {
                unverified_connections.push({ ...fame, user: customerUser });
              }
            }

          });

          const filteredVerifiedNoOrder = verified_connections.filter(
            (v) => v.order === 0
          );

          const orderedVerified = verified_connections
            .filter((c) => c.order !== 0)
            .sort((a, b) => a.order - b.order);

          const result = orderedVerified.concat(filteredVerifiedNoOrder);

          all_community_detail.push({
            verified_connections: result,
            unverified_connections,
            works_known_for: response.result.works_known_for,
            collaborators_known_for: response.result.collaborators_known_for,
          });

          setConnections({
            verified_connections: result,
            unverified_connections,
          });

          setWorkCollaboratorsExhibitions(toUpdate)
          setAllCommunity(all_community_detail);
        } 
        else {
          var ids = id.split(",");
          var all_community = [];
          let toUpdate = workCollaboratorsExhibitions

          ids.forEach((id) => {
            var verified_connections = [];
            var unverified_connections = [];

            toUpdate[id] = response.result[id].exhibitions

            response.result[id].all_fame.forEach((fame) => {
              if (fame.status === 4) {
                return;
              }

              const customerUser =
                parseInt(id) === fame?.sender[0].user_id
                  ? fame?.invitee[0]
                  : fame?.sender[0];

             if(Number(id) !== customerUser.id) {
              if (customerUser.is_kyced) {
                verified_connections.push({ ...fame, user: customerUser });
              } else {
                unverified_connections.push({ ...fame, user: customerUser });
              }
             }
            });

            const filteredVerifiedNoOrder = verified_connections.filter(
              (v) => v.order === 0
            );

            const orderedVerified = verified_connections
              .filter((c) => c.order !== 0)
              .sort((a, b) => a.order - b.order);
            const result = orderedVerified.concat(filteredVerifiedNoOrder);

            all_community.push({
              verified_connections: result,
              unverified_connections,
              works_known_for: response.result[id].works_known_for,
              collaborators_known_for:
                response.result[id].collaborators_known_for,
            });

          });

          setAllCommunity(all_community);
        }
      }
    } catch (error) {}
  };

  const getCertificates = async (param) => {
    try {
      const response = await WorkService.getCertificatesDetails(
        param,
        artis_code
      );
      setInCertificates(response.result);
      // console.log("response :", response.result)
    } catch (error) {
      console.log("Error fetching collection :", error);
    }
  };

  useEffect(() => {
    if (lastValue) {
      getCertificates(lastValue, artis_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastValue, artis_code]);

  // Get fame data for certificate holder
  useEffect(() => {
    if (inCertificates && inCertificates.length > 0) {
      const fetchFameConnections = async () => {
        try {
          const response = await UserService.getFameConnections(
            `?q=detail&id=${inCertificates[0].holder_id}`
          );
          setCertificateHolderFameData(response.result);
        } catch (error) {
          console.log("Error fetching fame connections:", error);
        }
      };
      fetchFameConnections();
    }
  }, [inCertificates]);

  useEffect(() => {
    if (
      inCertificates &&
      inCertificates.length > 0 &&
      certificateHolderFameData &&
      Object.keys(certificateHolderFameData).length > 0
    ) {
      for (let i = 0; i < certificateHolderFameData.all_fame.length; i++) {
        if (
          certificateHolderFameData.all_fame[i].invitee[0].user_id ===
          inCertificates[0].holder_id
        ) {
          setCertificateHolderIndex(i);
          break;
        }
      }

      let allConnections = [];
      certificateHolderFameData.all_fame.forEach((fame) => {
        if
        (
          fame.invitee[0].user_id !== fame.sender[0].user_id
        ) {

         if(fame.status !== 4) {
            if (fame.sender[0].user_id === inCertificates[0].holder_id) {
              fame.invitee[0]["endorsed"] = false
              allConnections.push(fame.invitee[0]);
            } 
            else {
              fame.sender[0]["endorsed"] = false
              allConnections.push(fame.sender[0]);
            }
         }
        }
      });

      allConnections.sort((a,b) => b.is_kyced - a.is_kyced)
      setCertificateHolderConnections(allConnections);
    }

  }, [inCertificates, certificateHolderFameData]);

  useEffect(() => {
    if (inCertificates && inCertificates.length > 0) {
      const fetchCertificateHoldersFameData = async () => {
        try {
          let commaSeparatedIds = inCertificates[0].provinance
            .map((element) => element.buyer_id)
            .join(",");

          const response = await UserService.getFameConnections(
            `?q=multiple&id=${commaSeparatedIds}`
          );

          setAllCertificatesHolderFameData(Object.entries(response.result));
        } catch (error) {
          console.log("Error fetching fame connections:", error);
        }
      };
      fetchCertificateHoldersFameData();
    }
  }, [inCertificates]);

  useEffect(() => {
    if (
      inCertificates &&
      inCertificates.length > 0 &&
      allcertificateHoldersFameData &&
      allcertificateHoldersFameData.length > 0
    ) {
      let updated = false;

      for (let j = 0; j < inCertificates[0].provinance.length; j++) {
        // eslint-disable-next-line no-loop-func
        allcertificateHoldersFameData.forEach((fameData, key) => {
          for (let index = 0; index < fameData[1].all_fame.length; index++) {
            const item = fameData[1].all_fame[index];

            if (
              item.invitee[0].user_id === inCertificates[0].provinance[j].buyer_id
            ) {
              inCertificates[0].provinance[j]["famedata"] = item;

              inCertificates[0].provinance[j].famedata["works_known_for"] =
                fameData[1].works_known_for;

              inCertificates[0].provinance[j].famedata["all_fame"] =
                fameData[1].all_fame;
              updated = true;

              inCertificates[0].provinance[j].famedata["exhibitions"] =
                fameData[1].exhibitions;
              updated = true;

              break;
            }
          }
        });
      }
      inCertificates[0].provinance.sort((a, b) => b.id - a.id);

      if (updated) {
        setInCertificates(inCertificates);
      }
    }
  }, [inCertificates, allcertificateHoldersFameData]);

  useEffect(() => {
    if (
      inCertificates &&
      inCertificates.length > 0 &&
      allcertificateHoldersFameData &&
      allcertificateHoldersFameData.length > 0
    ) {
      const updatedFameData = [...allcertificateHoldersFameData];
      let updated = false;

      for (let j = 0; j < inCertificates[0].provinance.length; j++) {
        let creativeCommunity = [];
        // eslint-disable-next-line no-loop-func
        updatedFameData.forEach((fameData, key) => {

          if (
            Number(fameData[0]) === inCertificates[0].provinance[j].buyer_id
          ) {
            for (let index = 0; index < fameData[1].all_fame.length; index++) {
              const item = fameData[1].all_fame[index];

              if(item.invitee[0].user_id === item.sender[0].user_id) continue
              if(item.status === 4) continue

              if (
                item.sender[0].user_id === inCertificates[0].provinance[j].buyer_id
              ) {
                item.invitee[0]["endorsed"] = false
                creativeCommunity.push(item.invitee[0]);
                updated = true;
              } else {
                item.sender[0]["endorsed"] = false
                creativeCommunity.push(item.sender[0]);
                updated = true;
              }
            }
          }
        });

        if (creativeCommunity.length > 0) {
          creativeCommunity.sort((a,b) => b.is_kyced - a.is_kyced)
          inCertificates[0].provinance[j].famedata["community"] =
            creativeCommunity;
          creativeCommunity = [];
        }
      }

      if (updated) {
        setInCertificates(inCertificates);
      }
    }
  }, [inCertificates, allcertificateHoldersFameData]);

  useEffect(() => {
    WorkService.lookup(artis_code)
      .then((res) => {
        if (res.hasOwnProperty("result")) {
          fetchWork(artis_code)
            .then(({ work, user }) => {
              setWork(work);
              setProfile(user);
              setIsExist(work.registered_on_blockchain);
              document.title = "Artis.app | " + work?.title;
            })
            .catch((err) => {});
        } else {
          console.error("this is error");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [artis_code]);

  const fetchCollabs = async () => {
    const response = await WorkService.getCollaboratorStatus();
    const sendbody = {
      work_id: work.id,
      artis_code: work.artis_code,
      emails: work.emails,
    };
    const response_allcollabyId = await WorkService.getCollaboratorById(
      sendbody
    );
    if (response_allcollabyId.result) {
      var allCollabsbyWork = response_allcollabyId.result;

      if (allCollabsbyWork.length === 1) {
        const myprofile = allCollabsbyWork[0].profile;
        const query = `?q=detail&id=${myprofile.user_id}`;
        fetchFameData(query, myprofile.user_id, "detail");
      } else {
        var ids = allCollabsbyWork[0].profile.user_id;

        for (var i = 1; i < allCollabsbyWork.length; i++) {
          var collaborator = allCollabsbyWork[i];
          ids += "," + collaborator.profile.user_id;
        }
        const query = `?q=multiple&id=${ids}`;
        fetchFameData(query, ids, "multiple");
      }
      setAllCollabsbyWork(allCollabsbyWork);
    }
    if (response.result) {
      const getWork = response.result.filter((res) => res.work.id === work.id);

      const getUserWork = getWork.filter(
        (w) =>
          w.registered_by_id === profile.user.id &&
          !w.work.rejected_collab &&
          w.signed
      );

      setAllCollabs(getUserWork);
    }
  };

  const handlePutOnSale = async (price, saleDescription) => {
    const response = await WorkService.updateCertificateSale({
      sale_price: price,
      sale_description: saleDescription,
      cert_id: lastValue,
      on_sale: true,
    });

    if (response?.result) {
      setInCertificates((prevInCertificates) => {
        const updatedCertificates = [...prevInCertificates];
        updatedCertificates[0] = {
          ...updatedCertificates[0],
          sale_price: response.result.sale_price,
          on_sale: response.result.on_sale,
          sale_description: response.result.sale_description,
          transfer_code: response.result.transfer_code,
        };
        toast.success("Successfully put Certificate on Sale!");
        return updatedCertificates;
      });
      return true;
    }

    return false;
  };

  const handleSale = async (buyer, email) => {
    const response = await WorkService.updateCertificateSale({
      sale_price: buyer,
      sale_description: email,
      cert_id: lastValue,
      on_sale: true,
    });
    if (response?.result) {
      await WorkService.transferCerticate({
        cert_id: lastValue,
        on_transfer: true,
      });
      return true; // Transfer was successful
    }
    return false;
  };

  const handleCancelSale = async () => {
    const response = await WorkService.updateCertificateSale({
      sale_price: null,
      sale_description: null,
      cert_id: lastValue,
      on_sale: false,
    });
    if (response?.result) {
      setInCertificates((prevInCertificates) => {
        const updatedCertificates = [...prevInCertificates];
        updatedCertificates[0] = {
          ...updatedCertificates[0],
          sale_price: response.result.sale_price,
          on_sale: response.result.on_sale,
          sale_description: response.result.sale_description,
          transfer_code: response.result.transfer_code,
        };
        return updatedCertificates;
      });
      toast.success("Certificate sale Cancelled!");
      return true;
    }
    return false;
  };

  const onTransferRequestFail = (error) => {
    toast.error(`Certificate is not on Sale!`);
  };

  useEffect(() => {
    if (work && profile) {
      fetchCollabs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [work, profile]);

  if (!work || !profile) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  const handleOpenImage = (value) => {
    setImage(value);
    setOpenImage(true);
  };

  return (
    <div>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Container maxWidth="sm">
          {isExist === false && (
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
              <Container maxWidth="sm">
                <DetailedNoFound isMobile={isMobile} />
                <br />
                <br />
                <br />
                <br />
                <br />
                <ArtisFooter />
              </Container>
            </Slide>
          )}
          {isExist && (
            <DetailLoadingBox
              proof={"This digital Artis.app CERTIFICATE OF AUTHENTICITY was recorded on the blockchain, creating an unalterable and independent proof of who created this certificate, what work the certificate covers, who currently holds this certificate, and the history of the Certificate holders. This record is available instantly and world wide."}
              isMobile={isMobile}
              from={'cert'}
            />
          )}
          {isExist && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "background.paper",
                overflow: "hidden",
                boxShadow: 1,
                fontWeight: "bold",
              }}
            >
              <Typography>
                <center>
                  {work?.work_type === "Photograph" &&
                    work?.work_image.image && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => handleOpenImage(work?.work_image?.image)}
                      >
                        <Box
                          component="img"
                          alt={work?.description}
                          sx={{
                            width: "auto",
                            maxHeight: "70vh",
                            cursor: "pointer",
                          }}
                          src={work.work_image.image}
                        />
                      </div>
                    )}
                </center>
              </Typography>
              <Box
                component="p"
                sx={{
                  fontFamily: "Bellefair, serif",
                  fontSize: "1.5rem",
                  fontStyle: "italic",
                  fontWeight: "normal",
                }}
              >
                <center>{work?.work_type}</center>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  m: 3,
                  minWidth: { md: 350 },
                }}
              >
                {inCertificates?.length > 0 &&
                  inCertificates[0]?.on_sale === true && (
                    <>
                      {/* {work.sale_description && ( */}
                      <Box
                        component="div"
                        style={{
                          paddingTop: 20,
                          paddingBottom: 12,
                          borderRadius: 10,
                          minHeight: 50,
                          backgroundColor: "#e0e0e0",
                          width: "60%",
                          display: "flex",
                          flexFlow: "column wrap",
                          justifyContent: "center",
                          alignItems: "center",
                          wordBreak: 'break-word',
                          whiteSpace: 'pre-wrap',
                          textAlign: 'center'
                        }}
                        sx={{
                          fontSize: "1.3rem",
                          mt: 1,
                          fontFamily: "Bellefair, serif",
                          fontWeight: 400,
                          backgroundColor: "grey",
                        }}
                      >
                        <Box
                          // component="span"
                          color="green"
                          sx={{
                            fontSize: "2rem",
                            mt: 1,
                            fontFamily: "Bellefair, serif",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            // component="span"
                            color="gray"
                            sx={{
                              fontSize: "2.5rem",
                              alignItems: "center",
                              lineHeight: '1.8rem'
                            }}
                          >
                            FOR SALE
                            <br />
                            <br />
                          </Box>
                          <div>
                            <center>{inCertificates[0]?.sale_price}</center>
                          </div>
                        </Box>
                        <Box
                          // component="span"
                          sx={{
                            fontSize: "1.5rem",
                            color:"gray",
                            marginTop: '0.3rem',
                            padding: '0.5rem'
                          }}
                        >
                          {inCertificates[0]?.sale_description}
                        </Box>
                      </Box>
                      {/* // )} */}
                    </>
                  )}

                <Box
                  component="h1"
                  sx={{
                    fontSize: "2.3rem",
                    mt: 1,
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  <center>{work?.title}</center>
                </Box>
                <Box
                  sx={{
                    fontSize: isMobile ? "1.2rem" : "1.5rem",
                    fontWeight: "normal",
                    fontFamily: "Bellefair, serif",
                    display: "flex",
                    flexFlow: "column wrap",
                    alignItems: "center",
                  }}
                >
                  <center>
                      <span style={{ whiteSpace: "normal" }}>
                        {allCollabsbyWork.length > 0 && <>© {work?.copyright_date?.slice(0, 4)} </>}

                        {allCollabsbyWork?.map((col, index) => (
                          col?.collaborator.oncopyright ? (
                            <span key={index}>
                              {col.profile.first_name} {col.profile.last_name}
                              {col.collaborator.is_kyced && (
                                <img
                                  style={{ height: 10, marginLeft: 1 }}
                                  alt="identity validated"
                                  src="/images/GoldWreath.jpg"
                                />
                              )}
                              
                              {index !== (allCollabsbyWork?.length-1) && allCollabsbyWork[index + 1]?.collaborator.oncopyright && ", "}
                            </span>
                          ) : null
                        ))}
                      </span>  
                  </center>
                </Box>
                {connections?.ordered_verified_connections?.map((con) => {
                  return (
                    <>
                      <Box
                        sx={{
                          fontSize: "0.5rem",
                          fontWeight: "normal",
                          fontFamily: "Bellefair, serif",
                          display: "flex",
                          color: "grey",
                          flexFlow: "row wrap",
                          alignItems: "center",
                        }}
                      >
                        Validated by
                      </Box>
                      <Box
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: "normal",
                          fontFamily: "Bellefair, serif",
                          color: "grey",
                          display: "flex",
                          flexFlow: "row wrap",
                          alignItems: "center",
                        }}
                      >
                        {con.invitee_name}
                      </Box>
                    </>
                  );
                })}

                {work?.artist_statement && (
                  <>
                    <Box
                      component="span"
                      color="gray"
                      fontFamily="Montserrat, sans-serif"
                    >
                      <br />
                      {work?.artist_type || "Artist "} Statement:
                    </Box>
                    <Box
                      component="p"
                      color="black"
                      sx={{
                        fontFamily: "Montserrat, sans-serif",
                        fontSize: "1.2em",
                        fontWeight: "normal",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {work?.artist_statement}
                    </Box>
                  </>
                )}
                {incollection.length > 0 && (
                  <>
                    <Box
                      component="span"
                      color="gray"
                      fontFamily="Montserrat, sans-serif"
                    >
                      <br />
                      {incollection.length > 1 ? "collections" : "collection"}
                    </Box>

                    {incollection.map((collection) => (
                      <Box
                        component="p"
                        color="black"
                        sx={{
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: "1.2em",
                          fontWeight: "normal",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {collection.collection_details.name}
                      </Box>
                    ))}
                  </>
                )}
              </Box>
              <center>
               <Box
                  component="h5"
                  color="gray"
                  fontFamily="Montserrat, sans-serif"
                  margin={0} // Removes margin around the first box
                >
                  CERTIFICATE {inCertificates[0]?.cert_num} OF{" "}
                  {inCertificates[0]?.num_of_certs}
                </Box>
                <Box
                  component="h6"
                  color="gray"
                  fontFamily="Montserrat, sans-serif"
                  fontSize="80%" // Makes the text 80% the size of the first phrase
                  margin={0} // Removes margin around the second box
                  lineHeight={1} // Ensures minimal vertical space
                >
                  ISSUED FOR THIS WORK
                </Box>
              </center>
              <br />
              <Box
                component="p"
                display="inline"
                color="gray"
                sx={{ marginLeft: "15px", textAlign: "center" }}
              >
                {"Certificate Holder"}
              </Box>

              {certificateHolderIndex !== -1 &&
                certificateHolderFameData &&
                Object.keys(certificateHolderFameData).length > 0 && (
                  <>
                    <Bio
                      openId={openId}
                      setOpenId={setOpenId}
                      collaborator_id={""}
                      contribution={
                        certificateHolderFameData.all_fame[
                          certificateHolderIndex
                        ]?.invitee[0]?.artist_type
                      }
                      bio={
                        certificateHolderFameData.all_fame[
                          certificateHolderIndex
                        ]?.invitee[0]?.artist_bio || ""
                      }
                      isEdit={false}
                      is_kyced={
                        certificateHolderFameData.all_fame[
                          certificateHolderIndex
                        ]?.invitee[0]?.is_kyced
                      }
                      website={
                        certificateHolderFameData.all_fame[
                          certificateHolderIndex
                        ]?.invitee[0]?.artist_website || ""
                      }
                      photo={
                        certificateHolderFameData.all_fame[
                          certificateHolderIndex
                        ]?.invitee[0]?.photo
                          ? `https://artis-stage-data.s3.amazonaws.com/static/${
                              certificateHolderFameData.all_fame[
                                certificateHolderIndex
                              ].invitee[0].photo.split("static/")[1]
                            }`
                          : ""
                      }
                      first_name={
                        certificateHolderFameData.all_fame[
                          certificateHolderIndex
                        ]?.invitee[0]?.first_name?.toUpperCase() || ""
                      }
                      last_name={
                        certificateHolderFameData.all_fame[
                          certificateHolderIndex
                        ]?.invitee[0]?.last_name?.toUpperCase() || ""
                      }
                      token={""}
                      handleBioUpdate={() => console.info("bio")}
                      artist_type={
                        certificateHolderFameData.all_fame[
                          certificateHolderIndex
                        ]?.invitee[0]?.artist_type
                      }
                      hiddenTitle
                      allCommunity={
                        <Box sx={{ marginLeft: 2.5, marginRight: 2.5 }}>
                          {(certificateHolderFameData.works_known_for?.length >
                            0 ||
                            certificateHolderFameData.collaborators_known_for
                              ?.length > 0) && (
                            <Box
                              component="p"
                              fontSize="0.8rem"
                              display="inline"
                              color="gray"
                              sx={{ marginLeft: "0px", marginBottom: "-8px" }}
                            >
                              KNOWN FOR
                            </Box>
                          )}
                          {certificateHolderFameData.works_known_for?.length >
                            0 &&
                            certificateHolderFameData.works_known_for?.map(
                              (work, wIndex) => {
                                return (
                                  <Box
                                    sx={{
                                      marginBottom: 1,
                                      padding: "5px 5px 5px 5px",
                                      borderBottom:
                                        certificateHolderFameData
                                          .works_known_for.length +
                                          certificateHolderFameData
                                            .collaborators_known_for?.length >
                                        1
                                          ? 1
                                          : 0,
                                      borderColor: "#D3D3D3",
                                      marginTop: wIndex === 0 ? 0 : 1,
                                      overflow: "auto",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexFlow: "row wrap",
                                        alignItems: "center",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          background: "none",
                                          border: "none",
                                          padding: "0",
                                          color: "#808080",
                                          cursor: "pointer",
                                          "&:hover": {
                                            textDecoration: "none",
                                            color: "#808080",
                                          },
                                          "&:focus": {
                                            textDecoration: "none",
                                            color: "#808080",
                                          },
                                        }}
                                        onClick={() =>
                                          window.open(
                                            `/works/${work.artis_code}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <>
                                          {work.work_copy_name ? (
                                            <img
                                              style={{
                                                marginRight: 15,
                                                objectFit: "cover",
                                                width: 120,
                                              }}
                                              src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(
                                                work.work_copy_name
                                              )}`}
                                              alt="profileimg"
                                            />
                                          ) : (
                                            <ArtTrackIcon />
                                          )}
                                        </>
                                         <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: 0,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.title}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#808080",
                                        marginBottom: 0,
                                        lineHeight: 1,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.description}
                                    </Typography>
                                      </button>
                                    </Box>
                                  </Box>
                                );
                              }
                            )}

                          {certificateHolderFameData.collaborators_known_for
                            ?.length > 0 &&
                            certificateHolderFameData.collaborators_known_for?.map(
                              (work, cIndex) => {
                                return (
                                  <Box
                                    sx={{
                                      marginBottom: 1,
                                      padding: "5px 5px 5px 5px",
                                      borderBottom:
                                        certificateHolderFameData
                                          .works_known_for.length +
                                          certificateHolderFameData
                                            .collaborators_known_for?.length >
                                        1
                                          ? 1
                                          : 0,
                                      borderColor: "#D3D3D3",
                                      marginTop:
                                        cIndex === 0 &&
                                        certificateHolderFameData
                                          .works_known_for?.length === 0
                                          ? 0
                                          : 1,
                                      overflow: "auto",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexFlow: "row wrap",
                                        alignItems: "center",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          background: "none",
                                          border: "none",
                                          padding: "0",
                                          color: "#808080",
                                          cursor: "pointer",
                                          "&:hover": {
                                            textDecoration: "none",
                                            color: "#808080",
                                          },
                                          "&:focus": {
                                            textDecoration: "none",
                                            color: "#808080",
                                          },
                                        }}
                                        onClick={() =>
                                          window.open(
                                            `/works/${work.work_artis_code}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <>
                                          {work.work_copy_name ? (
                                            <img
                                              style={{
                                                marginRight: 15,
                                                objectFit: "cover",
                                                width: 120,
                                              }}
                                              src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(
                                                work.work_copy_name
                                              )}`}
                                              alt="profileimg"
                                            />
                                          ) : (
                                            <ArtTrackIcon />
                                          )}
                                        </>
                                         <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: 0,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.title}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#808080",
                                        marginBottom: 0,
                                        lineHeight: 1,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.escription}
                                    </Typography>
                                      </button>
                                    </Box>
                                  </Box>
                                );
                              }
                            )}

                          {(certificateHolderFameData.works_known_for?.length >
                            0 ||
                            certificateHolderFameData.collaborators_known_for
                              ?.length > 0) && (
                            <Typography
                              fontSize="0.6rem"
                              gutterBottom
                              width="100%"
                              float="right"
                              color="gray"
                              textAlign="right"
                            >
                              TAP ON WORK TO SEE ITS REGISTRATION
                            </Typography>
                          )}

                          {certificateHolderFameData.all_fame[
                            certificateHolderIndex
                          ]?.endorsed_by?.split(",").length > 0 && certificateHolderFameData.all_fame[
                            certificateHolderIndex
                          ]?.endorsed_by !== "" && certificateHolderFameData.all_fame[
                            certificateHolderIndex
                          ]?.endorsed_by != null && (
                            <>
                              <Box
                                component="p"
                                fontSize="0.8rem"
                                display="inline"
                                color="gray"
                                sx={{ marginLeft: "0px" }}
                              >
                                <br />
                                PROFESSIONALLY ENDORSED BY
                              </Box>
                              {certificateHolderConnections.map(
                                (fame, index) => {
                                  const endorsedUsers =
                                    certificateHolderFameData.all_fame[
                                      certificateHolderIndex
                                    ]?.endorsed_by
                                      .split(",")
                                      .filter(
                                        (val) =>
                                          Number(val) === fame.id
                                      );

                                      if (endorsedUsers.length > 0) {
                                        fame.endorsed = true;
                                      
                                        return (
                                          <FameUserItemWrapper
                                            key={index}
                                            user={{
                                              ...fame,
                                              photo: fame.photo
                                                ? `https://artis-stage-data.s3.amazonaws.com/static/${
                                                    fame.photo.split("static/")[1]
                                                  }`
                                                : "",
                                            }}
                                            showOrder={false}
                                            name={`${fame.first_name} ${fame.last_name}`}
                                            is_kyced={fame.is_kyced}
                                            shouldExpand={true}
                                            userProfile={profile}
                                            fromEditOrDetail={true}
                                          />
                                        );
                                      }
                                      
                                    return null;
                                }
                              )}
                            </>
                          )}
                          {certificateHolderFameData.all_fame[
                            certificateHolderIndex
                          ]?.invitee[0]?.artist_bio && (
                            <>
                              <Box
                                component="p"
                                fontSize="0.8rem"
                                display="inline"
                                color="gray"
                                sx={{ marginLeft: "0px" }}
                              >
                                <br />
                                BIOGRAPHY
                              </Box>
                              <Box
                                component="p"
                                sx={{
                                  whiteSpace: "pre-wrap",
                                  fontSize: "1rem",
                                  fontFamily: "'Montserrat', sans-serif;",
                                  fontWeight: "normal",
                                  m: 0,
                                  textAlign: "left",
                                  mt: 0,
                                  fontStyle: "italic",
                                }}
                              >
                                {
                                  certificateHolderFameData.all_fame[
                                    certificateHolderIndex
                                  ].invitee[0].artist_bio
                                }
                              </Box>
                            </>
                          )}
                          {
                            certificateHolderFameData.exhibitions && Object.keys(certificateHolderFameData.exhibitions).length > 0 && 
                            <>
                                <Box
                                  component="p"
                                  fontSize="0.8rem"
                                  display="block"
                                  color="gray"
                                  sx={{ marginLeft: "0px", marginTop: 2.5, wordBreak: 'break-word' }}
                                >
                                  {certificateHolderFameData.exhibitions[0].eventname.toUpperCase()}
                                </Box>

                                {
                                  certificateHolderFameData.exhibitions.map((exhibition) => {
                                    return <Exhibition data={exhibition}/>
                                  })
                                }
                            </>
                          }
                          {
                            // Check if there are any valid records in COMMUNITY,
                            certificateHolderConnections &&
                            certificateHolderConnections.length > 0 && 
                            certificateHolderConnections.filter(item => item.endorsed === false).length > 0 && (
                                <>
                                  <Box
                                    component="p"
                                    fontSize="0.8rem"
                                    display="inline"
                                    color="gray"
                                    sx={{ marginLeft: "0px" }}
                                  >
                                    <br />
                                    COMMUNITY
                                  </Box>
                                  {certificateHolderConnections.map((fame) => {
                                    const customerUser = fame;
                                    const name =
                                      customerUser?.first_name &&
                                      customerUser?.last_name
                                        ? `${customerUser?.first_name} ${customerUser?.last_name}`
                                        : fame.invitee_name;

                                    if(!fame.endorsed) {
                                      return (
                                        <FameUserItemWrapper
                                          key={fame.id}
                                          user={{ ...customerUser }}
                                          showOrder={false}
                                          name={name}
                                          is_kyced={customerUser.is_kyced}
                                          order={fame.order}
                                          shouldExpand={true}
                                          userProfile={profile}
                                          fromEditOrDetail={true}
                                        />
                                      );
                                    }
                                    return null;
                                  })}
                                </>
                              )
                          }

                          {certificateHolderConnections.length > 0 && (
                            <Typography
                              fontSize="0.6rem"
                              gutterBottom
                              width="100%"
                              float="right"
                              color="gray"
                              textAlign="right"
                            >
                              TAP NAME TO DISPLAY MORE INFORMATION
                            </Typography>
                          )}
                        </Box>
                      }
                    />
                  </>
                )}
              <br />

              {allCollabsbyWork.length > 0 && (
                <Box
                  component="p"
                  display="inline"
                  color="gray"
                  sx={{ marginLeft: "15px", textAlign: "center" }}
                >
                  {allCollabsbyWork.length === 1
                    ? "Original Work Registered by"
                    : "Original Collaborators"}
                </Box>
              )}

              {allCollabsbyWork.length > 0 &&
                allCollabsbyWork &&
                allCollabsbyWork.map((data, index) => (
                  <Bio
                    openId={openId}
                    setOpenId={setOpenId}
                    collaborator_id={data?.profile?.id}
                    contribution={data?.collaborator?.contribution}
                    detail={true}
                    ownership={data?.collaborator?.ownership}
                    oncopyright={data?.collaborator?.oncopyright}
                    bio={data?.profile?.artist_bio}
                    isEdit={false}
                    is_kyced={data?.collaborator?.is_kyced}
                    website={data?.profile?.artist_website}
                    photo={
                      data?.profile?.photo
                        ? `https://artis-stage-data.s3.amazonaws.com/static/${data?.profile?.photo}`
                        : ""
                    }
                    first_name={data?.profile?.first_name.toUpperCase()}
                    last_name={data?.profile?.last_name.toUpperCase()}
                    token={""}
                    signed={
                      data.collaborator.hasOwnProperty("signed")
                        ? data.collaborator.signed
                        : true
                    }
                    handleBioUpdate={() => console.info("bio")}
                    artist_type={data?.collaborator?.contribution}
                    subCollabs={allCollabs}
                    onEditPage={false}
                    hiddenTitle
                    allCommunity={
                      <Box sx={{ marginLeft: 2.5, marginRight: 2.5 }}>
                        {(allCommunity[index]?.works_known_for?.length > 0 ||
                          allCommunity[index]?.collaborators_known_for?.length >
                            0) && (
                          <Box
                            component="p"
                            fontSize="0.8rem"
                            display="inline"
                            color="gray"
                            sx={{ marginLeft: "0px", marginBottom: "-8px" }}
                          >
                            KNOWN FOR
                          </Box>
                        )}
                        {allCommunity[index]?.works_known_for?.length > 0 &&
                          allCommunity[index]?.works_known_for?.map(
                            (work, wIndex) => {
                              return (
                                <Box
                                  sx={{
                                    marginBottom: 1,
                                    padding: "5px 5px 5px 5px",
                                    borderBottom:
                                      allCommunity[index]?.works_known_for
                                        .length +
                                        allCommunity[index]
                                          ?.collaborators_known_for?.length >
                                      1
                                        ? 1
                                        : 0,
                                    borderColor: "#D3D3D3",
                                    marginTop: wIndex === 0 ? 0 : 1,
                                    overflow: "auto",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexFlow: "row wrap",
                                      alignItems: "center",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        background: "none",
                                        border: "none",
                                        padding: "0",
                                        color: "#808080",
                                        cursor: "pointer",
                                        "&:hover": {
                                          textDecoration: "none",
                                          color: "#808080",
                                        },
                                        "&:focus": {
                                          textDecoration: "none",
                                          color: "#808080",
                                        },
                                      }}
                                      onClick={() =>
                                        window.open(
                                          `/works/${work.artis_code}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <>
                                        {work.work_copy_name ? (
                                          <img
                                            style={{
                                              marginRight: 15,
                                              objectFit: "cover",
                                              width: 120,
                                            }}
                                            src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(
                                              work.work_copy_name
                                            )}`}
                                            alt="profileimg"
                                          />
                                        ) : (
                                          <ArtTrackIcon />
                                        )}
                                      </>
                                       <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: 0,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.title}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#808080",
                                        marginBottom: 0,
                                        lineHeight: 1,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.description}
                                    </Typography>
                                    </button>
                                  </Box>
                                </Box>
                              );
                            }
                          )}
                        {allCommunity[index]?.collaborators_known_for?.length >
                          0 &&
                          allCommunity[index]?.collaborators_known_for?.map(
                            (work, cIndex) => {
                              return (
                                <Box
                                  sx={{
                                    marginBottom: 1,
                                    padding: "5px 5px 5px 5px",
                                    borderBottom:
                                      allCommunity[index]?.works_known_for
                                        .length +
                                        allCommunity[index]
                                          ?.collaborators_known_for?.length >
                                      1
                                        ? 1
                                        : 0,
                                    borderColor: "#D3D3D3",
                                    marginTop:
                                      cIndex === 0 &&
                                      allCommunity[index]?.works_known_for
                                        ?.length === 0
                                        ? 0
                                        : 1,
                                    overflow: "auto",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexFlow: "row wrap",
                                      alignItems: "center",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        background: "none",
                                        border: "none",
                                        padding: "0",
                                        color: "#808080",
                                        cursor: "pointer",
                                        "&:hover": {
                                          textDecoration: "none",
                                          color: "#808080",
                                        },
                                        "&:focus": {
                                          textDecoration: "none",
                                          color: "#808080",
                                        },
                                      }}
                                      onClick={() =>
                                        window.open(
                                          `/works/${work.work_artis_code}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <>
                                        {work.work_copy_name ? (
                                          <img
                                            style={{
                                              marginRight: 15,
                                              objectFit: "cover",
                                              width: 120,
                                            }}
                                            src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(
                                              work.work_copy_name
                                            )}`}
                                            alt="profileimg"
                                          />
                                        ) : (
                                          <ArtTrackIcon />
                                        )}
                                      </>
                                       <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: 0,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.work_title}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#808080",
                                        marginBottom: 0,
                                        lineHeight: 1,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.work_description}
                                    </Typography>
                                    </button>
                                  </Box>
                                </Box>
                              );
                            }
                          )}
                        {(allCommunity[index]?.works_known_for?.length > 0 ||
                          allCommunity[index]?.collaborators_known_for?.length >
                            0) && (
                          <Typography
                            fontSize="0.6rem"
                            gutterBottom
                            width="100%"
                            float="right"
                            color="gray"
                            textAlign="right"
                          >
                            TAP ON WORK TO SEE ITS REGISTRATION
                          </Typography>
                        )}
                        {data?.endorsements?.length > 0 && (
                          <>
                            <Box
                              component="p"
                              fontSize="0.8rem"
                              display="inline"
                              color="gray"
                              sx={{ marginLeft: "0px" }}
                            >
                              <br />
                              PROFESSIONALLY ENDORSED BY
                            </Box>
                            {data.endorsements.map((endorsement, index) => {
                              return (
                                <FameUserItemWrapper
                                  user={{
                                    ...endorsement,
                                    photo: endorsement.photo
                                      ? `https://artis-stage-data.s3.amazonaws.com/static/${endorsement.photo}`
                                      : "",
                                  }}
                                  showOrder={false}
                                  name={`${endorsement?.first_name} ${endorsement?.last_name}`}
                                  is_kyced={endorsement.is_kyced}
                                  shouldExpand={true}
                                  userProfile={profile}
                                  fromEditOrDetail={true}
                                />
                              );
                            })}
                          </>
                        )}
                        {data?.profile?.artist_bio && (
                          <>
                            <Box
                              component="p"
                              fontSize="0.8rem"
                              display="inline"
                              color="gray"
                              sx={{ marginLeft: "0px" }}
                            >
                              <br />
                              BIOGRAPHY
                            </Box>
                            <Box
                              component="p"
                              sx={{
                                whiteSpace: "pre-wrap",
                                fontSize: "1rem",
                                fontFamily: "'Montserrat', sans-serif;",
                                fontWeight: "normal",
                                textAlign: "left",
                                mt: 0,
                              }}
                            >
                              {data?.profile?.artist_bio}
                            </Box>
                          </>
                        )}

                      {
                        workCollaboratorsExhibitions && Object.keys(workCollaboratorsExhibitions).length > 0 && workCollaboratorsExhibitions[data.profile.id] && workCollaboratorsExhibitions[data.profile.id].length > 0 && <>
                            <Box
                              component="p"
                              fontSize="0.8rem"
                              display="block"
                              color="gray"
                              sx={{ marginLeft: "0px", marginTop: 2.5, wordBreak: 'break-word', }}
                            >
                              {workCollaboratorsExhibitions[data.profile.id][0].eventname.toUpperCase()}
                            </Box>

                            {
                              workCollaboratorsExhibitions[data.profile.id].map((exhibition) => {
                                return <Exhibition data={exhibition} />
                              })
                            }
                        </>
                      }

                        {
                          // Check if there are any valid records in COMMUNITY
                          (allCommunity[index]?.verified_connections?.some(
                            (fame) => {
                              return !data.endorsements.some(
                                (endorsement) =>
                                  endorsement.user_id === fame.user.user_id
                              );
                            }
                          ) ||
                            false ||
                            allCommunity[index]?.unverified_connections
                              ?.length > 0 ||
                            false) && (
                            <>
                              <Box
                                component="p"
                                fontSize="0.8rem"
                                display="inline"
                                color="gray"
                                sx={{ marginLeft: "0px" }}
                              >
                                <br />
                                COMMUNITY
                              </Box>
                              {allCommunity[index]?.verified_connections
                                .filter((fame) => {
                                  return !data.endorsements.some(
                                    (endorsement) =>
                                      endorsement.user_id === fame.user.user_id
                                  );
                                })
                                .map((fame) => {
                                  const customerUser = fame.user;
                                  const name =
                                    customerUser?.first_name &&
                                    customerUser?.last_name
                                      ? `${customerUser?.first_name} ${customerUser?.last_name}`
                                      : fame.invitee_name;

                                  return (
                                    <FameUserItemWrapper
                                      key={fame.id}
                                      user={{ ...customerUser }}
                                      showOrder={false}
                                      name={name}
                                      is_kyced={customerUser.is_kyced}
                                      order={fame.order}
                                      shouldExpand={true}
                                      userProfile={profile}
                                      fromEditOrDetail={true}
                                    />
                                  );
                                })}

                              {allCommunity[index]?.unverified_connections.map(
                                (fame) => {
                                  const customerUser = fame.user;
                                  const name =
                                    customerUser?.first_name &&
                                    customerUser?.last_name
                                      ? `${customerUser?.first_name} ${customerUser?.last_name}`
                                      : fame.invitee_name;

                                  return (
                                    <FameUserItemWrapper
                                      key={fame.id}
                                      name={name}
                                      is_kyced={customerUser.is_kyced}
                                      order={fame.order}
                                      shouldExpand={true}
                                      user={{ ...customerUser }}
                                      userProfile={profile}
                                      fromEditOrDetail={true}
                                    />
                                  );
                                }
                              )}
                            </>
                          )
                        }
                        <Typography
                          fontSize="0.6rem"
                          gutterBottom
                          width="100%"
                          float="right"
                          color="gray"
                          textAlign="right"
                        >
                          TAP NAME TO DISPLAY MORE INFORMATION
                        </Typography>
                      </Box>
                    }
                  />
                ))}
              <br />
              <br />

              <Link
                to={`/works/${work?.artis_code}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    padding: 2,
                    borderRadius: 4,
                    color: "black",
                    textAlign: "center",
                    fontFamily: "Bellefair, serif",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    border: "2px solid black",  // Black border
                    marginLeft: "20%",  // Wide margins on the left
                    marginRight: "20%", // Wide margins on the right
                  }}
                >
                  View Work's Original Registration
                </Box>
              </Link>
              <br />

              <Box
                component="p"
                display="inline"
                color="gray"
                sx={{ marginLeft: "15px", fontWeight: "bold"  }}
              >
                <br />
                <br />
                {"Certificate Statement"}
              </Box>

              <Box
                component="p"
                padding="0px 1.5rem"
                fontFamily="Montserrat, sans-serif"
              >
                I {`${profile.first_name} ${profile.last_name}`} hereby certify
                by issuing this digital Certificate of Authenticity and
                recording it on the blockchain on <strong>{inCertificates[0]?.date}</strong>,
                that the above described work is an original piece created by{" "}
                {allCollabsbyWork && allCollabsbyWork.length > 1
                  ? allCollabsbyWork.length === 1
                    ? `${allCollabsbyWork[0]?.profile?.first_name} ${allCollabsbyWork[0]?.profile?.last_name}`
                    : allCollabsbyWork.map((collaborators, index) =>
                        index === allCollabsbyWork.length - 2
                          ? `${collaborators?.profile?.first_name} ${collaborators?.profile?.last_name} and `
                          : index === allCollabsbyWork.length - 1
                          ? `${collaborators?.profile?.first_name} ${collaborators?.profile?.last_name}`
                          : `${collaborators?.profile?.first_name} ${collaborators?.profile?.last_name}, `
                      )
                  : "me"}
                . And I certify that the details herein are accurate and true
                to the best of my knowledge.
                <br />
                <Box
                  component="p"
                  color="gray"
                  fontWeight="bold"
                  fontFamily="Montserrat, sans-serif"
                ></Box>
              </Box>

              <Box
                component="p"
                display="inline"
                color="gray"
                sx={{ textAlign: "center", fontSize: "0.8em"  }}
              >
                {"CERTIFICATE DIGITALLY SIGNED AND ISSUED BY:"}
              </Box>

              {allCollabsbyWork.length > 0 &&
                allCollabsbyWork &&
                allCollabsbyWork.map(
                  (data, index) =>
                    data.profile.user_id === profile.id && (
                      <Bio
                        openId={openId}
                        setOpenId={setOpenId}
                        collaborator_id={"12"}
                        contribution={data?.collaborator?.contribution}
                        detail={true}
                        ownership={data?.collaborator?.ownership}
                        oncopyright={data?.collaborator?.oncopyright}
                        bio={data?.profile?.artist_bio}
                        isEdit={false}
                        is_kyced={data?.collaborator?.is_kyced}
                        website={data?.profile?.artist_website}
                        photo={
                          data?.profile?.photo
                            ? `https://artis-stage-data.s3.amazonaws.com/static/${data?.profile?.photo}`
                            : ""
                        }
                        first_name={data?.profile?.first_name.toUpperCase()}
                        last_name={data?.profile?.last_name.toUpperCase()}
                        token={""}
                        signed={
                          data.collaborator.hasOwnProperty("signed")
                            ? data.collaborator.signed
                            : true
                        }
                        handleBioUpdate={() => console.info("bio")}
                        artist_type={data?.collaborator?.contribution}
                        subCollabs={allCollabs}
                        onEditPage={false}
                        hiddenTitle
                        allCommunity={
                          <Box sx={{ marginLeft: 2.5, marginRight: 2.5 }}>
                            {(allCommunity[index]?.works_known_for?.length >
                              0 ||
                              allCommunity[index]?.collaborators_known_for
                                ?.length > 0) && (
                              <Box
                                component="p"
                                fontSize="0.8rem"
                                display="inline"
                                color="gray"
                                sx={{ marginLeft: "0px", marginBottom: "-8px" }}
                              >
                                KNOWN FOR
                              </Box>
                            )}
                            {allCommunity[index]?.works_known_for?.length > 0 &&
                              allCommunity[index]?.works_known_for?.map(
                                (work, wIndex) => {
                                  return (
                                    <Box
                                      sx={{
                                        marginBottom: 1,
                                        padding: "5px 5px 5px 5px",
                                        borderBottom:
                                          allCommunity[index]?.works_known_for
                                            .length +
                                            allCommunity[index]
                                              ?.collaborators_known_for
                                              ?.length >
                                          1
                                            ? 1
                                            : 0,
                                        borderColor: "#D3D3D3",
                                        marginTop: wIndex === 0 ? 0 : 1,
                                        overflow: "auto",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexFlow: "row wrap",
                                          alignItems: "center",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          style={{
                                            background: "none",
                                            border: "none",
                                            padding: "0",
                                            color: "#808080",
                                            cursor: "pointer",
                                            "&:hover": {
                                              textDecoration: "none",
                                              color: "#808080",
                                            },
                                            "&:focus": {
                                              textDecoration: "none",
                                              color: "#808080",
                                            },
                                          }}
                                          onClick={() =>
                                            window.open(
                                              `/works/${work.artis_code}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          <>
                                            {work.work_copy_name ? (
                                              <img
                                                style={{
                                                  marginRight: 15,
                                                  objectFit: "cover",
                                                  width: 120,
                                                }}
                                                src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(
                                                  work.work_copy_name
                                                )}`}
                                                alt="profileimg"
                                              />
                                            ) : (
                                              <ArtTrackIcon />
                                            )}
                                          </>
                                           <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: 0,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.title}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#808080",
                                        marginBottom: 0,
                                        lineHeight: 1,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.description}
                                    </Typography>
                                        </button>
                                      </Box>
                                    </Box>
                                  );
                                }
                              )}
                            {allCommunity[index]?.collaborators_known_for
                              ?.length > 0 &&
                              allCommunity[index]?.collaborators_known_for?.map(
                                (work, cIndex) => {
                                  return (
                                    <Box
                                      sx={{
                                        marginBottom: 1,
                                        padding: "5px 5px 5px 5px",
                                        borderBottom:
                                          allCommunity[index]?.works_known_for
                                            .length +
                                            allCommunity[index]
                                              ?.collaborators_known_for
                                              ?.length >
                                          1
                                            ? 1
                                            : 0,
                                        borderColor: "#D3D3D3",
                                        marginTop:
                                          cIndex === 0 &&
                                          allCommunity[index]?.works_known_for
                                            ?.length === 0
                                            ? 0
                                            : 1,
                                        overflow: "auto",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexFlow: "row wrap",
                                          alignItems: "center",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          style={{
                                            background: "none",
                                            border: "none",
                                            padding: "0",
                                            color: "#808080",
                                            cursor: "pointer",
                                            "&:hover": {
                                              textDecoration: "none",
                                              color: "#808080",
                                            },
                                            "&:focus": {
                                              textDecoration: "none",
                                              color: "#808080",
                                            },
                                          }}
                                          onClick={() =>
                                            window.open(
                                              `/works/${work.work_artis_code}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          <>
                                            {work.work_copy_name ? (
                                              <img
                                                style={{
                                                  marginRight: 15,
                                                  objectFit: "cover",
                                                  width: 120,
                                                }}
                                                src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(
                                                  work.work_copy_name
                                                )}`}
                                                alt="profileimg"
                                              />
                                            ) : (
                                              <ArtTrackIcon />
                                            )}
                                          </>
                                           <Typography
                                              sx={{
                                                fontWeight: "bold",
                                                fontSize: "16px",
                                                marginBottom: 0,
                                                textAlign: "center",
                                              }}
                                            >
                                              {work.work_title}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: "12px",
                                                color: "#808080",
                                                marginBottom: 0,
                                                lineHeight: 1,
                                                textAlign: "center",
                                              }}
                                            >
                                              {work.work_description}
                                            </Typography>
                                        </button>
                                      </Box>
                                    </Box>
                                  );
                                }
                              )}
                            {(allCommunity[index]?.works_known_for?.length >
                              0 ||
                              allCommunity[index]?.collaborators_known_for
                                ?.length > 0) && (
                              <Typography
                                fontSize="0.6rem"
                                gutterBottom
                                width="100%"
                                float="right"
                                color="gray"
                                textAlign="right"
                              >
                                TAP ON WORK TO SEE ITS REGISTRATION
                              </Typography>
                            )}
                            {data?.endorsements?.length > 0 && (
                              <>
                                <Box
                                  component="p"
                                  fontSize="0.8rem"
                                  display="inline"
                                  color="gray"
                                  sx={{ marginLeft: "0px" }}
                                >
                                  <br />
                                  PROFESSIONALLY ENDORSED BY
                                </Box>
                                {data.endorsements.map((endorsement, index) => {
                                  return (
                                    <FameUserItemWrapper
                                      user={{
                                        ...endorsement,
                                        photo: endorsement.photo
                                          ? `https://artis-stage-data.s3.amazonaws.com/static/${endorsement.photo}`
                                          : "",
                                      }}
                                      showOrder={false}
                                      name={`${endorsement?.first_name} ${endorsement?.last_name}`}
                                      is_kyced={endorsement.is_kyced}
                                      shouldExpand={true}
                                      userProfile={profile}
                                      fromEditOrDetail={true}
                                    />
                                  );
                                })}
                              </>
                            )}
                            {data?.profile?.artist_bio && (
                              <>
                                <Box
                                  component="p"
                                  fontSize="0.8rem"
                                  display="inline"
                                  color="gray"
                                  sx={{ marginLeft: "0px" }}
                                >
                                  <br />
                                  BIOGRAPHY
                                </Box>
                                <Box
                                  component="p"
                                  sx={{
                                    whiteSpace: "pre-wrap",
                                    fontSize: "1rem",
                                    fontFamily: "'Montserrat', sans-serif;",
                                    fontWeight: "normal",
                                    textAlign: "left",
                                    mt: 0,
                                  }}
                                >
                                  {data?.profile?.artist_bio}
                                </Box>
                              </>
                            )}

                          {
                            workCollaboratorsExhibitions && Object.keys(workCollaboratorsExhibitions).length > 0 && workCollaboratorsExhibitions[data.profile.id] && workCollaboratorsExhibitions[data.profile.id].length > 0 && <>
                                <Box
                                  component="p"
                                  fontSize="0.8rem"
                                  display="block"
                                  color="gray"
                                  sx={{ marginLeft: "0px", marginTop: 2.5, wordBreak: 'break-word' }}
                                >
                                  {workCollaboratorsExhibitions[data.profile.id][0].eventname.toUpperCase()}
                                </Box>

                                {
                                  workCollaboratorsExhibitions[data.profile.id].map((exhibition) => {
                                    return <Exhibition data={exhibition} />
                                  })
                                }
                            </>
                          }

                            {
                              // Check if there are any valid records in COMMUNITY
                              (allCommunity[index]?.verified_connections?.some(
                                (fame) => {
                                  return !data.endorsements.some(
                                    (endorsement) =>
                                      endorsement.user_id === fame.user.user_id
                                  );
                                }
                              ) ||
                                false ||
                                allCommunity[index]?.unverified_connections
                                  ?.length > 0 ||
                                false) && (
                                <>
                                  <Box
                                    component="p"
                                    fontSize="0.8rem"
                                    display="inline"
                                    color="gray"
                                    sx={{ marginLeft: "0px" }}
                                  >
                                    <br />
                                    COMMUNITY
                                  </Box>
                                  {allCommunity[index]?.verified_connections
                                    .filter((fame) => {
                                      return !data.endorsements.some(
                                        (endorsement) =>
                                          endorsement.user_id ===
                                          fame.user.user_id
                                      );
                                    })
                                    .map((fame) => {
                                      const customerUser = fame.user;
                                      const name =
                                        customerUser?.first_name &&
                                        customerUser?.last_name
                                          ? `${customerUser?.first_name} ${customerUser?.last_name}`
                                          : fame.invitee_name;

                                      return (
                                        <FameUserItemWrapper
                                          key={fame.id}
                                          user={{ ...customerUser }}
                                          showOrder={false}
                                          name={name}
                                          is_kyced={customerUser.is_kyced}
                                          order={fame.order}
                                          shouldExpand={true}
                                          userProfile={profile}
                                          fromEditOrDetail={true}
                                        />
                                      );
                                    })}

                                  {allCommunity[
                                    index
                                  ]?.unverified_connections.map((fame) => {
                                    const customerUser = fame.user;
                                    const name =
                                      customerUser?.first_name &&
                                      customerUser?.last_name
                                        ? `${customerUser?.first_name} ${customerUser?.last_name}`
                                        : fame.invitee_name;

                                    return (
                                      <FameUserItemWrapper
                                        key={fame.id}
                                        name={name}
                                        is_kyced={customerUser.is_kyced}
                                        order={fame.order}
                                        shouldExpand={true}
                                        user={{ ...customerUser }}
                                        userProfile={profile}
                                        fromEditOrDetail={true}
                                      />
                                    );
                                  })}
                                </>
                              )
                            }
                            <Typography
                              fontSize="0.6rem"
                              gutterBottom
                              width="100%"
                              float="right"
                              color="gray"
                              textAlign="right"
                            >
                              TAP NAME TO DISPLAY MORE INFORMATION
                            </Typography>
                          </Box>
                        }
                      />
                    )
                )}
              <br />

              <Box
                component="p"
                display="inline"
                color="gray"
                sx={{ marginLeft: "15px", fontWeight: "bold" }}
              > 
                <br />
                {"Provenance"}
              </Box>

              <Box
                component="p"
                display="inline"
                color="gray"
                sx={{ marginLeft: "20px" }}
              >
                {
                  "Certificate's history has been tracked on the blockchain. Tap blue links to see transactions."
                }
              </Box>
              <br />
              {inCertificates &&
                Object.keys(inCertificates[0].provinance[0]).length >= 7 && (
                  <>
                    {inCertificates[0].provinance.map((item, index) => (
                      <>
                        <Box component="p" ml={3} mr={3} sx={{ fontSize: "0.8em" }}>
                          <a
                            style={{ textDecoration: "none" }}
                            href={`https://polygonscan.com/tx/${item.tx_hash}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.date}{" "}
                            {index === inCertificates[0].provinance.length - 1
                              ? "CERTIFICATE ISSUED BY:"
                              : "CERTIFICATE ACQUIRED BY:"}
                          </a>
                        </Box>

                        <Bio
                          openId={openId}
                          setOpenId={setOpenId}
                          collaborator_id={index}
                          contribution={item.famedata.invitee[0]?.artist_type}
                          bio={item.famedata.invitee[0]?.artist_bio || ""}
                          isEdit={false}
                          is_kyced={item.famedata.invitee[0]?.is_kyced}
                          website={
                            item.famedata.invitee[0]?.artist_website || ""
                          }
                          photo={
                            item.famedata.invitee[0].photo
                              ? `https://artis-stage-data.s3.amazonaws.com/static/${
                                  item.famedata.invitee[0].photo.split(
                                    "static/"
                                  )[1]
                                }`
                              : ""
                          }
                          first_name={
                            item.famedata.invitee[0]?.first_name?.toUpperCase() ||
                            ""
                          }
                          last_name={
                            item.famedata.invitee[0]?.last_name?.toUpperCase() ||
                            ""
                          }
                          token={""}
                          handleBioUpdate={() => console.info("bio")}
                          artist_type={item.famedata.invitee[0]?.artist_type}
                          hiddenTitle
                          allCommunity={
                            <Box sx={{ marginLeft: 2.5, marginRight: 2.5 }}>
                              {item.famedata.works_known_for?.length > 0 && (
                                <Box
                                  component="p"
                                  fontSize="0.8rem"
                                  display="inline"
                                  color="gray"
                                  sx={{
                                    marginLeft: "0px",
                                    marginBottom: "-8px",
                                  }}
                                >
                                  KNOWN FOR
                                </Box>
                              )}
                              {item.famedata.works_known_for?.length > 0 &&
                                item.famedata.works_known_for?.map(
                                  (work, wIndex) => {
                                    return (
                                      <Box
                                        sx={{
                                          marginBottom: 1,
                                          padding: "5px 5px 5px 5px",
                                          borderBottom:
                                            item.famedata.works_known_for
                                              .length > 1
                                              ? 1
                                              : 0,
                                          borderColor: "#D3D3D3",
                                          marginTop: wIndex === 0 ? 0 : 1,
                                          overflow: "auto",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexFlow: "row wrap",
                                            alignItems: "center",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            style={{
                                              background: "none",
                                              border: "none",
                                              padding: "0",
                                              color: "#808080",
                                              cursor: "pointer",
                                              "&:hover": {
                                                textDecoration: "none",
                                                color: "#808080",
                                              },
                                              "&:focus": {
                                                textDecoration: "none",
                                                color: "#808080",
                                              },
                                            }}
                                            onClick={() =>
                                              window.open(
                                                `/works/${work.artis_code}`,
                                                "_blank"
                                              )
                                            }
                                          >
                                            <>
                                              {work.work_copy_name ? (
                                                <img
                                                  style={{
                                                    marginRight: 15,
                                                    objectFit: "cover",
                                                    width: 120,
                                                  }}
                                                  src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(
                                                    work.work_copy_name
                                                  )}`}
                                                  alt="profileimg"
                                                />
                                              ) : (
                                                <ArtTrackIcon />
                                              )}
                                            </>
                                             <Typography
                                                sx={{
                                                  fontWeight: "bold",
                                                  fontSize: "16px",
                                                  marginBottom: 0,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {work.title}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "12px",
                                                  color: "#808080",
                                                  marginBottom: 0,
                                                  lineHeight: 1,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {work.description}
                                              </Typography>
                                          </button>
                                        </Box>
                                      </Box>
                                    );
                                  }
                                )}

                              {item.famedata.works_known_for?.length > 0 && (
                                <Typography
                                  fontSize="0.6rem"
                                  gutterBottom
                                  width="100%"
                                  float="right"
                                  color="gray"
                                  textAlign="right"
                                >
                                  TAP ON WORK TO SEE ITS REGISTRATION
                                </Typography>
                              )}

                              {item.famedata.invitee[0].endorsed_by?.split(",") 
                                .length > 0 && item.famedata.invitee[0].endorsed_by !== "" && item.famedata.invitee[0].endorsed_by !== null && (
                                <>
                                  <Box
                                    component="p"
                                    fontSize="0.8rem"
                                    display="inline"
                                    color="gray"
                                    sx={{ marginLeft: "0px" }}
                                  >
                                    <br />
                                    PROFESSIONALLY ENDORSED BY
                                  </Box>

                                  {item.famedata?.community.map(
                                    (fame, index) => {
                                      const endorsedUsers =
                                        item.famedata.invitee[0].endorsed_by
                                          .split(",")
                                          .filter(
                                            (val) =>
                                              Number(val) === fame.user_id
                                          );

                                      if(endorsedUsers.length > 0) {
                                        fame.endorsed = true
                                        return <FameUserItemWrapper
                                        key={index}
                                        user={{
                                          ...fame,
                                          photo: fame.photo
                                            ? `https://artis-stage-data.s3.amazonaws.com/static/${
                                                fame.photo.split(
                                                  "static/"
                                                )[1]
                                              }`
                                            : "",
                                        }}
                                        showOrder={false}
                                        name={`${fame.first_name} ${fame.last_name}`}
                                        is_kyced={fame.is_kyced}
                                        shouldExpand={true}
                                        userProfile={profile}
                                        fromEditOrDetail={true}
                                      />
                                      }
                                      return null;
                                    }
                                  )}
                                </>
                              )}

                              {item.famedata.invitee[0]?.artist_bio && (
                                <>
                                  <Box
                                    component="p"
                                    fontSize="0.8rem"
                                    display="inline"
                                    color="gray"
                                    sx={{ marginLeft: "0px" }}
                                  >
                                    <br />
                                    BIOGRAPHY
                                  </Box>
                                  <Box
                                    component="p"
                                    sx={{
                                      whiteSpace: "pre-wrap",
                                      fontSize: "1rem",
                                      fontFamily: "'Montserrat', sans-serif;",
                                      fontWeight: "normal",
                                      m: 0,
                                      textAlign: "left",
                                      mt: 0,
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {item.famedata.invitee[0]?.artist_bio}
                                  </Box>
                                </>
                              )}

                        {
                          item.famedata.exhibitions && Object.keys(item.famedata.exhibitions).length > 0 && <>
                              <Box
                                component="p"
                                fontSize="0.8rem"
                                display="block"
                                color="gray"
                                sx={{ marginLeft: "0px", marginTop: 2.5, wordBreak: 'break-word', }}
                              >
                                {item.famedata.exhibitions[0].eventname.toUpperCase()}
                              </Box>

                              {
                                item.famedata.exhibitions.map((exhibition) => {
                                  return <Exhibition data={exhibition} />
                                })
                              }
                          </>
                        }

                              {
                                // Check if there are any valid records in COMMUNITY,
                                item.famedata.community &&
                                item.famedata.community.length > 0 && 
                                item.famedata.community.filter(item => item.endorsed === false).length > 0 && (
                                    <>
                                      <Box
                                        component="p"
                                        fontSize="0.8rem"
                                        display="inline"
                                        color="gray"
                                        sx={{ marginLeft: "0px" }}
                                      >
                                        <br />
                                        COMMUNITY
                                      </Box>
                                      {item.famedata.community.map((fame) => {
                                        const customerUser = fame;
                                        const name =
                                          customerUser?.first_name &&
                                          customerUser?.last_name
                                            ? `${customerUser?.first_name} ${customerUser?.last_name}`
                                            : fame.invitee_name;

                                        if(!fame.endorsed) {
                                          return (
                                            <FameUserItemWrapper
                                              key={fame.id}
                                              user={{ ...customerUser }}
                                              showOrder={false}
                                              name={name}
                                              is_kyced={customerUser.is_kyced}
                                              order={fame.order}
                                              shouldExpand={true}
                                              userProfile={profile}
                                              fromEditOrDetail={true}
                                            />
                                          );
                                        }
                                        return null;
                                      })}
                                    </>
                                  )
                              }
                            </Box>
                          }
                        />
                        <br />
                      </>
                    ))}
                  </>
                )}

              {JSON.parse(localStorage.getItem("artis_user")) && inCertificates[0].holder_id ===
                JSON.parse(localStorage.getItem("artis_user")).profile.user.id && (
                <>
                  <Box
                    component="p"
                    display="inline"
                    color="gray"
                    sx={{ marginLeft: "15px", fontWeight: "bold" }}
                  >
                    <br />
                    <br />
                    {"Manage Certificate"}
                  </Box>

                  <Box
                    component="p"
                    display="inline"
                    color="gray"
                    sx={{ marginLeft: "20px" }}
                  >
                    {
                      "This section is visible only to you, because you are the certificate's holder."
                    }
                  </Box>

                  <Sell
                    openId={openId}
                    setOpenId={setOpenId}
                    sold_tx_hash={work?.sold_tx_hash}
                    sold_buyer={work?.sold_buyer}
                    sold_price={work?.sold_price}
                    sold_email={work?.sold_email}
                    sold_date={work?.sold_date}
                    sale_description={work?.sale_description}
                    title={work?.title}
                    handleSale={handleSale}
                    handlePutOnSale={handlePutOnSale}
                    handleCancelSale={handleCancelSale}
                    cert_price={inCertificates[0]?.sale_price}
                    cert_description={inCertificates[0]?.sale_description}
                    cert_on_sale={inCertificates[0]?.on_sale}
                  />

                  <Transfer
                    openId={openId}
                    setOpenId={setOpenId}
                    inCertificates={inCertificates}
                    setInCertificates={setInCertificates}
                    artis_code={artis_code}
                    onTransferRequestFail={onTransferRequestFail}
                    cert_id={inCertificates ? inCertificates[0].id : ""}
                    senderName={JSON.parse(localStorage.getItem("artis_user")).profile.first_name + " " + JSON.parse(localStorage.getItem("artis_user")).profile.last_name}
                    workName={work.title}
                    allCollabsbyWork={allCollabsbyWork}
                  />

                  <LinksAndQrCodes 
                    openId={openId} 
                    setOpenId={setOpenId} 
                    code={artis_code+"?"+inCertificates[0].id}
                  />

                  <Settings 
                    openId={openId} 
                    setOpenId={setOpenId} 
                  />

                </>
              )}

              <br />
              <Typography
                fontSize="0.6rem"
                gutterBottom
                width="100%"
                float="right"
                textAlign="right"
              >
                <img
                  style={{ height: 10 }}
                  alt="identity validated"
                  src="/images/GoldWreath.jpg"
                />{" "}
                = IDENTITY CONFIRMED WITH GOVERNMENT DOCUMENTS&nbsp;&nbsp;&nbsp;
              </Typography>
            </Box>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <ArtisFooter />
        </Container>
      </Slide>
      <Modal
        open={openImage}
        onClose={() => setOpenImage(false)}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={openImage} timeout={500} sx={{ outline: "none" }}>
          <img
            src={image}
            alt="asd"
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          />
        </Fade>
      </Modal>
    </div>
  );
}
