/* eslint-disable jsx-a11y/anchor-is-valid */

/**
 * WorkEdit.jsx
 * 
 * Summary:
 * This file defines the WorkEdit component, which serves as the editing page for a specific artwork on the Artis.app platform.
 * It allows authorized users (creators or collaborators) to view and modify details of a registered work, including collaborator 
 * information, artist statements, tags, blockchain data, and more. The component fetches work and collaboration data from the 
 * backend, manages state for UI interactions (e.g., collapsible sections, modals), and provides options to share proof of 
 * registration via links, QR codes, or NFC tags. It integrates various collapsible components for detailed editing and 
 * visualization of work-related data.
 * 
 * Key Features:
 * - Fetches and displays artwork details and collaborator information
 * - Handles authorization to ensure only creators or collaborators can edit
 * - Provides collapsible sections for bio, statement, tags, blockchain, etc.
 * - Supports collaboration approval/rejection and work deletion
 * - Allows sharing of proof of registration via link, ArtisCode, QR code, and NFC
 * - Includes responsive UI with modals, tooltips, and toast notifications
 */

import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  LinearProgress,
  Slide,
  IconButton,
  Tooltip,
  Typography,
  Modal,
  Button,
} from "@mui/material";

import { useParams, useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { WorkService, AuthService as UserService } from "../api";
import Bio from "../components/work/edit/collapsibles/artist-bio";
import Statement from "../components/work/edit/collapsibles/statement";
import utils from "../utils";
import Comments from "../components/work/edit/collapsibles/comments";
import Collection from "../components/work/edit/collapsibles/collection";
import Tags from "../components/work/edit/collapsibles/tags";
import Blockchain from "../components/work/edit/collapsibles/blockchain";
import Email from "../components/work/edit/collapsibles/email";
import VerifyWork from "../components/work/edit/collapsibles/verify";
import { FameUserItemWrapper } from "../components/tabs/FameUserItem";
import { useUser } from "../hooks/user";
import ArtisFooter from "../components/footer";
import ArtisButton from "../components/buttons/button";
import DeleteCollabDialog from "../components/dialogs/delete-collab";
import ConfirmCollabDialog from "../components/dialogs/confirm-collab";
import Government from "../components/work/edit/collapsibles/government";
import GPSLocation from "../components/work/edit/collapsibles/gps-location";
import Fade from "@mui/material/Fade";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import Community from "../components/work/edit/collapsibles/community";
import Add from "../components/work/edit/collapsibles/add";
import Exhibition from "../components/work/edit/collapsibles/exhibition";
import AiDescription from "../components/work/edit/collapsibles/ai-description";

// Default collaboration status object
const defaultCollab = {
  showBanner: false,
  text: "",
  backgroundColor: "",
  textColor: "white",
  addApproveBtn: false,
  addDeleteButton: false,
};

// Main WorkEdit component
export default function WorkEdit() {
  const { user, setUser } = useUser(); // User context hook
  const [work, setWork] = useState(null); // State for work data
  const [profile, setProfile] = useState(null); // State for user profile data
  const [openId, setOpenId] = useState(""); // State for tracking open collapsible section
  const [collabStatus, setCollabStatus] = useState(defaultCollab); // State for collaboration status UI
  const [allCollabs, setAllCollabs] = useState(null); // State for all collaborations by current user
  const [allCollabsbyWork, setAllCollabsbyWork] = useState(null); // State for all collaborations for this work
  const [isCollabDialogOpen, setIsCollabDialogOpen] = useState(false); // State for delete collaboration dialog
  const [confirmDialog, setIsConfirmDialogOpen] = useState({
    isVisible: false,
    type: "",
  }); // State for confirm collaboration dialog
  const [allCommunity, setAllCommunity] = useState([]); // State for community data
  const [selfOwnership, setSelfOwnership] = useState(100); // State for user's ownership percentage
  const [onCopyRight, setOnCopyRight] = useState("original"); // State for copyright status
  const [userMode, setUserMode] = useState("original"); // State for user mode (original/collaborator)
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [modalState, setModalState] = useState(""); // State for modal type
  const [myTags, setMyTags] = useState([]); // State for tags
  const [openImage, setOpenImage] = useState(false); // State for image modal visibility
  const [image, setImage] = useState("false"); // State for image URL
  const [knownFor, setKnownFor] = useState(false); // State for "known for" data
  const [allExhibitions, setAllExhibitions] = useState({}); // State for exhibition data
  const [isAuthorised, setIsAuthorised] = useState(false); // State for authorization check

  const { artis_code } = useParams(); // Get artis_code from URL params
  const history = useHistory(); // History object for navigation

  // Determine artist type from work or profile
  const getArtistType = () => {
    if (work?.artist_type) return work.artist_type;
    else if (profile?.artist_type) return profile?.artist_type;
    return "Artist";
  };

  // Fetch work data by artis_code
  const fetchWork = async (artis_code) => {
    const response = await WorkService.getWork(artis_code);
    const { work, user } = response?.result;
    return { work, user };
  };

  // Fetch collaboration data and set states accordingly
  const fetchCollabs = async (newWork = null) => {
    const response = await WorkService.getCollaboratorStatus();
    const response_allcollabyId = await WorkService.getCollaboratorById({
      work_id: work.id,
      artis_code: work.artis_code,
      emails: work.emails,
    });

    if (response.result) {
      const selectedWork = newWork ?? work;
      const sameWork = response.result.find(
        (res) => res.work.id === selectedWork.id
      );
      const allCollabs = response.result.filter(
        (res) =>
          res.work.id === selectedWork.id &&
          res.registered_by_id === user.profile.user.id
      );

      setAllCollabs(allCollabs);
      var allCollabsbyWork = response_allcollabyId.result;

      // Check if user is authorized (creator or collaborator)
      const isCreatorOrCollaborator = allCollabsbyWork.some(
        (collab) => collab.profile.user_id === user.profile.user.id
      );
      if (!isCreatorOrCollaborator) {
        history.push(`/works/${artis_code}`); // Redirect if not authorized
      } else {
        setIsAuthorised(true);
      }

      // Fetch community data based on number of collaborators
      if (allCollabsbyWork.length === 1) {
        const myprofile = allCollabsbyWork[0].profile;
        const query = `?q=detail&id=${myprofile.user_id}`;
        fetchFameData(query, myprofile.user_id, "detail");
      } else {
        var ids = allCollabsbyWork[0].profile.user_id;
        for (var i = 1; i < allCollabsbyWork.length; i++) {
          ids += "," + allCollabsbyWork[i].profile.user_id;
        }
        const query = `?q=multiple&id=${ids}`;
        fetchFameData(query, ids, "multiple");
      }
      setAllCollabsbyWork(allCollabsbyWork);

      // Set ownership and copyright status
      allCollabs.length === 0
        ? sameWork
          ? setSelfOwnership(sameWork.ownership)
          : setSelfOwnership(100)
        : setSelfOwnership(work.ownership);
      allCollabs.length === 0
        ? sameWork
          ? sameWork.oncopyright
            ? setOnCopyRight("collaborator_true")
            : setOnCopyRight("collaborator_false")
          : setOnCopyRight("original")
        : setOnCopyRight("original");

      // Set tags based on user mode
      if (work.emails === user.profile.email) {
        setUserMode("original");
        let temp = [];
        temp.push(...work?.tags);
        setMyTags(temp);
      } else {
        setUserMode("collaborator");
        const body = {
          collaborator_id: user?.profile?.id,
          work_id: work?.id,
        };
        const response_tags = await WorkService.getTagsById(body);
        const results = response_tags?.result;
        const collaborator_tags_string = results[0]?.searchtabs;
        var collaborator_tags_array = [];
        if (collaborator_tags_string)
          collaborator_tags_array = JSON.parse(collaborator_tags_string);

        let temp = [];
        temp.push(...collaborator_tags_array);
        setMyTags(temp);
      }

      // Set collaboration status UI
      if (
        sameWork &&
        sameWork.registered_by_id === user.profile.user.id &&
        !selectedWork.registered_on_blockchain
      ) {
        setCollabStatus({ addDeleteButton: true });
      }
      if (
        sameWork &&
        sameWork.collaborator.user_id === user.profile.user.id &&
        !sameWork.signed &&
        !selectedWork.rejected_collab
      ) {
        setCollabStatus({
          showBanner: true,
          text: "To register this work permanently on the blockchain and issue a Certificate of Copyright Registration, you’ll need to confirm your participation blow.",
          backgroundColor: "#fffde7",
          addApproveBtn: true,
        });
      }
      if (
        sameWork &&
        sameWork.registered_by_id === user.profile.user.id &&
        sameWork?.work.rejected_collab
      ) {
        setCollabStatus({
          showBanner: true,
          text: "A collaborator declined. This work cannot be registered on the blockchain.",
          backgroundColor: "#ffebee",
          addDeleteButton: true,
        });
      }
    }
  };

  // Fetch fame/community data for collaborators
  const fetchFameData = async (query, id, method) => {
    try {
      const response = await UserService.getFameConnections(query);
      if (response.result) {
        const verified_connections = [];
        const unverified_connections = [];

        if (method === "detail") {
          var all_community_detail = [];
          let toUpdate = {};
          toUpdate[id] = response.result.exhibitions;
          setAllExhibitions(toUpdate);

          response.result.all_fame.forEach((fame) => {
            if (fame.status === 4) return;
            const customerUser =
              id === fame?.sender[0].user_id
                ? fame?.invitee[0]
                : fame?.sender[0];

            if (Number(id) !== customerUser.user_id) {
              if (customerUser.is_kyced) {
                verified_connections.push({ ...fame, user: customerUser });
              } else {
                unverified_connections.push({ ...fame, user: customerUser });
              }
            }
          });

          const filteredVerifiedNoOrder = verified_connections.filter(
            (v) => v.order === 0
          );
          const orderedVerified = verified_connections
            .filter((c) => c.order !== 0)
            .sort((a, b) => a.order - b.order);
          const result = orderedVerified.concat(filteredVerifiedNoOrder);

          all_community_detail.push({
            verified_connections: result,
            unverified_connections,
            works_known_for: response.result.works_known_for,
            collaborators_known_for: response.result.collaborators_known_for,
          });
          setAllCommunity(all_community_detail);
        } else {
          var ids = id.split(",");
          var all_community = [];
          let toUpdate = allExhibitions;

          ids.forEach((id) => {
            var verified_connections = [];
            var unverified_connections = [];

            toUpdate[id] = response.result[id].exhibitions;
            response.result[id].all_fame.forEach((fame) => {
              if (fame.status === 4) return;
              const customerUser =
                parseInt(id) === fame?.sender[0].user_id
                  ? fame?.invitee[0]
                  : fame?.sender[0];

              if (Number(id) !== customerUser.user_id) {
                if (customerUser.is_kyced) {
                  verified_connections.push({ ...fame, user: customerUser });
                } else {
                  unverified_connections.push({ ...fame, user: customerUser });
                }
              }
            });

            const filteredVerifiedNoOrder = verified_connections.filter(
              (v) => v.order === 0
            );
            const orderedVerified = verified_connections
              .filter((c) => c.order !== 0)
              .sort((a, b) => a.order - b.order);
            const result = orderedVerified.concat(filteredVerifiedNoOrder);

            all_community.push({
              verified_connections: result,
              unverified_connections,
              works_known_for: response.result[id].works_known_for,
              collaborators_known_for:
                response.result[id].collaborators_known_for,
            });
          });

          setAllExhibitions(toUpdate);
          setAllCommunity(all_community);
        }
      }
    } catch (error) {}
  };

  // Effect to fetch collabs and known-for data when work changes
  useEffect(() => {
    if (work) {
      fetchCollabs();
      async function fetchData() {
        try {
          const response = await WorkService.getKnownFor({ work_id: work.id });
          if (response.hasOwnProperty("result")) {
            setKnownFor(response.result[0].known_for);
          }
        } catch (error) {}
      }
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [work]);

  // Effect to fetch work data on mount or artis_code change
  useEffect(() => {
    if (artis_code) {
      fetchWork(artis_code).then(({ work, user }) => {
        setWork(work);
        setProfile(user);
        if (isAuthorised) document.title = "Artis.app | " + work?.title;
      });
    }
    // eslint-disable-next-line
  }, [artis_code]);

  // Update artist statement
  const updateStatement = async (statement) => {
    if (onCopyRight !== "original") {
      setModalState("statement");
      setIsModalOpen(true);
    } else {
      const response = await WorkService.updateWork(artis_code, {
        artist_statement: statement,
      });
      if (response?.result) {
        setWork({
          ...work,
          artist_statement: response.result.artist_statement,
        });
      }
    }
  };

  // Update user bio
  const handleBioUpdate = async (body) => {
    const response = await UserService.updateProfile(body);
    if (response?.result) {
      const { artist_bio, artist_website, photo } = response.result;
      setUser({
        ...user,
        profile: { ...user.profile, artist_bio, artist_website, photo },
      });
    }
    return response;
  };

  // Update tags
  const handleTagsUpdate = async (tags) => {
    if (userMode === "original") {
      const response = await WorkService.updateWork(artis_code, { tags });
      if (response?.result) {
        setWork({
          ...work,
          tags: response.result.tags,
        });
      }
    } else {
      const body = {
        tags: JSON.stringify(tags),
        collaborator_id: user?.profile?.id,
        work_id: work?.id,
      };
      await WorkService.updateTagsById(body);
      setWork({
        ...work,
        tags: tags,
      });
    }
  };

  // Send warning email
  const handleWarningEmail = async (body) => {
    const response = await WorkService.sendWarningEmail(artis_code, body);
    if (response?.result?.success) return response.result.previous;
    return [];
  };

  // Get previous warnings
  const getWarnings = async () => {
    const response = await WorkService.getPreviousWarnings(artis_code);
    if (response?.result) return response.result;
    return [];
  };

  // Update new comment status
  const updateNewComment = () => {
    setWork({
      ...work,
      new_comment: false,
    });
  };

  // Verify work file
  const handleVerify = async (body) => {
    try {
      const response = await WorkService.verifyFile(work.artis_code, body);
      if (response?.result) {
        return response.result.match;
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  // Download QR code as PNG
  const downloadQR = () => {
    const canvas = document.getElementById(work?.artis_code);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${work?.artis_code}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // Loading state while data is being fetched
  if (!work || !profile || !isAuthorised) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  // Handle collaboration sign status
  const handleCollabSign = async (signed) => {
    try {
      await WorkService.updateCollab({
        signed,
        work_id: work.id,
        collaborator_id: user.profile.user.id,
      });
    } catch (error) {}
  };

  // Open image modal
  const handleOpenImage = (value) => {
    setImage(value);
    setOpenImage(true);
  };

  // Update "known for" data
  const handleKnownFor = async (knownFor) => {
    try {
      const response = await WorkService.updateKnownFor({
        work_id: work.id,
        known_for: knownFor,
      });
      if (response?.result?.Success) {
        setKnownFor(knownFor);
        toast.success("Work updated successfully");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Render the main UI
  return (
    <div>
      {isAuthorised && (
        <Slide
          direction="left"
          in={true}
          mountOnEnter
          unmountOnExit
          appear={false}
        >
          <Container maxWidth="sm">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "background.paper",
                overflow: "hidden",
                boxShadow: 1,
                fontWeight: "bold",
                mb: 5,
                pb: 3,
              }}
            >
              {/* Collaboration status banner */}
              {collabStatus.showBanner && (
                <Box
                  sx={{
                    display: "flex",
                    flexFlow: "row wrap",
                    backgroundColor: `${collabStatus?.backgroundColor}`,
                    padding: "5px",
                    margin: "20px 0",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ color: "gray", fontSize: "18px" }}>
                    {collabStatus.text}
                  </Typography>
                </Box>
              )}

              {/* Display work image if photograph */}
              {work?.work_type === "Photograph" && work?.work_image.image && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => handleOpenImage(work?.work_image?.image)}
                >
                  <Box
                    component="img"
                    sx={{
                      width: "auto",
                      maxHeight: "70vh",
                      cursor: "pointer",
                    }}
                    alt={work?.description}
                    src={work?.work_image?.image}
                  />
                </div>
              )}
              {/* Work title and description */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  m: 3,
                  minWidth: { md: 350 },
                }}
              >
                <Box
                  component="h1"
                  sx={{
                    fontSize: "1.7rem",
                    mt: 1,
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  {work?.title}
                </Box>
                <Box
                  component="p"
                  sx={{
                    fontSize: "1.6rem",
                    fontFamily: "Bellefair, serif",
                    fontWeight: "normal",
                  }}
                >
                  {work?.description}
                </Box>
              </Box>

              {/* Work metadata */}
              <Box
                sx={{
                  display: "flex",
                  ml: 3,
                  mb: 3,
                  mr: 3,
                  fontWeight: "normal",
                  fontSize: "1.2rem",
                  fontFamily: "Bellefair, serif",
                }}
              >
                <Box sx={{ flexGrow: 1, gridRow: "1" }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Box>
                      <Box component="p" display="inline" color="gray">
                        Copyright:{" "}
                      </Box>
                      <Box component="span" display="inline">
                        {work?.copyright_date
                          ? utils.formatDate(work?.copyright_date)
                          : "No Copyright"}
                      </Box>
                    </Box>
                    <Box>
                      <Box component="p" display="inline" color="gray">
                        Artis Code:{" "}
                      </Box>
                      <Box component="span" display="inline">
                        <Link
                          to={{
                            pathname: `/works/${work?.artis_code}`,
                            work,
                            profile,
                          }}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                        >
                          {work?.artis_code}
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ flexGrow: 1, gridRow: "1" }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Box display="flex" justifyContent="flex-end">
                      <Box component="p" display="inline" color="gray">
                        Registered:{" "}
                      </Box>
                      <Box component="span" display="inline">
                        {utils.formatDate(work?.registered_date)}
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                      <Box component="p" display="inline" color="gray">
                        You Own:
                      </Box>
                      <Box component="span" display="inline">
                         {selfOwnership}%
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <br />

              {/* Link to public page */}
              <Link
                to={`/works/${work?.artis_code}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    padding: 2,
                    borderRadius: 4,
                    color: "black",
                    textAlign: "center",
                    fontFamily: "Bellefair, serif",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    border: "2px solid black",
                    marginLeft: "20%",
                    marginRight: "20%",
                  }}
                >
                  View Public Page
                </Box>
              </Link>
              <br />

              {/* Collaborators/Registered by section */}
              {allCollabsbyWork && allCollabsbyWork.length !== 1 ? (
                <Box
                  component="p"
                  display="inline"
                  color="gray"
                  sx={{ marginLeft: "12px" }}
                >
                  Collaborators
                </Box>
              ) : (
                <Box
                  component="p"
                  display="inline"
                  color="gray"
                  sx={{ marginLeft: "12px" }}
                >
                  Registered by
                </Box>
              )}
              {allCollabsbyWork &&
                allCollabsbyWork.map((data, index) => (
                  <Bio
                    openId={openId}
                    setOpenId={setOpenId}
                    collaborator_id={data?.profile?.id}
                    contribution={data?.collaborator?.contribution}
                    ownership={data?.collaborator?.ownership}
                    oncopyright={data?.collaborator?.oncopyright}
                    bio={data?.profile?.artist_bio}
                    sign_status={
                      data?.collaborator.hasOwnProperty("work_creator")
                        ? ""
                        : data?.collaborator?.sign_status
                    }
                    isEdit={
                      user.profile.user.id === data.profile.user_id
                        ? true
                        : false
                    }
                    is_kyced={data?.collaborator?.is_kyced}
                    website={data?.profile?.artist_website}
                    photo={
                      data?.profile?.photo
                        ? `https://artis-stage-data.s3.amazonaws.com/static/${data?.profile?.photo}`
                        : ""
                    }
                    first_name={data?.profile?.first_name.toUpperCase()}
                    last_name={data?.profile?.last_name.toUpperCase()}
                    token={user?.access}
                    signed={
                      data.collaborator.hasOwnProperty("signed")
                        ? data.collaborator.signed
                        : true
                    }
                    handleBioUpdate={handleBioUpdate}
                    artist_type={data?.collaborator?.contribution}
                    allCollabs={allCollabsbyWork}
                    subCollabs={allCollabs}
                    allCommunity={
                      <Box>
                        {/* Known For section */}
                      
                        {(allCommunity[index]?.works_known_for?.length > 0 ||
                          allCommunity[index]?.collaborators_known_for?.length >
                            0) && (
                          <Box
                            component="p"
                            fontSize="0.8rem"
                            display="inline"
                            color="gray"
                            sx={{ marginLeft: "0px" }}
                          >
                           
                            KNOWN FOR
                          </Box>
                        )}
                        {allCommunity[index]?.works_known_for?.length > 0 &&
                          allCommunity[index]?.works_known_for?.map(
                            (work, wIndex) => (
                              <Box
                                sx={{
                                  marginBottom: 1,
                                  padding: "5px 5px 5px 5px",
                                  borderBottom:
                                    allCommunity[index]?.works_known_for
                                      .length +
                                      allCommunity[index]
                                        ?.collaborators_known_for?.length >
                                    1
                                      ? 1
                                      : 0,
                                  borderColor: "#D3D3D3",
                                  marginTop: wIndex === 0 ? 0 : 1,
                                  overflow: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexFlow: "row wrap",
                                    justifyContent: "center",
                                    alignItems: "center", // Corrected from "left"
                                  }}
                                >
                                  <button
                                    type="button"
                                    style={{
                                      background: "none",
                                      border: "none",
                                      padding: "0",
                                      color: "#808080",
                                      cursor: "pointer",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      window.open(
                                        `/works/${work.artis_code}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    {work.work_copy_name ? (
                                      <img
                                        style={{
                                          marginBottom: 8,
                                          objectFit: "cover",
                                          width: 120,
                                        }}
                                        src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(
                                          work.work_copy_name
                                        )}`}
                                        alt="profileimg"
                                      />
                                    ) : (
                                      <ArtTrackIcon style={{ marginBottom: 8 }} />
                                    )}
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: 0,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.title}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#808080",
                                        marginBottom: 0,
                                        lineHeight: 1,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.description}
                                    </Typography>
                                  </button>
                                </Box>
                              </Box>
                            )
                          )}
                        {allCommunity[index]?.collaborators_known_for?.length >
                          0 &&
                          allCommunity[index]?.collaborators_known_for?.map(
                            (work, cIndex) => (
                              <Box
                                sx={{
                                  marginBottom: 1,
                                  padding: "5px 5px 5px 5px",
                                  borderBottom:
                                    allCommunity[index]?.works_known_for
                                      .length +
                                      allCommunity[index]
                                        ?.collaborators_known_for?.length >
                                    1
                                      ? 1
                                      : 0,
                                  borderColor: "#D3D3D3",
                                  marginTop:
                                    cIndex === 0 &&
                                    allCommunity[index]?.works_known_for
                                      ?.length === 0
                                      ? 0
                                      : 1,
                                  overflow: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexFlow: "row wrap",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    type="button"
                                    style={{
                                      background: "none",
                                      border: "none",
                                      padding: "0",
                                      color: "#069",
                                      cursor: "pointer",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      window.open(
                                        `/works/${work.work_artis_code}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    {work.work_copy_name ? (
                                      <img
                                        style={{
                                          marginBottom: 8,
                                          objectFit: "cover",
                                          width: 120,
                                        }}
                                        src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(
                                          work.work_copy_name
                                        )}`}
                                        alt="profileimg"
                                      />
                                    ) : (
                                      <ArtTrackIcon style={{ marginBottom: 8 }} />
                                    )}
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: 0,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.work_title}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#808080",
                                        marginBottom: 0,
                                        lineHeight: 1,
                                        textAlign: "center",
                                      }}
                                    >
                                      {work.work_description}
                                    </Typography>
                                  </button>
                                </Box>
                              </Box>
                            )
                          )}
                        {(allCommunity[index]?.works_known_for?.length > 0 ||
                          allCommunity[index]?.collaborators_known_for?.length >
                            0) && (
                          <Typography
                            fontSize="0.6rem"
                            gutterBottom
                            width="100%"
                            float="right"
                            textAlign="right"
                            color="gray"
                          >
                            TAP ON WORK TO SEE ITS REGISTRATION
                          </Typography>
                        )}
                        {/* Endorsements section */}
                         
                        {data?.endorsements?.length > 0 && (
                          <>
                            <Box
                              component="p"
                              fontSize="0.8rem"
                              display="inline"
                              color="gray"
                              sx={{ marginLeft: "0px" }}
                            >
                              PROFESSIONALLY ENDORSED BY
                            </Box>
                            {data.endorsements.map((endorsement) => (
                              <FameUserItemWrapper
                                user={{
                                  ...endorsement,
                                  photo: endorsement.photo
                                    ? `https://artis-stage-data.s3.amazonaws.com/static/${endorsement.photo}`
                                    : "",
                                }}
                                showOrder={false}
                                name={`${endorsement?.first_name} ${endorsement?.last_name}`}
                                is_kyced={endorsement.is_kyced}
                                shouldExpand={true}
                                userProfile={profile}
                                fromEditOrDetail={true}
                              />
                            ))}
                          </>
                        )}
                        {/* Biography section */}
                        {data?.profile?.user_id !== user.profile.user.id &&
                          data?.profile?.artist_bio && (
                            <>
                              <Box
                                component="p"
                                fontSize="0.8rem"
                                display="inline"
                                color="gray"
                                sx={{ marginLeft: "0px" }}
                              >
                                <br />
                                BIOGRAPHY
                              </Box>
                              <Box
                                component="p"
                                sx={{
                                  whiteSpace: "pre-wrap",
                                  fontSize: "1rem",
                                  fontFamily: "'Montserrat', sans-serif;",
                                  fontWeight: "normal",
                                  textAlign: "left",
                                  mt: 0,
                                }}
                              >
                                {data?.profile?.artist_bio}
                              </Box>
                            </>
                          )}
                        {/* Exhibitions section */}
                        {data?.profile?.user_id !== user.profile.user.id &&
                          allExhibitions &&
                          Object.keys(allExhibitions).length > 0 &&
                          allExhibitions[data.profile.id] &&
                          allExhibitions[data.profile.id].length > 0 && (
                            <>
                              <Box
                                component="p"
                                fontSize="0.8rem"
                                display="block"
                                color="gray"
                                sx={{ marginLeft: "0px", marginTop: 2.5 }}
                              >
                                {allExhibitions[
                                  data.profile.id
                                ][0].eventname.toUpperCase()}
                              </Box>
                              {allExhibitions[data.profile.id].map(
                                (exhibition) => (
                                  <Exhibition data={exhibition} />
                                )
                              )}
                            </>
                          )}
                        {/* Community section */}
                        
                        {(allCommunity[index]?.verified_connections?.some(
                          (fame) =>
                            !data.endorsements.some(
                              (endorsement) =>
                                endorsement.user_id === fame.user.user_id
                            )
                        ) ||
                          allCommunity[index]?.unverified_connections?.length >
                            0) && (
                          <>
                            <Box
                              component="p"
                              fontSize="0.8rem"
                              display="inline"
                              color="gray"
                              sx={{ marginLeft: "0px" }}
                            >
                              <br /><br />
                              COMMUNITY
                            </Box>
                            {allCommunity[index]?.verified_connections
                              .filter(
                                (fame) =>
                                  !data.endorsements.some(
                                    (endorsement) =>
                                      endorsement.user_id === fame.user.user_id
                                  )
                              )
                              .map((fame) => {
                                const customerUser = fame.user;
                                const name =
                                  customerUser?.first_name &&
                                  customerUser?.last_name
                                    ? `${customerUser?.first_name} ${customerUser?.last_name}`
                                    : fame.invitee_name;

                                return (
                                  <FameUserItemWrapper
                                    key={fame.id}
                                    user={{ ...customerUser }}
                                    showOrder={false}
                                    name={name}
                                    is_kyced={customerUser.is_kyced}
                                    order={fame.order}
                                    shouldExpand={true}
                                    userProfile={profile}
                                    fromEditOrDetail={true}
                                  />
                                );
                              })}
                            {allCommunity[index]?.unverified_connections.map(
                              (fame) => {
                                const customerUser = fame.user;
                                const name =
                                  customerUser?.first_name &&
                                  customerUser?.last_name
                                    ? `${customerUser?.first_name} ${customerUser?.last_name}`
                                    : fame.invitee_name;

                                return (
                                  <FameUserItemWrapper
                                    key={fame.id}
                                    user={{ ...customerUser }}
                                    showOrder={false}
                                    name={name}
                                    is_kyced={customerUser.is_kyced}
                                    order={fame.order}
                                    shouldExpand={true}
                                    userProfile={profile}
                                    fromEditOrDetail={true}
                                  />
                                );
                              }
                            )}
                          </>
                        )}
                        <Typography
                          fontSize="0.6rem"
                          gutterBottom
                          width="100%"
                          float="right"
                          textAlign="right"
                          color="gray"
                        >
                          TAP ON NAME TO DISPLAY MORE INFORMATION
                        </Typography>
                      </Box>
                    }
                    organization={data?.profile?.organization}
                    onEditPage={true}
                  />
                ))}

              <br />
              {/* Delete work button */}
              {collabStatus?.addDeleteButton && (
                <Box>
                  <ArtisButton
                    name="Delete Work"
                    onClick={async () => {
                      setIsCollabDialogOpen(true);
                    }}
                    id="delete"
                    sx={{ fontSize: "1.5rem" }}
                    textColor="red"
                  />
                </Box>
              )}
              {/* Approve/Reject collaboration buttons */}
              {collabStatus?.addApproveBtn && (
                <Box>
                  <ArtisButton
                    name="Accept Collaboration"
                    onClick={async () => {
                      setIsConfirmDialogOpen({
                        isVisible: true,
                        type: "approve",
                      });
                    }}
                    id="cancel"
                    sx={{ fontSize: "1.5rem" }}
                    textColor="green"
                  />
                  <ArtisButton
                    name="Decline Collaboration"
                    onClick={async () => {
                      setIsConfirmDialogOpen({
                        isVisible: true,
                        type: "reject",
                      });
                    }}
                    id="cancel"
                    sx={{ fontSize: "1.5rem" }}
                    textColor="red"
                  />
                </Box>
              )}
              <br />

              {/* Collapsible components */}
              <Comments
                openId={openId}
                setOpenId={setOpenId}
                workId={work?.id}
                num_comments={work?.num_comments}
                new_comment={work?.new_comment}
                updateNewComment={updateNewComment}
              />
              <Collection
                openId={openId}
                setOpenId={setOpenId}
                artis_code={artis_code}
              />
              <Add
                openId={openId}
                setOpenId={setOpenId}
                artis_code={artis_code}
                title={work?.title}
                work_creator={work?.work_creator}
                allCollabsbyWork={allCollabsbyWork}
                workEmail={work.emails}
                userEmail={user.profile.email}
                registered_by_id={profile.id}
                copyrightDate={work?.copyright_date}
                current_loggedin_id={Number(
                  JSON.parse(localStorage.getItem("artis_user")).profile.id
                )}
              />
              <Statement
                statement={work?.artist_statement}
                openId={openId}
                setOpenId={setOpenId}
                updateStatement={updateStatement}
                artist_type={getArtistType()}
              />
              <Government
                openId={openId}
                setOpenId={setOpenId}
                sold_tx_hash={work?.sold_tx_hash}
                tx_hash={work?.tx_hash}
                work_copy_fingerprint={work?.work_copy_fingerprint}
                artis_code={artis_code}
                onCopyRight={onCopyRight}
                hidden={false}
                onEdit={true}
              />
              <Community
                openId={openId}
                setOpenId={setOpenId}
                showOnCommunity={work.show_on_community}
                workId={work.id}
                knownFor={knownFor}
                handleKnownFor={handleKnownFor}
              />
              <Email
                openId={openId}
                setOpenId={setOpenId}
                artis_code={work?.artis_code}
                copyright_date={work?.copyright_date}
                copyright_owner={work?.copyright_owner}
                tx_hash={work?.tx_hash}
                title={work?.title}
                work_creator={work?.work_creator}
                handleWarningEmail={handleWarningEmail}
                getPreviousWarnings={getWarnings}
              />
              <VerifyWork
                openId={openId}
                setOpenId={setOpenId}
                fileSize={work?.work_copy_size}
                fileName={work?.work_copy_name}
                registeredDate={work?.registered_date}
                id={work?.id}
                handleVerify={handleVerify}
              />
              <AiDescription
                openId={openId}
                setOpenId={setOpenId}
                workId={work?.id}
                ai_description={work?.AI_description}
                image={work?.work_image}
                aiDescription={work?.ai_description}
              />
              <Blockchain
                openId={openId}
                setOpenId={setOpenId}
                sold_tx_hash={work?.sold_tx_hash}
                tx_hash={work?.tx_hash}
                work_copy_fingerprint={work?.work_copy_fingerprint}
              />
              <GPSLocation
                openId={openId}
                setOpenId={setOpenId}
                location={work?.location}
              />
              <Tags
                openId={openId}
                setOpenId={setOpenId}
                chips={myTags}
                tags={myTags}
                usermode={userMode}
                handleTagsUpdate={handleTagsUpdate}
                options={user?.profile?.default_tags || []}
              />

              <br />
              {/* Modal for restricted actions */}
              <Modal open={isModalOpen} onClose={(e) => setIsModalOpen(false)}>
                <Box
                  sx={{
                    width: 400,
                    height: 164,
                    backgroundColor: "white",
                    margin: "auto",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    padding: "30px 20px",
                  }}
                >
                  <Typography sx={{ mt: 2, color: "black" }}>
                    Only the original person who registered this work can change
                    the {modalState}
                  </Typography>
                  <Button
                    onClick={(e) => setIsModalOpen(false)}
                    sx={{ float: "right", marginRight: "20px", marginTop: "5px" }}
                  >
                    OK
                  </Button>
                </Box>
              </Modal>
              {/* Delete collaboration dialog */}
              <DeleteCollabDialog
                handleClose={() => setIsCollabDialogOpen(false)}
                open={isCollabDialogOpen}
                deleteWorkAndCollab={async () => {
                  try {
                    await WorkService.deleteWorkAndCollab(work.id);
                    history.push("/works");
                  } catch (error) {}
                }}
              />
              {/* Confirm collaboration dialog */}
              {confirmDialog?.isVisible && (
                <ConfirmCollabDialog
                  handleClose={() =>
                    setIsConfirmDialogOpen({ isVisible: false, type: "" })
                  }
                  open={confirmDialog?.isVisible}
                  approveText={
                    confirmDialog?.type === "approve" ? "approve" : "reject"
                  }
                  onAccept={async () => {
                    if (confirmDialog?.type === "approve") {
                      setCollabStatus(defaultCollab);
                      await handleCollabSign(true);
                      const { work } = await fetchWork(artis_code);
                      await fetchCollabs(work);
                      setIsConfirmDialogOpen({ isVisible: false, type: "" });
                    }
                    if (confirmDialog?.type === "reject") {
                      setCollabStatus(defaultCollab);
                      await handleCollabSign(false);
                      const { work } = await fetchWork(artis_code);
                      await fetchCollabs(work);
                      history.push("/works");
                      setIsConfirmDialogOpen({ isVisible: false, type: "" });
                    }
                  }}
                />
              )}
              <br />
              <br />
              {/* Proof of registration sharing options */}
              <Box>
                <Box component="p" ml={3} mr={3} fontSize="20px">
                  <strong>SEND PROOF OF REGISTRATION</strong>
                  <br />
                  <br />
                  <Box component="p" ml={3} mr={3} fontSize="15px">
                    By <strong>Link</strong>: <br />
                    Email a link that sends people directly to your Proof.
                    <br />
                    <br />
                    <em>Link:</em> https://artis.app/works/{work?.artis_code}
                    <Tooltip title="Copy Link to Proof of Registration">
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://artis.app/works/${work?.artis_code}`
                          );
                          toast.success(
                            "Link to the Proof copied to clipboard!",
                            {
                              position: "bottom-left",
                              autoClose: 2000,
                              hideProgressBar: true,
                            }
                          );
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <br />
                  <Box component="p" ml={3} mr={3} fontSize="15px">
                    By <strong>ArtisCode</strong>: <br />
                    Inputing your Proof of Registration's ArtisCode into
                    Artis.app's front page, sends people to your Proof. Code is
                    easy to add to front pages of documents and scripts.
                    <br />
                    <br />
                    <em>ArtisCode: </em> {work?.artis_code}
                    <Tooltip title="Copy Artis Code">
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(work?.artis_code);
                          toast.success(`Aris Code copied to clipboard!`, {
                            position: "bottom-left",
                            autoClose: 2000,
                            hideProgressBar: true,
                          });
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <br />
                  <Box component="p" ml={3} mr={3} fontSize="15px">
                    By <strong>QRCode</strong>: <br />
                    QR Code below is encoded wtih a link to your Proof of
                    Registration. Attach this QR code to your original and when
                    people scan it with mobile phones, your Proof of
                    Registration will automatically appear.
                    <br />
                    <br />
                    <div align="center">
                      <iframe
                        title="QR code explainer"
                        src="https://player.vimeo.com/video/755622176?h=1b0c5ce2f4"
                        width="300"
                        height="300"
                        style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <br />
                    <Box component="p" ml={3} mr={3}>
                      <div align="center">
                        <a
                          onClick={downloadQR}
                          style={{
                            color: "black",
                            cursor: "pointer",
                            fontSize: "1rem",
                          }}
                        >
                          <QRCode
                            id={work?.artis_code}
                            value={`https://artis.app/works/${work?.artis_code}`}
                            size={100}
                          />
                          <br />
                          Click on QR code to download.
                        </a>
                      </div>
                    </Box>
                  </Box>
                  <br />
                  <Box component="p" ml={3} mr={3} fontSize="15px">
                    By <strong>NFC </strong>:
                    <br />
                    Mobile phones automatically scan NFC tags and load up
                    weblinks stored within. Apps used to encode weblinks into
                    NFCs and NFC tags are inexpensive.
                    <br />
                    <br />
                    <div align="center">
                      <iframe
                        title="explainer"
                        src="https://player.vimeo.com/video/754282297?h=dc4c3f484f"
                        width="300"
                        height="300"
                        style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <br />
                    Encode the link below into an NFC tag and attach the tag to
                    your work. We currently recommend using the app{" "}
                    <strong> NFC.cool</strong> over NFC Tools.
                    <br />
                    <em>NFC Link:</em> https://artis.app/works/
                    {work?.artis_code}
                    <Tooltip title="Copy NFC Link">
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://artis.app/works/${work?.artis_code}`
                          );
                          toast.success("NFC Link copied to clipboard!", {
                            position: "bottom-left",
                            autoClose: 2000,
                            hideProgressBar: true,
                          });
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
            <ArtisFooter />
          </Container>
        </Slide>
      )}
      {/* Image modal */}
      <Modal
        open={openImage}
        onClose={() => setOpenImage(false)}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={openImage} timeout={500} sx={{ outline: "none" }}>
          <img
            src={image}
            alt="asd"
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          />
        </Fade>
      </Modal>
    </div>
  );
}
