/**
 * AiBioGoals component allows users to input and update their personal biography and goals for the AI system.
 * The biography and goals are used to provide more personalized and accurate responses from the AI.
 * The component includes a form with two text fields for the biography and goals, and a submit button to update the information.
 */

import Box from "@mui/material/Box";
import { CloudOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import CollapsibleBody from "../collapsible";
import ArtisButton from "../buttons/button";
import { Controller, useForm } from "react-hook-form";
import ArtisInput from "../inputs/textfield";
import { useState } from "react";

// Component for handling AI Bio and Goals input
export default function AiBioGoals(props) {
  // Set up form with default values from props
  const { handleSubmit, control } = useForm({
    defaultValues: {
      ai_bio: props.ai_bio,
      ai_goals: props.ai_goals,
    },
  });

  // Local state to track current input values
  const [aiBio, setAiBio] = useState(props.ai_bio);
  const [aiGoals, setAiGoals] = useState(props.ai_goals);

  // Handle form submission by calling the prop function
  const _handleSubmit = async (data) => {
    try {
      await props.handleAiBio_AiGoalsUpdate(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Render the collapsible section for AI Bio and Goals
  return (
    <CollapsibleBody
      expanded={props.openId === "aiBio-Goals"}
      id="aiBio-Goals"
      title="AI Bio and Goals"
      icon={<CloudOutlined />}
      handleClick={props.setOpenId}
      mainSx={{
        alignItems: "flex-start",
        fontWeight: "normal",
        fontSize: "1.4rem",
      }}
    >
      {/* Form container with styling */}
      <Box
        m={4}
        sx={{ flexGrow: 1 }}
        component="form"
        ml={1.5}
        mr={1.5}
        noValidate
        // onSubmit={handleSubmit((data) => _handleSubmit(data))}
      >
        {/* Instructions for AI Bio input */}
        <Typography fontSize="1rem">
          Your personal biography, which we will not share with anyone, not even
          the people running Artis.app. Please detail as much as you can. You
          can even include things about yourself that are outside of your
          creative interests. This is important as the more your AI team knows about you,
          the better and more accurately we can respond to you.
        </Typography>
        
        {/* Controlled input for AI Bio */}
        <Controller
          control={control}
          name="ai_bio"
          render={({ ref, formState, fieldState, ...rest }) => (
            <ArtisInput
              label="AI Bio"
              id="ai-bio"
              multiline
              InputLabelProps={{
                required: false,
                sx: { color: "teal" },
                shrink: true,
              }}
              onChange={(e) => {
                // Enforce limit to 768 characters, but maxLength is set to 500 in props
                const newValue = e.target.value.slice(0, 768);
                rest.field.onChange(newValue);
                setAiBio(newValue);
              }}
              value={rest.field.value}
              fontFamily="Montserrat', sans-serif"
              {...rest}
              fontSize="0.5 rem" // Possible typo: should be "0.5rem"
              maxLength={500}
            />
          )}
        />

        {/* Instructions for AI Goals input */}
        <Typography fontSize="1rem" mt={5}>
          Your personal goals, which we will not share with anyone, not even the
          people running Artis.app. This will also help us give more
          personalized and accurate answers.
        </Typography>

        {/* Controlled input for AI Goals */}
        <Controller
          control={control}
          name="ai_goals"
          render={({ ref, formState, fieldState, ...rest }) => (
            <ArtisInput
              label="AI goal"
              id="ai-goal"
              multiline
              InputLabelProps={{
                required: false,
                sx: { color: "teal" },
                shrink: true,
              }}
              onChange={(e) => {
                // Enforce limit to 768 characters, but maxLength is set to 500 in props
                const newValue = e.target.value.slice(0, 768);
                rest.field.onChange(newValue);
                setAiGoals(newValue);
              }}
              value={rest.field.value}
              fontFamily="Montserrat', sans-serif"
              {...rest}
              fontSize="0.5 rem" // Possible typo: should be "0.5rem"
              maxLength={500}
            />
          )}
        />

        {/* Submit button to update AI Bio and Goals */}
        <ArtisButton
          // disabled={!(isValid && dirty)} // Uncomment to disable based on form validity and dirtiness
          onClick={handleSubmit((data) => _handleSubmit(data))}
          name="Update"
          size="medium"
          id="update"
          sx={{
            fontSize: "1.5rem",
            marginTop: "20px",
          }}
          // Note: trim(" ") is not standard; should be trim() to remove all whitespace
          disabled={!aiBio?.trim(" ") || !aiGoals?.trim(" ")} // Check if both fields are not empty after trimming
        />
      </Box>
    </CollapsibleBody>
  );
}
