import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Container,
  CircularProgress,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import EXIF from "exif-js";
import heic2any from "heic2any";
import config from "../config";
import InfoDialog from "../components/dialogs/info-dialog";
import TagsInput from "../components/inputs/chips-new";
import DateInput from "../components/inputs/date-picker";
import { CollaboratorCard } from "../components/collaborator_card/collaboratorcard";

import WorkService from "../api/service";
import { useUser } from "../hooks/user";
import EmailInputs from "../components/inputs/emails";
import ArtisButton from "../components/buttons/button";
import ArtisFooter from "../components/footer";
import AddCollabDialog from "../components/dialogs/add-collab";
import UserService from "../api/auth";
import ErrorDialog from "../components/dialogs/error-dialog";
import utils from "../utils";

const getMediaUrl = (path) => `${config.url.S3_URL}/${path}`;

const FrameModal = ({
  open,
  handleClose,
  setWorkIcon,
  setValues,
  frames = [],
  defaultSelected = "",
}) => {
  const [selected, setSelected] = React.useState(defaultSelected);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Typography
          textAlign="center"
          sx={{
            fontSize: "1em",
          }}
        >
          Select frame to display on Proof of Registration
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={1}>
          {frames.map((frame) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    objectFit: "cover",
                    cursor: "pointer",
                    border:
                      frame.name === selected.name ? "3px solid #72bcd4" : null,
                  }}
                  src={getMediaUrl(frame.name)}
                  alt="frame"
                  onClick={() => setSelected(frame)}
                />
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ArtisButton
          name="Cancel"
          sx={{
            fontSize: "1.5rem",
            color: "red",
          }}
          onClick={() => {
            setWorkIcon(getMediaUrl(defaultSelected || frames[0].name));
            handleClose();
          }}
        />
        <ArtisButton
          name="Select"
          onClick={() => {
            setWorkIcon(getMediaUrl(selected.name));
            setValues(selected);
            handleClose();
          }}
          sx={{
            fontSize: "1.5rem",
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

const initialValues = {
  title: "",
  description: "",
  work_creator: "",
  copyright_date: new Date(),
  copyright_owner: "",
  tags: [],
  work_copy_name: "",
  work_copy_size: null,
  work_copy_fingerprint: "",
  support_document_name: "",
  support_document_size: null,
  support_document_fingerprint: "",
  image_id: "",
  pdficon_id: "",
  work_type: "Photograph",
  emails: [],
};

const WORK_TYPES = {
  Photograph: "/images/artis_icons/photographIcon.png",
  Film: "/images/artis_icons/filmIcon.png",
  Art: "/images/artis_icons/artIcon.png",
  Multimedia: "/images/artis_icons/multimediaIcon.png",
  Dance: "/images/artis_icons/danceIcon.png",
  Program: "/images/artis_icons/programIcon.png",
  Website: "/images/artis_icons/websiteIcon.png",
  Book: "/images/artis_icons/bookIcon.png",
  Story: "/images/artis_icons/storyIcon.png",
  Script: "/images/artis_icons/scriptIcon.png",
  Music: "/images/artis_icons/musicIcon.png",
};

const schema = yup.object({
  work_copy_name: yup.string().required(),
  title: yup.string().required(),
  description: yup.string().required(),
});

export default function CreateWork(props) {
  const { user } = useUser();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [sendCommunityEmail, setSendCommunityEmail] = useState(false);
  const [_audio, _setAudio] = useState(null);
  const [image_id, setImageId] = useState(null);

  const defaultValues = {
    ...initialValues,
    work_creator: `${user?.profile?.first_name} ${user?.profile?.last_name}`,
    copyright_owner: `${user?.profile?.first_name} ${user?.profile?.last_name}`,
    tags: user?.profile?.default_tags || [],
    work_type: "Photograph",
    emails: user?.profile?.email ? [user.profile.email] : [],
  };

  const [isFrameModalOpen, setIsFrameModalOpen] = React.useState(false);
  const [frameImages, setFrameImages] = useState([]);
  const [_file, setFile] = useState(null);

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState,
    reset,
    trigger,
  } = useForm({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  const [isCollabModalOpen, setIsCollabModalOpen] = useState(false);
  const [workIcon, setWorkIcon] = useState("");
  const [defaultWorkIcon, setDefaultWorkIcon] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [fileLoading, setFileLoading] = useState("");
  const [message, setMessage] = useState("");
  const [selectCover, setSelectCover] = useState(false);
  const [artistType, setArtistType] = useState(user?.profile?.artist_type);
  const [fameConnections, setFameConnections] = useState(null);
  const [allCollabs, setAllCollabs] = useState([]);
  const [percentage, setPercentage] = useState(100);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allPercentages, setAllPercentages] = useState({});
  const [isModalCheckContri, setIsModalCheckContri] = useState(false);
  const [glSearchTabs, setGLSearchTabs] = useState([]);
  const [sendGPSLocation, setSendGPSLocation] = useState(false);
  const [gpsSwitchEnabled, setGpsSwitchEnabled] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [locationData, setLocationData] = useState(null);
  const [uploadError, setUploadError] = useState(false);
  const [showOnCommunity, setShowOnCommunity] = useState(true);
  const [creatorHasCopyright, setCreatorHasCopyright] = useState(true);
  const history = useHistory();
  const theme = useTheme();
  React.useEffect(() => {
    document.title = "Add Work | Artis.app";
    const audio = new Audio("https://artis.app/Artis.Success.wav");
    audio.load();
    _setAudio(audio);
  }, []);

  React.useEffect(() => {
    const fetchConnections = async () => {
      try {
        const response = await UserService.getFameConnections();
        if (response.result) {
          const allConnections = response.result.all_fame.map((fame) => {
            const customUser =
              user.profile.user.id === fame?.sender[0].user_id
                ? fame?.invitee[0]
                : fame?.sender[0];
            return { ...customUser, isSelected: false };
          });
          setFameConnections(allConnections);
        }
      } catch (error) {}
    };
    fetchConnections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (window.innerWidth > 768) {
      setGpsSwitchEnabled(false);
    } else {
      setGpsSwitchEnabled(true);
    }
  }, []);

  const { isDirty, isValid } = formState;

  const getWorkIcon = (type, file = null, response = null) => {
    if (type.includes("image") && response?.result?.image_id) {
      setWorkIcon(URL.createObjectURL(file));
    } else if (response.result?.icon_path) {
      setWorkIcon(getMediaUrl(response.result.icon_path));
      setDefaultWorkIcon(response.result.icon_path);
    } else {
      setSelectCover(true);
      setWorkIcon(WORK_TYPES["Photograph"]);
    }
  };

  // Fixed handleFileUpload function
  const handleFileUpload = async (event, type) => {
    let file = event.target.files[0];

    setFile(file);
    if (file?.size > 25 * 1024 * 1024) {
      toast.error("File size exceeds 25MB limit.");
      return;
    }

    if (file.name.includes(".heic") || file.name.includes(".HEIC")) {
      await heic2any({
        blob: file,
        toType: "image/jpg",
      }).then(function (resultBlob) {
        file = new File(
          [resultBlob],
          file.name.replace(".heic", "").replace(".HEIC", "") + ".jpg",
          { type: "image/jpeg", lastModified: file.lastModified }
        );
      });
    }

    if (event?.target && event.target?.files.length) {
      const formData = new FormData();
      formData.append("file", file);

      if (file.type.includes("image")) {
        formData.append("image", true);
      }
      if (type === "file") {
        formData.append("work_copy", true);
      }

      const isVideoType = file.type.match("video.*") ? "video" : null;
      setFileLoading(type);

      // Set initial copyright_date to file's last modified date
      if (file.lastModified) {
        setValue("copyright_date", new Date(file.lastModified));
      }

      // Attempt to set copyright_date from EXIF DateTimeOriginal
      EXIF.getData(file, function () {
        const allMetaData = EXIF.getAllTags(this);
        if (allMetaData && allMetaData.DateTimeOriginal) {
          const dateTimeStr = allMetaData.DateTimeOriginal; // e.g., "2023:10:15 14:30:00"
          const [datePart, timePart] = dateTimeStr.split(" ");
          if (datePart && timePart) {
            const [year, month, day] = datePart.split(":").map(Number);
            const [hour, minute, second] = timePart.split(":").map(Number);
            // Create Date object, month is 0-based in JS
            const exifDate = new Date(year, month - 1, day, hour, minute, second);
            if (!isNaN(exifDate.getTime())) {
              setValue("copyright_date", exifDate);
            } else {
              console.warn("Invalid EXIF DateTimeOriginal:", dateTimeStr);
            }
          }
        }
      });

      try {
        let response = null;
        if (isVideoType) {
          response = await WorkService.uploadVideoFile(formData);
          if (response.result.frame_images) {
            setDefaultWorkIcon(response.result.icon_path);
            setFrameImages(response.result.frame_images);
            setIsFrameModalOpen(true);
          }
        } else {
          response = await WorkService.uploadFile(formData);
        }

        const result = response.result;
        setImageId(result?.image_id);

        if (response?.error) {
          setUploadError(true);
          setFileLoading("");
          return;
        }

        if (type === "file") {
          getWorkIcon(file.type, file, response);
          setValue("image_id", result?.image_id || "");
          setValue("pdficon_id", result?.pdficon_id || "");
          setValue("work_copy_name", result.filename, {
            shouldValidate: false,
          });
          setValue("work_copy_size", result.filebytes);
          setValue("work_copy_fingerprint", result.fingerprint);
        } else if (type === "cover") {
          setSelectCover(false);
        } else {
          setValue("support_document_name", result.filename, {
            shouldValidate: false,
          });
          setValue("support_document_size", result.filebytes);
          setValue("support_document_fingerprint", result.fingerprint);
        }

        setFileLoading("");
        setUploadError(false);
      } catch (e) {
        setMessage(e.message);
        setFileLoading("");
        setUploadError(false);
      }
    }
    trigger();
  };

  const handleIconSelect = (e) => {
    setWorkIcon(WORK_TYPES[e.target.value]);
    setValue("work_type", e.target.value);
  };

  const _handleSubmit = async (data, e) => {
    var all_artists = document.getElementsByName("artist_type");
    var all_artists_values = [];
    for (var i = 0; i < all_artists.length; i++) {
      all_artists_values.push(all_artists[i].value);
    }

    if (!all_artists_values.includes("")) {
      setIsloading(true);
      data.copyright_date =
        creatorHasCopyright ||
        allCollabs.some((collab) => collab.oncopyright === true)
          ? data.copyright_date.toISOString().slice(0, 10)
          : null;
      data.sendCommunityEmail = sendCommunityEmail;
      data.collaboratorsExist = false;
      if (allCollabs.length > 0) {
        data.collaboratorsExist = true;
      }
      data.ownership = percentage;
      data.artist_type = artistType;
      data.show_on_community = showOnCommunity;
      data.hasCopyright = creatorHasCopyright;

      if (gpsSwitchEnabled && sendGPSLocation) {
        if (setLocationData !== null) {
          data.location = locationData;
        } else {
          setIsErrorModalOpen(true);
          setSendGPSLocation(false);
          setIsloading(false);
          return;
        }
      }

      const response = await WorkService.createWork(data);
      allCollabs.forEach(async (con) => {
        try {
          await WorkService.createCollaborators({
            work_id: response.result.id,
            collab_id: con.user_id,
            registered_by_id: user.profile.user.id,
            contribution: con.artist_type,
            ownership: con.hasOwnProperty("ownership") ? con.ownership : 0,
            searchtabs: JSON.stringify(glSearchTabs),
            oncopyright: con.hasOwnProperty("oncopyright")
              ? con.oncopyright
              : false,
          });
        } catch (error) {
          console.error(error);
        }
      });
      setIsloading(false);

      setSelectCover(false);
      setWorkIcon("");

      if (response?.result?.success) {
        reset();
        const work = response.result;
        if (_file.name.includes(".pdf")) {
          const formData = new FormData();
          formData.append("pdfFile", _file);
          formData.append("workId", work.id);
          console.log("formData", formData);

          try {
            const response = await WorkService.CreateAI_Description(formData);
            if (response.result) {
              console.log("response", response);
            }
          } catch (error) {
            toast.error("Error creating ai description.");
            console.error(error);
          }
        } else if (image_id) {
          try {
            const response = await WorkService.CreateAI_Description({
              imageId: image_id,
              workId: work.id,
            });
            if (response.result) {
              console.log("response", response);
            }
          } catch (error) {
            toast.error("Error creating ai description.");
            console.error(error);
          }
        }
        const successMessage = (
          <div>
            <img alt="Artis.app" src="https://artis.app/images/peace.jpg" />
            <p>
              <strong>CONGRATS!!!</strong>
            </p>
            <br />
            <p>'{work.title}'</p>
            {allCollabs.length > 0 && (
              <p>sent to collaborators for approval!</p>
            )}
            {allCollabs.length === 0 && <p>is registered on the blockchain!</p>}
          </div>
        );
        playSuccessAudio();
        setAllCollabs([]);
        toast.success(successMessage, {
          onClose: () => {
            history.push("/works");
          },
        });
      } else {
        toast.error(
          `Something went wrong while submitting your original, please try again!`,
          {
            onClose: () => {},
          }
        );
      }
      window.scrollTo(0, 0);
    } else {
      setIsModalCheckContri(true);
    }
  };

  const playSuccessAudio = () => {
    if (_audio) {
      _audio.play();
    }
  };

  const selectedCollaborators = fameConnections?.some((con) => con.isSelected);

  const handleGPSSwitch = () => {
    setSendGPSLocation(!sendGPSLocation);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocationData({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      () => {
        setIsErrorModalOpen(true);
        setSendGPSLocation(false);
      }
    );
  };

  const styles = {
    "artis-input": {
      marginTop: "0",
      marginBottom: "5%",
      borderColor: "teal !important",
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "1rem",
      [theme.breakpoints.down(490)]: {
        width: window.innerWidth - 70,
      },
    },
  };

  return (
    <Container maxWidth="md">
      <FrameModal
        open={isFrameModalOpen}
        handleClose={() => setIsFrameModalOpen(false)}
        frames={frameImages}
        setWorkIcon={setWorkIcon}
        defaultSelected={defaultWorkIcon}
        setValues={(frame) => {
          setValue("image_id", frame.id || "");
          setValue("work_copy_name", frame.filename, { shouldValidate: true });
        }}
      />
      <Typography mb={1} textAlign="center" fontSize="1.3rem">
        {message}
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: theme.palette.primary,
          overflow: "hidden",
          display: "grid",
          boxShadow: 2,
          backgroundSize: "cover",
          backgroundPosition: "0 400px",
          padding: 1,
        }}
      >
        <Box
          component="form"
          id="work_form"
          noValidate
          sx={{
            mt: 1,
            marginRight: "2%",
            marginLeft: "2%",
            marginTop: "5%",
          }}
        >
          <Grid item>
            <Typography
              component="div"
              fontSize="12px"
              textAlign="center"
              color="gray"
              sx={{
                [theme.breakpoints.down(490)]: {
                  width: window.innerWidth - 70,
                },
              }}
            >
              TO REGISTER A WORK ON THE BLOCKCHAIN, COMPLETE STEPS 1 THROUGH 5.
            </Typography>
          </Grid>
          <br />
          <br />
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              [theme.breakpoints.down(500)]: {
                width: window.innerWidth - 70,
              },
            }}
          >
            <img
              src="https://artis.app/images/Artis_one.jpg"
              alt="ONE"
              width="50"
              height="50"
            />
          </Typography>
          <Grid
            container
            direction={window.innerWidth > 490 ? "row" : "column"}
            sx={{
              [theme.breakpoints.down(490)]: {
                width: window.innerWidth - 70,
              },
            }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: "1.5em",
                  fontFamily: "Bellefair, serif",
                }}
              >
                Select the file containing your work, for Artis.app to digitally
                fingerprint.
              </Typography>
            </Grid>
            <Grid item xs={3} sm>
              <InfoDialog
                title="Digitally Fingerprint."
                subtitle="The file you are digitally fingerprinting should somehow capture the original you want to register on the blockchain. For example, you can fingerprint a JPG of your photo, or mobile phone photo of your painting or the furniture you built. You can fingerprint your script as a text file or pdf or microsoft word format. You can fingerprint a MP3 audio file of your music or speech. Under most copyright laws, a creative work has to be presevered in some manner for it to gain protection. So for example, a dance is not protected just because you danced it. Nor an improvised speech just because you spoke it.  When you use your mobile phone to record a video of your dance or speech, etc, and then fingerprint that video with Artis.app, the you have created a copyright and protected it."
                examples={[
                  "MonaLisa_photo.jpg",
                  "Imagine.mp3",
                  "RomeoAndJuliet.pdf",
                  "NativeSon.doc",
                ]}
              />
            </Grid>
            <Grid item></Grid>
          </Grid>
          <TextField
            variant="standard"
            style={{
              textTransform: "none",
            }}
            margin="normal"
            required
            fullWidth
            id="work_copy_name"
            name="work_copy_name"
            sx={styles["artis-input"]}
            InputProps={{
              style: {
                fontSize: "1.2rem",
              },
            }}
            disabled={isLoading}
            InputLabelProps={{
              required: false,
              sx: { color: "teal" },
              shrink: true,
            }}
            onClick={(event) => document.getElementById("upload_file").click()}
            value={getValues(["work_copy_name"])}
          />
          {workIcon && (
            <Box
              textAlign="center"
              sx={{
                mb: "2%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                [theme.breakpoints.down(490)]: {
                  width: window.innerWidth - 70,
                },
              }}
            >
              <img
                src={workIcon}
                style={{ maxHeight: "200px", maxWidth: "200px" }}
                alt="Work icon"
              />
              <Typography color="black" display="flex">
                {getValues(["work_copy_name"])} uploaded and fingerprinted.
              </Typography>
            </Box>
          )}
          {fileLoading === "file" && (
            <Box
              textAlign="center"
              sx={{
                mb: "2%",
                [theme.breakpoints.down(490)]: {
                  width: window.innerWidth - 70,
                },
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <input
            id="upload_file"
            type="file"
            onChange={(event) => handleFileUpload(event, "file")}
            style={{ display: "none" }}
          />
          {uploadError && (
            <Grid container direction="row">
              <Grid item>
                <Typography
                  color="red"
                  textAlign="center"
                  sx={{
                    fontSize: "1.5em",
                  }}
                >
                  The file you uploaded exceeds the maximum allowed size of
                  25MB. Please choose a smaller file and try again.
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
          )}
          {selectCover && (
            <>
              <Grid container direction="row">
                <Grid item>
                  <Typography
                    color="green"
                    textAlign="left"
                    sx={{
                      fontSize: "1.5em",
                      [theme.breakpoints.down(490)]: {
                        width: window.innerWidth - 70,
                      },
                    }}
                  >
                    As your work is not an image, please select an icon to
                    represents this work inside Artis.app. <br /> <br /> Note
                    that this icon will not be shown on Certificates.
                  </Typography>
                </Grid>
                <Grid item></Grid>
              </Grid>
              <br />
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  [theme.breakpoints.down(490)]: {
                    width: window.innerWidth - 70,
                  },
                }}
              >
                <Select
                  native={false}
                  lable="Cover"
                  value={getValues(["work_type"])}
                  onChange={handleIconSelect}
                  renderValue={(v) => {
                    return (
                      <img
                        style={{ height: 50, width: 50 }}
                        src={WORK_TYPES[v]}
                        alt={v}
                      />
                    );
                  }}
                >
                  {Object.keys(WORK_TYPES).map((k) => (
                    <MenuItem value={k}>{k}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <br />
              <br />
            </>
          )}
          <br />
          <br />
          <br />
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              [theme.breakpoints.down(500)]: {
                width: window.innerWidth - 70,
              },
            }}
          >
            <img
              src="https://artis.app/images/Artis_two.jpg"
              alt="TWO"
              width="50"
              height="50"
            />
          </Typography>
          <Grid
            container
            direction={window.innerWidth > 490 ? "row" : "column"}
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: "1.5em",
                  fontFamily: "Bellefair, serif",
                }}
              >
                What is the work's title?
              </Typography>
            </Grid>
            <Grid item xs={3} sm>
              <InfoDialog
                title="Title"
                subtitle="This is the name of your original work. It does not mean this is the name of the file you are uploading. If you upload a photo file named SFSSS.jpg of your canvas painting called BOO, you would set this title to be BOO"
                examples={["Mona Lisa", "Casablanca", "Ninth Symphony"]}
              />
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                variant="standard"
                style={{ textTransform: "none" }}
                margin="normal"
                required
                fullWidth
                id="title"
                label="ex: Mona Lisa"
                name="Title"
                autoComplete="title"
                sx={styles["artis-input"]}
                InputProps={{
                  classes: {
                    teal: {
                      root: {
                        "&:hover:not($disabled):not($focused):not($error) $notchedOutline":
                          {
                            borderColor: "teal !important",
                          },
                      },
                    },
                  },
                  style: {
                    fontSize: "1.1em",
                  },
                }}
                InputLabelProps={{ required: false, sx: { color: "#9E9E9E" } }}
                {...field}
              />
            )}
          />
          <br />
          <br />
          <br />
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              [theme.breakpoints.down(500)]: {
                width: window.innerWidth - 70,
              },
            }}
          >
            <img
              src="https://artis.app/images/Artis_three.jpg"
              alt="THREE"
              width="50"
              height="50"
            />
          </Typography>
          <Grid
            container
            direction={window.innerWidth > 490 ? "row" : "column"}
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: "1.5em",
                  fontFamily: "Bellefair, serif",
                }}
              >
                How would you describe the work?
              </Typography>
            </Grid>
            <Grid item xs={1} sm>
              <InfoDialog
                title="Description"
                subtitle="Describe what is the work itself, not the file you are uploading. For example, if you photographed your oil painting and uploaded the resulting JPG image, in this description you would write Oil Painting, not JPG nor image. If you uploaded a film script in PDF format, you would write Film Script, not PDF. "
                examples={[
                  "12x12cm oil painting on canvas",
                  "Fashion Photo",
                  "Short Story",
                  "TV Pilot Script",
                  "Piano Composition",
                  "Pitch Deck",
                ]}
              />
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                variant="standard"
                style={{ textTransform: "none" }}
                margin="normal"
                required
                sx={styles["artis-input"]}
                fullWidth
                name="description"
                label="ex: 77cmx 53cm oil on canvas"
                id="description"
                autoComplete="description"
                InputProps={{
                  classes: {
                    teal: {
                      root: {
                        "&:hover:not($disabled):not($focused):not($error) $notchedOutline":
                          {
                            borderColor: "teal !important",
                          },
                      },
                    },
                  },
                  style: {
                    fontSize: "1.1rem",
                  },
                }}
                InputLabelProps={{ required: false, sx: { color: "#9E9E9E" } }}
                {...field}
              />
            )}
          />
          <br />
          <br />
          <br />
          <Grid container direction="row" mb={3}>
            <Grid item>
              <Typography
                sx={{
                  fontSize: "1.5em",
                  fontFamily: "Bellefair, serif",
                }}
              >
                Created by:{" "}
              </Typography>
            </Grid>
            <Grid item xs={3} sm></Grid>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                height: 200,
                width: 460,
                borderRadius: "15px",
                backgroundColor: "rgba(240, 250, 240, 0.9)",
                margin: "auto",
                [theme.breakpoints.down(530)]: {
                  margin: 0,
                  width: window.innerWidth - 70,
                },
                [theme.breakpoints.down(440)]: {
                  margin: 0,
                  height: 250,
                  fontSize: "1.0em",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.5em",
                  fontWeight: "bold",
                  marginTop: "10px",
                  textAlign: "left",
                  paddingTop: "15px",
                  [theme.breakpoints.down(425)]: {
                    fontSize: "1.4em",
                  },
                }}
                color="text.secondary"
              >
                   
                {`${user?.profile?.first_name} ${user?.profile?.last_name}`}
              </Typography>
              <Grid
                sx={{
                  margin: "10px 20px 0 20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1em",
                    display: "inline-block",
                    mr: 3,
                  }}
                >
                  Contribution
                </Typography>
                <Typography sx={{ display: "inline-block", width: "100%" }}>
                  <TextField
                    variant="standard"
                    style={{ textTransform: "none" }}
                    required
                    fullWidth
                    id="user_artist_type"
                    name="artist_type"
                    autoComplete="artist_type"
                    value={artistType}
                    onChange={(e) => setArtistType(e.target.value)}
                  />
                </Typography>
              </Grid>
              <Grid sx={{ margin: "20px 20px 0 20px" }}>
                <Typography
                  sx={{
                    fontSize: "1em",
                    display: "inline-block",
                    marginTop: "-5px",
                    mr: 3,
                  }}
                >
                  Ownership
                </Typography>
                <Typography
                  sx={{ display: "inline", color: "black", width: "100%" }}
                >
                  {percentage}%
                </Typography>
              </Grid>
              <Grid sx={{ margin: "10px 20px 0 20px" }}>
                <Typography
                  sx={{
                    fontSize: "1em",
                    display: "inline-block",
                    mr: 3,
                  }}
                >
                  Has Copyright
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={creatorHasCopyright}
                      inputProps={{ "aria-label": "Switch" }}
                      sx={utils.switchStyle}
                      onChange={(e) => setCreatorHasCopyright(e.target.checked)}
                    />
                  }
                />
              </Grid>
            </Typography>
          </Grid>
          <Grid container direction="row">
            <Grid item sx={{ margin: "auto", width: "460px" }}>
              {!isCollabModalOpen && selectedCollaborators && (
                <Typography sx={{ mb: 2 }}>Selected Collaborators:</Typography>
              )}
              {allCollabs?.map((con, index) => {
                return (
                  <CollaboratorCard
                    key={con.id}
                    value={con}
                    onChangeContri={(value) => (con.artist_type = value)}
                    onChangeCopyright={(check) => {
                      setAllCollabs((prevCollabs) => {
                        const newArray = [...prevCollabs];
                        newArray[index] = {
                          ...newArray[index],
                          oncopyright: check,
                        };
                        return newArray;
                      });
                    }}
                    defaultValue={
                      allPercentages["percentage" + con.id]
                        ? allPercentages["percentage" + con.id]
                        : 0
                    }
                    onChangePercentage={(value) => {
                      var all_percentages = allPercentages;
                      all_percentages["percentage" + con.id] = parseInt(value);
                      con["ownership"] = parseInt(value);
                      var all_values = Object.values(all_percentages);
                      var sum_percentages = all_values.reduce(
                        (a, b) => a + b,
                        0
                      );
                      setAllPercentages(all_percentages);
                      if (sum_percentages > 100) {
                        all_percentages["percentage" + con.id] = 0;
                        con["ownership"] = parseInt(value);
                        setPercentage(100 - sum_percentages + parseInt(value));
                        setIsModalOpen(true);
                      } else {
                        setPercentage(100 - sum_percentages);
                      }
                    }}
                    onCloseClick={() => {
                      const index = allCollabs.indexOf(con);
                      if (index !== -1) {
                        const newArray = [...allCollabs];
                        newArray.splice(index, 1);
                        setAllCollabs(newArray);
                        var all_percentages = allPercentages;
                        allPercentages["percentage" + con.id] = allPercentages[
                          "percentage" + con.id
                        ]
                          ? allPercentages["percentage" + con.id]
                          : 0;
                        setPercentage(
                          percentage + allPercentages["percentage" + con.id]
                        );
                        all_percentages["percentage" + con.id] = 0;
                        setAllPercentages(all_percentages);
                      }
                    }}
                  />
                );
              })}
              <ArtisButton
                name="Add Collaborator"
                sx={{
                  fontSize: 20,
                  pl: 0,
                  ml: 0,
                  textAlign: "left",
                  justifyContent: "flex-start",
                }}
                onClick={() => {
                  setIsCollabModalOpen(!isCollabModalOpen);
                }}
                type="button"
              />
            </Grid>
            <Modal open={isModalOpen} onClose={(e) => setIsModalOpen(false)}>
              <Box
                sx={{
                  width: 400,
                  height: 164,
                  backgroundColor: "white",
                  margin: "auto",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  padding: "30px 20px",
                }}
              >
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, color: "black" }}
                >
                  This is not Mel Brook’s The Producers, you cannot distribute
                  more than 100% of ownership
                </Typography>
              </Box>
            </Modal>
            <Modal
              open={isModalCheckContri}
              onClose={(e) => setIsModalCheckContri(false)}
            >
              <Box
                sx={{
                  width: 400,
                  height: 164,
                  backgroundColor: "white",
                  margin: "auto",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  padding: "30px 20px",
                }}
              >
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, color: "black" }}
                >
                  Please fill in what your collaborators contributed to this
                  work
                </Typography>
              </Box>
            </Modal>
            <AddCollabDialog
              open={isCollabModalOpen}
              handleClose={() => setIsCollabModalOpen(false)}
              fameConnections={fameConnections}
              allCollabs={allCollabs}
              setAllCollabs={setAllCollabs}
            />
            <ErrorDialog
              open={isErrorModalOpen}
              handleClose={() => setIsErrorModalOpen(false)}
              message={`
                Your mobile browser refused to give Artis.app your GPS coordinates. Please go into the browser setting and enable this.
              `}
            />
          </Grid>
          <br />
          <br />
          <img
            src="https://artis.app/images/Artis_four.jpg"
            alt="ONE"
            width="50"
            height="50"
          />
          <Grid container direction="row">
            <Grid item>
              {(creatorHasCopyright ||
                allCollabs.some((collab) => collab.oncopyright === true)) ===
              true ? (
                <Controller
                  name="copyright_date"
                  render={({ field }) => (
                    <DateInput
                      label="Copyright date"
                      value={getValues().copyright_date}
                      handleChange={field.onChange}
                    />
                  )}
                  control={control}
                />
              ) : (
                <Typography
                  sx={{
                    fontFamily: "Bellefair, serif",
                    fontSize: "1.5em",
                    [theme.breakpoints.down(490)]: {
                      width: window.innerWidth - 70,
                    },
                  }}
                >
                  No Copyright Date
                </Typography>
              )}
            </Grid>
            <Grid item xs={3} sm>
               
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid
            container
            direction={window.innerWidth > 490 ? "row" : "column"}
          >
            <Grid item>
              <Typography
                sx={{
                  fontFamily: "Bellefair, serif",
                  fontSize: "1.5em",
                  [theme.breakpoints.down(490)]: {
                    width: window.innerWidth - 70,
                  },
                }}
              >
                Optional - add Search Tags to catagorize this work.
              </Typography>
            </Grid>
            <Grid item xs={3} sm>
              <InfoDialog
                title="Search Tags"
                subtitle="Search Tags are an easy way to search for your work later. For example, you can add the Search Tag 'photo' to all your photos and 'pdf' to all the pdfs your register. So if you have hundreds of works registered on Artis.app, you can later search by the tags you added.  Multiple Search Tags can be added to a single work. To create a Search Tag, type in a word and press enter."
                examples={["PDF", "MP3", "Poem", "Recipe"]}
              />
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <TagsInput
                sx={{
                  mb: 3,
                  fontSize: "1.5rem",
                  [theme.breakpoints.down(490)]: {
                    width: window.innerWidth - 70,
                  },
                }}
                id="tags"
                name="Tags"
                placeholder="Type in a word and press enter"
                label="tags"
                chips={field.value}
                options={user?.profile?.tags}
                setChips={field.onChange}
                onChangeSearch={(value) => setGLSearchTabs(value)}
              />
            )}
          />
          <Grid
            container
            direction={window.innerWidth > 490 ? "row" : "column"}
          >
            <Grid item>
              <Typography
                sx={{
                  fontFamily: "Bellefair, serif",
                  fontSize: "1.5em",
                  [theme.breakpoints.down(490)]: {
                    width: window.innerWidth - 70,
                  },
                }}
              >
                Optional - Include support documentation.
              </Typography>
            </Grid>
            <Grid item xs={3} sm>
              <InfoDialog
                title="Support Document"
                subtitle="Attach and record supporting documentation for your work. For example, if you are registering a photo of a model, upload the model-signed release form. Good practice is to zip up all support documents into one file and then upload that one zip file here."
                examples={[
                  "VogueSepember2023Cover_modelRelease.zip",
                  "LocationPermissionForm.zip",
                  "ClearChainOfTitle.zip",
                ]}
              />
            </Grid>
            <Grid item></Grid>
          </Grid>
          <TextField
            variant="standard"
            style={{ textTransform: "none" }}
            margin="normal"
            fullWidth
            value={getValues(["support_document_name"])}
            id="support_file"
            label="Click here to select support file to include in registration"
            name="support_document_name"
            sx={styles["artis-input"]}
            InputLabelProps={{ required: false, sx: { color: "#9E9E9E" } }}
            onClick={(event) =>
              document.getElementById("upload_support_file").click()
            }
          />
          {getValues("support_document_name") && (
            <Box
              textAlign="center"
              sx={{
                mb: "2%",
              }}
            >
              <Typography color="black">
                {getValues("support_document_name")} uploaded and fingerprinted.
              </Typography>
            </Box>
          )}
          {fileLoading === "support_file" && (
            <Box
              textAlign="center"
              sx={{
                mb: "2%",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <input
            id="upload_support_file"
            onChange={(event) => handleFileUpload(event, "support_file")}
            type="file"
            style={{ display: "none" }}
          />
        </Box>
      </Box>
      <br />
      <Typography
        sx={{
          fontFamily: "Bellefair, serif",
          fontSize: "1.5em",
        }}
      >
        Email detailed copy of registration to:
      </Typography>
      <EmailInputs
        email={user?.profile?.email}
        emails={
          user?.profile?.work_emails &&
          user?.profile.work_emails.filter(
            (obj) => obj?.email && obj.email.includes("@")
          )
        }
        onEmailsUpdate={(emails) => setValue("emails", emails)}
      />
      <Box sx={{ display: "flex", flexFlow: "row wrap", alignItems: "center" }}>
        <FormControlLabel
          control={
            <Switch
              checked={sendCommunityEmail}
              onChange={() => setSendCommunityEmail(!sendCommunityEmail)}
              inputProps={{ "aria-label": "Switch" }}
              sx={utils.switchStyle}
            />
          }
          label="Send Proof of Registration to your community."
        />
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={sendGPSLocation}
              onChange={handleGPSSwitch}
              disabled={!gpsSwitchEnabled}
              inputProps={{ "aria-label": "Switch" }}
              sx={utils.switchStyle}
            />
          }
          label="Include GPS location of where this work is being registered."
        />
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={showOnCommunity}
              onChange={() => setShowOnCommunity(!showOnCommunity)}
              inputProps={{ "aria-label": "Switch" }}
              sx={utils.switchStyle}
            />
          }
          label="Show work on your community's timeline."
        />
      </Box>
      {isLoading && (
        <Box textAlign="center" mb={4}>
          <CircularProgress size="4rem" />
        </Box>
      )}
      <div style={{ textAlign: "center" }}>
        <br />
        <br />
        <br />
        <img
          src="https://artis.app/images/Artis_five.jpg"
          alt="FOUR"
          width="50"
          height="50"
        />
        <br />
        {isLoading ? (
          <Box sx={{ m: 2, pl: 5, pr: 5, pb: 0, pt: 0, textAlign: "center" }}>
            Registering new work in blockchain, please wait...
          </Box>
        ) : (
          <Button
            style={{
              backgroundColor: `${
                !isDirty || !isValid ? "#eeeeee" : "#26a69a"
              }`,
              fontSize: "1.4em",
              color: `${!isDirty || !isValid ? "black" : "#fff"}`,
              textTransform: "uppercase",
              fontFamily: "Bellefair, serif",
            }}
            sx={{
              m: 2,
              pl: 5,
              pr: 5,
              pb: 0,
              pt: 0,
            }}
            type="button"
            onClick={() => handleSubmit(_handleSubmit)()}
          >
            register on blockchain
          </Button>
        )}
        <Typography color="black" component="p" textAlign="center" mb={4}>
          By clicking the button above, you attest the work you're registering
          <br /> is your original creation and its copyright belongs to you.
        </Typography>
        {selectedCollaborators && (
          <Typography color="green" component="p" textAlign="center" mb={4}>
            When button is pressed, the work will be sent to collaborators for
            them to accept or decline their participation. Only after all have
            accepted will the work be registered on the blockchain.
          </Typography>
        )}
      </div>
      <br />
      <br />
      <ArtisFooter />
    </Container>
  );
}
