import React from "react";
import WorkService from "../../../../api/service";
import ArtisInput from "../../../inputs/textfield";
import ArtisButton from "../../../buttons/button";
import CollapsibleBody from "../../../collapsible";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import Loader from "../../../buttons/loader";

export default function AiDescription(props) {
  const extension = props?.image?.image?.split(".").pop()?.toLowerCase();

  const [aiDesc, setAiDesc] = React.useState(props?.aiDescription);
  const [show, setShow] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const handleSave = async () => {
    try {
      const response = await WorkService.SaveAI_Description({
        aiDesc,
        work_id: props.workId,
      });
      if (response.result) {
        toast.success("Ai description updated.");
        setShow(false);
      }
    } catch (error) {
      toast.error("Error saving ai description.");
      console.error(error);
    }
  };

  const handleCreateDescription = async () => {
    setLoading(true);

    try {
      const response = await WorkService.CreateAI_Description({
        imageId: props?.image?.id,
        workId: props?.workId,
      });
      if (response.result) {
        toast.success("Ai description created.");
        console.log("response", response);

        setAiDesc(response?.result?.data?.ai_description);
        setShow(false);
      }
    } catch (error) {
      toast.error("Error creating ai description.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CollapsibleBody
      expanded={props.openId === "ai-description"}
      id="ai-description"
      title={"AI Description"}
      icon={<SmartToyOutlinedIcon />}
      handleClick={props.setOpenId}
      new_comment={props.new_comment}
      mainSx={{}}
      buttonProps={{
        textColor: "black",
      }}
    >
      {props?.image?.image &&
      (extension === "jpeg" || extension === "png" || extension === "jpg") ? (
        <>
          <Box component="div" m={3}>
            <Box>
              Artis's AI team uses this description to understand
              the work and your creativity. If it is inaccurate or missing (such as with musical works, as Artis cannot understand them yet) update the description. This
              information is not shared with the public.
            </Box>
          </Box>

          <Grid
            sx={{
              m: 2,
            }}
            style={{ fontSize: "1.2rem" }}
          >
            <ArtisInput
              label="AI Description"
              id="comment"
              multiline
              InputLabelProps={{
                required: false,
                sx: { color: "teal" },
                shrink: true,
              }}
              fontSize="1rem"
              onChange={(e) => setAiDesc(e.target.value)}
              value={aiDesc}
              fontFamily="Montserrat', sans-serif"
            />
            <ArtisButton
              id="update-aiDescription"
              name="Update"
              onClick={handleSave}
              sx={{
                fontSize: "1.5rem",
                mt: 2,
              }}
              disabled={!aiDesc?.trim(" ")}
            />
            {!props.aiDescription && show && (
              <>
                {loading ? (
                  <Loader isLoading={loading} color="success" />
                ) : (
                  <ArtisButton
                    id="have-aiDescribe"
                    name="Have AI Describe"
                    sx={{
                      fontSize: "1.5rem",
                      mb: 2,
                    }}
                    onClick={handleCreateDescription}
                  />
                )}
              </>
            )}
          </Grid>
        </>
      ) : (
        <Box component="div" m={3}>
          <Box>This file type does not support AI description</Box>
        </Box>
      )}
    </CollapsibleBody>
  );
}
