import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Login from "../components/forms/Login";
import Signup from "../components/forms/Signup";
import SubmitEmail from "../components/forms/SubmitEmail";
import { useUser } from "../hooks/user";
import ArtisButton from "../components/buttons/button";
import { AuthService } from "../api";
import WorkService from "../api/service";
import useCheckMobileScreen from "../hooks/isMobile";
import UserService from "../api/auth";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .required("Email or username is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const signUpValidationSchema = yup.object({
  name: yup.string("Enter your name").required("Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export default function SignIn() {
  const { user, login } = useUser();
  const isMobile = useCheckMobileScreen(600);
  const history = useHistory();
  const location = useLocation();

  const [artisCode, setArtisCode] = useState("");
  const [, setSuccess] = useState(false);
  const [, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [artisCodeError, setArtisCodeError] = useState("");
  const [, setIsMissmatch] = useState(false);
  const [submitEmail, setSubmitEmail] = useState("");
  const [openPage, setOpenPage] = useState("");
  const [hasSenderId, setHasSenderId] = useState(false);
  const [hasTransferCode, setHasTransferCode] = useState(false);
  const [senderId, setSenderId] = useState("");
  const [transferCode, setTransferCode] = useState("");
  const [enableSignUp, setEnableSignUp] = useState(false);
  const [invitedByName, setInvitedByName] = useState("an Artis User");
  const [invitedByTransferCode, setInvitedByTransferCode] = useState(null);

  // Define style objects for video embed with fixed dimensions
  const videoContainerStyles = {
    width: "300px",
    height: "418px",
    margin: "0 auto",
  };

  const iframeStyles = {
    width: "100%",
    height: "100%",
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setSubmitEmail(submitEmail);
    setOpenPage("email");

    const response = await AuthService.submitEmail({ email: submitEmail });

    if (response.result) {
      setSubmitEmail("");
      toast.success("Email submitted successfully!");
    } else {
      setSubmitEmail("");
      toast.error("Invalid email!");
    }
  };

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      setSuccess(true);

      const loginAttempt = await login(
        values.email.toLowerCase(),
        values.password
      );
      setIsLoading(false);
      setSuccess(false);

      if (loginAttempt !== "Success") {
        formik.setFieldValue("password", "");
        setError("Invalid password or email!");
      }
    },
  });

  const signupFormik = useFormik({
    initialValues: { name: "", email: "" },
    validationSchema: signUpValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      setSuccess(true);

      const identifier =
        invitedByTransferCode?.length > 0
          ? invitedByTransferCode[0]?.holder_id
          : senderId;

      const transferCodeValue =
        invitedByTransferCode?.length > 0 &&
        invitedByTransferCode?.[0]?.transfer_code;
      if (transferCodeValue) {
        localStorage.setItem("transfer_code", transferCodeValue);
      }
      const loginAttempt = await UserService.fameCommunitySignUp({
        invitee_name: values.name,
        invitee_email: values.email.toLowerCase(),
        sender_id: identifier,
      });

      if (loginAttempt?.result) {
        window.location.href = loginAttempt?.result?.signup_url;
      } else {
        if (loginAttempt.error.response) {
          toast.error(loginAttempt.error.response.data.error + "!");
        } else if (loginAttempt.error) {
          toast.error(loginAttempt.error + "!");
        } else {
          toast.error("Encountered an unknown error.");
        }
      }
      setIsLoading(false);
      setSuccess(false);
    },
  });

  useEffect(() => {
    document.title = "Login | Artis.app";
  }, []);

  useEffect(() => {
    if (user) {
      const { from } = location.state || { from: { pathname: "/works" } };
      history.push(from);
    }
  }, [user, history, location.state]);

  useEffect(() => {
    setIsMissmatch("");
  }, [formik.values.password]);

  useEffect(() => {
    setError("");
  }, [formik.values.email]);

  const handleLookup = async () => {
    const trimmedCode = artisCode.trim();

    if (trimmedCode === "") {
      setArtisCodeError("Code cannot be empty!");
    } else if (trimmedCode.includes("?")) {
      const parts = trimmedCode.split("?");
      const codePart = parts[1];

      if (codePart && codePart.trim() !== "") {
        const response = await WorkService.getCertificatesDetails(
          codePart,
          parts[0]
        );

        if (response.result) {
          history.push(`/work/${trimmedCode}`);
        } else {
          setArtisCodeError(
            `No work or certificate is registered under this code: ${trimmedCode}`
          );
        }
      } else {
        setArtisCodeError(
          "Invalid format. Please make sure to provide a valid code after '?'."
        );
      }
    } else {
      const lookupAttempt = await WorkService.lookup(trimmedCode);

      if (lookupAttempt.result) {
        history.push(`/works/${trimmedCode}`);
      } else {
        setArtisCodeError(`Nothing is registered under this code: ${trimmedCode}`);
      }
    }
  };

  useEffect(() => {
    if (hasSenderId) {
      setOpenPage("login");
    }
  }, [hasSenderId]);

  useEffect(() => {
    if (hasTransferCode) {
      setOpenPage("login");
    }
  }, [hasTransferCode]);

  useEffect(() => {
    const getSenderId = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const senderIdParam = urlParams.get("sender_id");
      if (senderIdParam) {
        const res = await WorkService.getUserProfile({
          sender_id: senderIdParam,
        });
        if (res?.result) {
          setInvitedByName(
            res?.result?.profile?.first_name +
              " " +
              res?.result?.profile?.last_name
          );
        } else {
          window.location.href = "/login";
        }
        setHasSenderId(true);
        setSenderId(senderIdParam);
        setEnableSignUp(true);
      }
    };
    getSenderId();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const transferCodeParam = urlParams.get("transfer_code");
    const getTransferCode = async () => {
      if (transferCodeParam) {
        const resp = await WorkService.getCertificatesTransfer(transferCodeParam);
        if (resp?.result) {
          setInvitedByTransferCode(resp?.result);
        } else {
          window.location.href = "/login";
        }
        setHasTransferCode(true);
        setTransferCode(transferCodeParam);
        setEnableSignUp(true);
      }
    };
    getTransferCode();
  }, []);

  return (
    <Container maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {((!hasSenderId && !enableSignUp) || (!hasTransferCode && !enableSignUp)) && (
          <Box>
            <Grid
              sx={{
                fontFamily: "Bellefair, serif",
                textAlign: "center",
                fontSize: "1.15rem", // Added to increase default font size by 15% (assuming parent default is 1rem)
              }}
              container
            >
              <Grid item>
                <ArtisButton
                  name="Login/Sign up"
                  onClick={() => setOpenPage(openPage === "login" ? "" : "login")}
                  sx={{ fontSize: "1.495rem" }} // Was 1.3em ≈ 1.3rem, now 1.3 * 1.15 = 1.495rem
                  textColor={openPage === "login" ? "gray" : "teal"}
                />
              </Grid>
              <Grid item>
                <ArtisButton
                  name="Find Work"
                  onClick={() =>
                    setOpenPage(openPage === "artis-code" ? "" : "artis-code")
                  }
                  sx={{ fontSize: "1.495rem" }} // Was 1.3em ≈ 1.3rem, now 1.3 * 1.15 = 1.495rem
                  textColor={openPage === "artis-code" ? "gray" : "teal"}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box maxWidth={isMobile ? "95%" : "50%"}>
          <Collapse in={openPage === "login"} timeout="auto" unmountOnExit>
            <Typography color="red" sx={{ fontSize: "1.15rem" }}>
              {error}
            </Typography> {/* Added fontSize: 1.15rem (default 1rem * 1.15) */}
            {(hasSenderId && enableSignUp) || (hasTransferCode && enableSignUp) ? (
              <Signup
                formik={signupFormik}
                setEnableSignUp={setEnableSignUp}
                invitedByName={invitedByName}
                setHasSenderId={setHasSenderId}
                setHasTransferCode={setHasTransferCode}
                transfer_code={transferCode}
                getCertificateData={invitedByTransferCode}
              />
            ) : (
              <Login formik={formik} />
            )}
            {!hasSenderId && !enableSignUp && (
              <SubmitEmail
                isMobile={isMobile}
                handleEmailSubmit={handleEmailSubmit}
                submitEmail={submitEmail}
                setSubmitEmail={setSubmitEmail}
                setError={setError}
              />
            )}
          </Collapse>
        </Box>
        <Box maxWidth={isMobile ? "95%" : "55%"}>
          <Collapse in={openPage === "artis-code"} timeout="auto" unmountOnExit>
            <Box>
              <br />
              <Typography sx={{ fontSize: "1.15rem", textAlign: "center" }}>
                Find a registered work by its Artis Code
              </Typography> {/* Replaced <p> with Typography, fontSize: 1.15rem */}
              <br />
              <Typography color="red" sx={{ fontSize: "1.15rem" }}>
                {artisCodeError}
              </Typography> {/* Added fontSize: 1.15rem */}
              <TextField
                style={{ textTransform: "none" }}
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="artis_code"
                label="Artis Code"
                type="text"
                id="name"
                value={artisCode}
                onChange={(e) => {
                  setArtisCode(e.target.value);
                  setArtisCodeError("");
                }}
                autoComplete="off"
                sx={{ mb: 3 }}
                InputProps={{ style: { fontSize: "1.495rem" } }} // Was 1.3rem, now 1.3 * 1.15 = 1.495rem
                InputLabelProps={{
                  required: false,
                  sx: { color: "#9E9E9E", fontSize: "1.15rem" }, // Added fontSize for label (default ~1rem * 1.15)
                }}
              />
              <Button
                type="button"
                className="normal"
                fullWidth
                style={{
                  backgroundColor: "transparent",
                  fontSize: "1.725rem", // Was 1.5em ≈ 1.5rem, now 1.5 * 1.15 = 1.725rem
                  color: "teal",
                  textTransform: "none",
                  fontFamily: "Bellefair, serif",
                }}
                variant="text"
                sx={{ mt: -1, mb: 2 }}
                onClick={handleLookup}
              >
                Search
              </Button>
            </Box>
          </Collapse>
        </Box>
        <Box maxWidth={isMobile ? "95%" : "55%"} textAlign="center">
          <Container style={{ textAlign: "center" }}>
            <br />
            <div style={videoContainerStyles}>
              <iframe
                src="https://player.vimeo.com/video/1061526923?autoplay=1&muted=1&badge=0&autopause=0&player_id=0&controls=0&app_id=58479"
                style={iframeStyles}
                title="ArtisVideo"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </div>
            <br />
          </Container>
        </Box>
      </Box>
      <Box maxWidth="md">
        <CssBaseline />
        <Box
          component="footer"
          sx={{
            mt: 4,
            py: 2,
            textAlign: "center",
            fontFamily: "Bellefair, serif",
            fontSize: "1.265rem", // Was 1.1rem, now 1.1 * 1.15 = 1.265rem
          }}
        >
          <div>
            YOUR CREATIVE JOURNEY POWERED BY <br />
            YOUR PERSONAL AI CREATIVE TEAM<br /><br />
            Creativity is more than ideas—it’s a process.<br /> Why create alone when Artis.app's AI powered team can help you:<br /><br />
            ORGANIZE & DEVELOP IDEAS<br />Brainstorm, structure, and refine projects<br /><br />
            PROTECT YOUR WORK<br />Secure precious intellectual property with blockchain-powered proof of creation and ownership<br /><br />
            MARKET & MONETIZE<br />Use custom AI to find best ways to promote and sell your creativity<br /><br />
            TRUST<br />Artis.app uses the same blockchains to protect your work that <a href="https://blackrock.com" rel="noreferrer" target="_blamk">BlackRock</a>, the world’s largets asset manager (<a href="https://www.barrons.com/livecoverage/chase-wells-fargo-bank-america-citi-earnings/card/blackrock-s-assets-rise-15-to-record-11-55-trillion-vXCLcXzBLI9RuoHK5jO1" rel="noreferrer" target="_blank">$11 trillion in assests</a>), is transitioning its stocks, bonds, funds, and real estate into.<br />
            <br />YOUR WORK STAYS YOUR WORK<br />
            Artis.app takes no rights from you. Nor charges commisions. Even if Artis.app goes defunct, your registrations will exist.       
          </div>
          <br />
          <img 
            src="https://www.artis.app/images/iphone_artis.jpg" 
            alt="iPhone Artis" 
            style={{ 
              maxWidth: "30%", 
              height: "auto", 
              margin: "14px 0" 
            }} 
          />
          <br /><br />
          Based in Hollywood, California, USA<br /> <br /><br />
          Founded by successful Hollywood filmmakers who want to help other creatives succeed <br />
          <a
            href="https://en.wikipedia.org/wiki/Martin_Kunert"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "teal" }}
          >
            Martin Kunert
          </a>{" "}
          and{" "}
          <a
            href="https://en.wikipedia.org/wiki/Eric_Manes"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "teal" }}
          >
            Eric Manes
          </a>
          <br /><br />
          copyright mmxxv artis.app inc.
          <br />built by{" "}
          <a
            href="https://techemulsion.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            TechEmulsion
          </a>
        </Box>
      </Box>
    </Container>
  );
}

// Load Vimeo script outside the component
const script = document.createElement("script");
script.src = "https://player.vimeo.com/api/player.js";
script.async = true;
document.body.appendChild(script);
