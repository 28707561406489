import { useState } from "react"; // Hook for managing state
import { Controller, useForm } from "react-hook-form"; // Library for form management
import Box from "@mui/material/Box"; // Material-UI component for layout
import Grid from "@mui/material/Grid"; // Material-UI component for grid layout
import PersonIcon from "@mui/icons-material/Person"; // Icon for person
import CollapsibleBody from "../../../collapsible"; // Custom collapsible component
import ArtisButton from "../../../buttons/button"; // Custom button component
import Loader from "../../../buttons/loader"; // Custom loader component
import ArtisInput from "../../../inputs/textfield"; // Custom text field component
import Exhibitions from "./exhibitions"; // Component for exhibitions
import heic2any from "heic2any"; // Library for converting HEIC to other formats

/**
 * Extracts the file name from a given file path or URL.
 * 
 * @param {string} file - The file path or URL
 * @returns {string} The extracted file name or an empty string if extraction fails
 */
const extractFileName = (file) => {
  try {
    return "" + file.split("/").pop();
  } catch (e) {
    return "";
  }
};

export default function Bio(props) {
  // Initialize form with default values from props
  const { handleSubmit, setValue, control } = useForm({
    defaultValues: {
      artist_bio: props.bio,
      artist_website: props.website,
      photo: null,
    },
  });

  // State for loading indicator
  const [isLoading, setIsLoading] = useState(false);
  // State for error messages
  const [error, setError] = useState("");
  // State for profile photo URL
  const [profilePhotoUrl, setProfilePhotoUrl] = useState(props.photo ? props.photo : "");
  // State for profile image file
  const [profileImageFile, setProfileImageFile] = useState("");

  /**
   * Handles form submission.
   * Validates the website URL if provided and sends the data to the server.
   * 
   * @param {object} data - The form data
   */
  const _handleSubmit = async (data) => {
    setError("");
    setIsLoading(true);
    const { ...body } = data;
    const formData = new FormData();

    // Regular expression for URL validation
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); //eslint-disable-line

    // Validate website only if it is not empty
    if (body.artist_website && !pattern.test(body.artist_website)) {
      setError("Please enter a valid URL.");
      setIsLoading(false);
      return;
    }

    // Append photo to form data if provided
    if (body?.photo) {
      formData.append("photo", profileImageFile);
    }

    // Append bio and website to form data
    formData.append("artist_bio", body.artist_bio);
    formData.append("artist_website", body.artist_website);

    // Call the API to update bio
    let response = await props.handleBioUpdate(formData);
    if (response.result) {
      // Reset photo field and update profile photo URL
      setValue("photo", null);
      setProfilePhotoUrl(response.result.photo);
    }
    setIsLoading(false);
  };

  /**
   * Handles file upload for profile photo.
   * Supports HEIC format conversion to JPEG.
   * 
   * @param {object} event - The file input change event
   */
  const handleFileUpload = async (event) => {
    setIsLoading(true);

    if (event?.target && event.target?.files.length) {
      const file = event.target.files[0];
      if (file.name.toLowerCase().endsWith("heic")) {
        // Set the original HEIC file to the form
        setValue("photo", file);
        // Convert HEIC to JPEG
        const convertedImage = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });
        // Set the converted image file
        setProfileImageFile(convertedImage);
        // Create a preview URL for the converted image
        const previewUrl = URL.createObjectURL(convertedImage);
        setProfilePhotoUrl(previewUrl);
        setError("");
      } else if (file.type.includes("image")) {
        // Set the image file to the form
        setValue("photo", file);
        // Create a preview URL for the image
        const previewUrl = URL.createObjectURL(file);
        setProfilePhotoUrl(previewUrl);
        // Set the image file
        setProfileImageFile(file);
        setError("");
      } else {
        setError("Please select an image");
      }
    }

    setIsLoading(false);
    event.target.value = null;
  };

  return (
    // Collapsible section for bio information
    <CollapsibleBody
      expanded={props.openId === `bio${props.collaborator_id}`}
      id={`bio${props.collaborator_id}`}
      title={
        props?.isSettings ? (
          `Bio, Website and Photo`
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column", // Change to column direction
              alignItems: "flex-start", // Align items to the start
              ml: -2, // Counteract extra indentation from CollapsibleBody
              mb: -1, // Reduce vertical distance to artist type by half
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* Display circular profile photo to the left of the name */}
              {profilePhotoUrl ? (
                <img
                  src={profilePhotoUrl}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%", // Makes photo circular
                    marginRight: "8px", // Space between photo and name
                    objectFit: "cover",
                  }}
                  alt="Profile"
                />
              ) : (
                <PersonIcon sx={{ marginRight: "8px" }} />
              )}
              <span>{`${props.first_name} ${props.last_name}`}</span>
            </Box>
          </Box>
        )
      }
      icon={null} // No icon rendered
      is_kyced={props?.is_kyced}
      handleClick={props.setOpenId}
      mainSx={{
        alignItems: props?.isSettings ? "stretch" : "flex-start",
      }}
      sign_status={props?.sign_status}
      detail={props?.detail}
      contribution={props?.contribution}
      onEditPage={props?.onEditPage}
    >
      <Box
        component="form"
        ml={1.5}
        mr={1.5}
        noValidate
        onSubmit={handleSubmit((data) => _handleSubmit(data))}
      >
        {/* Display error message if any */}
        {error !== "" && (
          <Box textAlign="center" color="red">
            {error}
          </Box>
        )}
        {/* Display ownership and copyright information if not in settings */}
        {!props.hasOwnProperty("hiddenTitle") && !props?.isSettings && (
          <Grid container direction={"column"} spacing={1}>
            <Grid item>
              <Box color="gray">
                <br />ownership: {props.ownership}%
                <br /> on Copyright: {props.oncopyright ? "Yes" : "No"}
              </Box>
            </Grid>
          </Grid>
        )}
        <br />
        {/* Display profile photo if available, now circular */}
        {profilePhotoUrl && (
          <Box
            textAlign="center"
            sx={{
              mb: "2%",
              mt: props.onEditPage ? "5%" : "2%",
            }}
          >
            <img
              src={profilePhotoUrl}
              style={{
                maxHeight: "300px",
                maxWidth: "300px",
              
                objectFit: "cover",
              }}
              alt="Profile"
            />
          </Box>
        )}
        {/* Photo upload input if in edit or settings mode */}
        {(props?.isEdit === true || props?.isSettings) && (
          <>
            <Controller
              control={control}
              name="photo"
              render={({ ref, formState, fieldState, ...rest }) => (
                <ArtisInput
                  label="Photo"
                  id="photo"
                  value={
                    rest.field.value?.name || extractFileName(profilePhotoUrl)
                  }
                  InputLabelProps={{
                    required: false,
                    sx: { color: "teal" },
                    shrink: true,
                  }}
                  onClick={(event) =>
                    document.getElementById("upload_photo_bio").click()
                  }
                  {...rest}
                />
              )}
            />
            <input
              id="upload_photo_bio"
              type="file"
              onChange={(e) => handleFileUpload(e)}
              hidden
            />
          </>
        )}
        {/* Website input if in edit mode or not specified */}
        {!props.hasOwnProperty("isEdit") || props?.isEdit === true ? (
          <Controller
            control={control}
            name="artist_website"
            render={({ ref, formState, fieldState, ...rest }) => (
              <ArtisInput
                label="Website (https://example.com)"
                id="website"
                onChange={rest.field.onChange}
                fontFamily="Montserrat', sans-serif"
                value={rest.field.value}
                InputLabelProps={{
                  required: false,
                  sx: { color: "teal" },
                  shrink: true,
                }}
                placeholder="https://www.example.com"
                {...rest}
              />
            )}
          />
        ) : (
          <Box component="a" sx={{ display: "block", textAlign: "center" }}>
            {props?.website
              ? props.website.split("//")[1] || props.website.split("//")[0]
              : ""}
          </Box>
        )}
        {/* Bio input if in edit mode or not specified */}
        {(!props.hasOwnProperty("isEdit") || props?.isEdit === true) && (
          <Controller
            control={control}
            name="artist_bio"
            render={({ ref, formState, fieldState, ...rest }) => (
              <ArtisInput
                label="Bio"
                id="bio"
                multiline
                InputLabelProps={{
                  required: false,
                  sx: { color: "teal" },
                  shrink: true,
                }}
                onChange={rest.field.onChange}
                value={rest.field.value}
                fontFamily="Montserrat', sans-serif"
                {...rest}
              />
            )}
          />
        )}

        {/* Loader component to show loading state */}
        <Loader isLoading={isLoading} />
        {/* Update button if in edit mode or not specified */}
        {!props.hasOwnProperty("isEdit") ||
          (props?.isEdit === true && (
            <ArtisButton
              id="update_bio"
              name="Update Bio, Website and Photo"
              sx={{
                fontSize: "1.5rem",
                mb: 2,
                mt: 2,
              }}
              disabled={isLoading}
            />
          ))}
        {/* Update button if in settings mode */}
        {props?.isSettings && (
          <ArtisButton
            id="update_bio"
            name="Update"
            sx={{
              fontSize: "1.5rem",
              mb: 2,
              mt: 2,
            }}
            disabled={isLoading}
            />
        )}
        <br /><br />
        {/* Exhibitions component if in edit mode */}
        {props.isEdit && (
          <Exhibitions
            userId={JSON.parse(localStorage.getItem("artis_user")).profile.user.id}
            isEdit={props.isEdit}
          />
        )}
        <br /><br />
        {/* Optional all community information */}
        {props.hasOwnProperty("allCommunity") && props.allCommunity}
        <br />
      </Box>
    </CollapsibleBody>
  );
}
