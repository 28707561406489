/**
 * FameUserItem.jsx
 * 
 * Summary:
 * This file contains two main components: FameUserItem and FameUserItemWrapper.
 * FameUserItem is a presentational component that displays individual user information
 * with expandable details, endorsement toggles, and community management features.
 * FameUserItemWrapper is a container component that handles data fetching and 
 * manages sub-connections, known works, and exhibitions for a user.
 * 
 * Key Features:
 * - User profile display with photo and verification status
 * - Expandable section with endorsement toggle and community ordering
 * - Invitation system for community building
 * - Display of known works and professional connections
 * - Integration with UserService API for data operations
 */

import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import PendingIcon from '@mui/icons-material/Pending';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';

import UserService from "../../api/auth";
import ArtisButton from "../buttons/button";
import Loader from "../buttons/loader";
import FameModal from './FameModal';
import utils from "../../utils";
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import Exhibition from "../work/edit/collapsibles/exhibition";

// Constants for ordering options
const possibleOrder = ["0", "1", "2", "3"];

// Switch label configuration
const label = {
  inputProps: {
    "aria-label": "Move this account to the top of your public pages.",
  },
};

// Success message templates
const successMessage = (name) => (
  <div>
    <img alt="Artis.app" src="https://artis.app/images/peace.jpg" />
    <p><strong>CONGRATS!!!</strong></p>
    <br />
    <p>Invitation sent to </p>
    <p>'{name}'</p>
    <p>for approval!</p>
  </div>
);

const successAcceptMessage = (name) => (
  <div>
    <img alt="Artis.app" src="https://artis.app/images/peace.jpg" />
    <p><strong>CONGRATS!!!</strong></p>
    <br />
    <p>'{name}'</p>
    <p>is now added as </p>
    <p>your community member</p>
  </div>
);

// API interaction functions
const sendInvitees = async (actualUser, fame, getAllFameConnections) => {
  try {
    await UserService.sendInvitee({
      id: fame.id,
      common_community: `${actualUser.first_name} ${actualUser.last_name}`,
      invitee_name: `${fame.first_name} ${fame.last_name}`,
      invitee_email: fame.email,
      invitee_message: fame.artist_bio,
    });
    if (getAllFameConnections) await getAllFameConnections();
    toast.success(successMessage(`${fame.first_name} ${fame.last_name}`));
  } catch (error) {
    console.error(error);
  }
};

const acceptInvite = async (fame, getAllFameConnections) => {
  try {
    await UserService.updateOrder([{ id: fame.id, status: 3 }]);
    toast.success(successAcceptMessage(`${fame.user.first_name} ${fame.user.last_name}`));
    if (getAllFameConnections) await getAllFameConnections();
  } catch (error) {
    console.error(error);
  }
};

const deleteInvite = async (fame, getAllFameConnections) => {
  try {
    await UserService.deleteFameConnection(fame.id);
    if (getAllFameConnections) await getAllFameConnections();
  } catch (error) {}
};

const updateOrder = async ({
  fame,
  order,
  allConnections,
  getAllFameConnections,
  setIsLoading,
  setIsExpanded,
}) => {
  const payload = [];
  try {
    if (order === 0) {
      payload.push({ id: fame.id, order });
      await UserService.updateOrder(payload);
    } else {
      let currentOrder = 1;
      payload.push({ id: fame.id, order: currentOrder });
      const filtered = allConnections
        .filter((con) => con.id !== fame.id)
        .filter((t) => t.order !== 0)
        .sort((a, b) => a.order - b.order);
      filtered.forEach((f) => {
        if (f.order !== 0) {
          currentOrder += 1;
          payload.push({ id: f.id, order: currentOrder });
        }
      });
      await UserService.updateOrder(payload);
    }
  } catch (error) {
    console.error(error);
  } finally {
    if (getAllFameConnections) await getAllFameConnections();
    setTimeout(() => setIsLoading(false), 2000);
  }
};

// Main presentational component
const FameUserItem = ({
  children,
  showOrder,
  allConnections,
  user,
  name,
  is_kyced,
  order,
  maxOrder,
  setMaxOrder,
  onDelete,
  selectedOrderItems,
  setSelectedOrderItems,
  onClick,
  shouldExpand,
  isPending,
  fame,
  getAllFameConnections,
  userProfile,
  endorsedBy,
  fromFame,
  showEndorsement,
  totalConnections,
  status,
  sender,
  actualUser,
  fromEditOrDetail,
  secondLayer
}) => {
  // Component state
  const [isEndorsementChecked, setIsEndorsementChecked] = useState(
    utils.checkNumberInString(user.endorsed_by, userProfile?.user?.id)
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(order !== 0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerUser] = useState(user);
  const [isVisibleReject, setIsVisibleReject] = useState(false);

  // Handle endorsement toggle
  const setEndorsement = async () => {
    try {
      const response = await UserService.endorse({
        endorsed_to: customerUser.user_id,
        endorsed_by: userProfile?.user?.id,
        is_endorsed: !isEndorsementChecked,
      });
      setIsModalVisible(false);
      if (response.result.success) setIsEndorsementChecked(!isEndorsementChecked);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ 
      cursor: "pointer", 
      marginBottom: 1, 
      marginTop: 1, 
      padding: "5px", 
      borderRadius: 1, 
      border: shouldExpand ? (isExpanded ? 2 : 1) : 0, // Invisible borders when not expandable
      borderColor: shouldExpand ? (isExpanded ? "#e8f5e9" : "#D3D3D3") : "transparent",
      boxShadow: isExpanded ? "0px 6px 18px rgba(0, 0, 0, 0.1)" : "none",
      paddingBottom: isExpanded ? 2 : "5px", // Add empty space when expanded, revert to original padding when closed
    }}>
      {/* User info header */}
      <Box
        sx={{ display: "flex", flexFlow: "row wrap", alignItems: "center" }}
        onClick={() => {
          if (onClick && !isExpanded) onClick();
          if (shouldExpand) setIsExpanded(!isExpanded);
        }}
      >
        {customerUser.photo ? (
          <img 
            src={customerUser.photo} 
            alt="profileimg" 
            style={{ 
              marginRight: 15, 
              objectFit: "cover", 
              width: 40, 
              height: 40, 
              borderRadius: "50%" 
            }} 
          />
        ) : (
          <PersonIcon style={{ marginRight: 15, width: 40, height: 40,  }} />
        )}
        <Typography sx={{ 
          paddingLeft: "0", 
          fontWeight: isExpanded ? "bold" : "normal" // Bold when expanded
        }}>
          {name}
        </Typography>
        {is_kyced && <img style={{ height: 10, marginLeft: 2, marginRight: 10 }} alt="identity validated" src="/images/GoldWreath.jpg" />}
        {sender === false && status === 4 && !fromEditOrDetail && <PendingIcon sx={{ color: "teal" }} />}
      </Box>

      {/* Expanded content */}
      {isExpanded && (
        <>
          <Box sx={{ display: "flex", flexFlow: "column wrap", alignItems: "center", mt: 2, ml: 2, mr: 2 }}>
            
            {/* Display artist_type */}
              {customerUser.artist_type && (
                <Typography sx={{ textAlign: "center", mb: 2, fontWeight: "bold"  }}>
                  {customerUser.artist_type}
                </Typography>
              )}
            
            {/* User details */}
            {customerUser?.photo && <img style={{ marginBottom: 15, objectFit: "cover", width: 200 }} src={customerUser.photo} alt="profileimg" />}
            {customerUser?.artist_website && (
              <Typography color="#006cd0" onClick={() => window.open(customerUser.artist_website, '_blank')} sx={{ textAlign: "center", mb: 2 }}>
                {customerUser.artist_website.split("//")[1] || customerUser.artist_website.split("//")[0]}
              </Typography>
            )}

            {/* User inputs section with light green background */}
            <Box sx={{ backgroundColor: "#e8f5e9", width: "100%", padding: "15px", borderRadius: 1 }}>
              {status !== 4 && secondLayer === false && user.id !== userProfile.user.id && 
               is_kyced && userProfile?.user?.is_kyced && fromFame && showEndorsement && (
                <FormControlLabel
                  control={<Switch checked={isEndorsementChecked} sx={utils.switchStyle} onChange={() => setIsModalVisible(true)} />}
                  label="Endorse their professional identity"
                  sx={{ color: "#70768a", margin: 0, display: "block" }}
                />
              )}
              {is_kyced && showOrder && (
                <FormControlLabel
                  control={<Switch checked={isChecked} sx={utils.switchStyle} onChange={() => setIsChecked(!isChecked)} {...label} />}
                  label="Pin this name to the top of your community list."
                  sx={{ color: "#70768a", margin: 0, display: "block", mt: 1 }}
                />
              )}
              {setSelectedOrderItems && (
                <select
                  style={{ width: 50, marginTop: 10 }}
                  value={order.toString()}
                  onChange={setSelectedOrderItems}
                  disabled={selectedOrderItems.length === 3 && !selectedOrderItems.includes(order.toString())}
                >
                  {possibleOrder.map((item) => <option key={item} value={item}>{item}</option>)}
                </select>
              )}
              {is_kyced && showOrder && !isLoading && (
                <ArtisButton
                  onClick={async () => {
                    setIsLoading(true);
                    const ord = isChecked ? maxOrder + 1 : 0;
                    await updateOrder({ fame, order: ord, allConnections, getAllFameConnections, setIsLoading, setIsExpanded });
                    if (isChecked) setMaxOrder(ord);
                  }}
                  name="Update"
                  sx={{ color: "blue", fontSize: "1.2rem", mt: 2 }}
                />
              )}
              {is_kyced && showOrder && <Loader isLoading={isLoading} />}
              {onDelete && (
                <Box sx={{ mt: 2, textAlign: "center" }}>
                  <DeleteIcon sx={{ color: "red" }} onClick={onDelete} />
                </Box>
              )}
            </Box>
          </Box>

          {sender === false && status === 4 && !fromEditOrDetail && (
            <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "center", mt: 2, ml: 2, mr: 2 }}>
              <ArtisButton name="Accept Invitation" textColor="green" onClick={() => acceptInvite(fame, getAllFameConnections)} sx={{ fontSize: "1.5rem" }} />
              <ArtisButton name="Decline invitation" textColor="red" onClick={() => setIsVisibleReject(true)} sx={{ fontSize: "1.5rem", ml: 2 }} />
            </Box>
          )}

          <Dialog open={isVisibleReject} onClose={() => setIsVisibleReject(false)}>
            <DialogTitle sx={{ textAlign: "center" }}>Are you sure you want to decline {name} community invitation?</DialogTitle>
            <DialogActions>
              <ArtisButton onClick={() => { deleteInvite(fame, getAllFameConnections); setIsVisibleReject(false); }} name="Yes" sx={{ fontSize: "1.5rem" }} />
              <ArtisButton onClick={() => setIsVisibleReject(false)} name="No" sx={{ fontSize: "1.5rem" }} />
            </DialogActions>
          </Dialog>

          {sender === true && status === 4 && !fromEditOrDetail && (
            <Box sx={{ display: 'flex', flexFlow: "row wrap", backgroundColor: '#e8f5e9', padding: "5px", margin: "20px 20px", justifyContent: "center" }}>
              <Typography sx={{ color: 'teal', fontSize: "18px" }}>Awaiting Invitation Acceptance</Typography>
            </Box>
          )}

          {children}
        </>
      )}

      <center>
        <FameModal
          isVisible={isModalVisible}
          setIsVisible={setIsModalVisible}
          handleConfirm={setEndorsement}
          title={isEndorsementChecked
            ? `Please confirm that you are removing your public endorsement of ${name} professional identity.`
            : `Please confirm that you are publicly endorsing ${name} professional identity.`}
          actionButtonText="Confirm"
        />
      </center>
    </Box>
  );
};

// Container component
export const FameUserItemWrapper = ({
  user,
  showOrder = true,
  allConnections,
  name,
  maxOrder,
  setMaxOrder,
  is_kyced,
  order,
  onDelete,
  selectedOrderItems,
  setSelectedOrderItems,
  shouldExpand,
  userProfile,
  isPending = false,
  fame,
  getAllFameConnections,
  fromFame = false,
  endorsedBy,
  totalConnections,
  sender,
  status,
  fromEditOrDetail,
  secondLayer = false,
  actualUser
}) => {
  // State declarations
  const [subConnections, setSubConnections] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userClassification] = useState(user.endorsed_by);
  const [isVisible, setIsVisible] = useState(false);
  const [knownFor, setKnownFor] = useState([]);
  const [allExhibitions, setAllExhibitions] = useState({});

  // Update maxOrder on mount
  useEffect(() => {
    if (order > maxOrder) setMaxOrder(order);
  }, [order, maxOrder, setMaxOrder]);

  if (!user.is_active) return null;

  // Check if user can be invited
  const shouldShowInvitationButton = (userId) =>
    !totalConnections?.some((collection) => collection.user.user_id === userId);

  const handleInvite = () => setIsVisible(true);

  // Fetch sub-connections on click
  const handleClick = async () => {
    if (userId === user.user_id) return;
    setUserId(user.user_id);
    try {
      const response = await UserService.getFameConnections(`?id=${user.user_id}`);
      if (response.result) {
        const verified_connections = [];
        const unverified_connections = [];
        let toUpdate = { [user.user_id]: response.result.exhibitions };
        setAllExhibitions(toUpdate);

        response.result.all_fame.forEach((fame) => {
          const customerUser = user.user_id === fame?.sender[0].user_id ? fame?.invitee[0] : fame?.sender[0];
          if (user.user_id === customerUser.id) return;
          if (customerUser.is_kyced) {
            verified_connections.push({ ...fame, user: customerUser });
          } else {
            unverified_connections.push({ ...fame, user: customerUser });
          }
        });

        setSubConnections({ verified_connections, unverified_connections });
        setKnownFor({
          worksKnownFor: response.result.works_known_for || [],
          collaboratorsKnownFor: response.result.collaborators_known_for || [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Classify connections
  const professionalEndorsements = [];
  const creativeCommunity = [];
  const classifyConnections = (connections) => {
    connections?.forEach((con) => {
      const endorsedByArray = userClassification ? userClassification.split(',') : [];
      const isEndorsedByUser = endorsedByArray.includes(`${con.user.user_id}`);
      const connectionArray = isEndorsedByUser ? professionalEndorsements : creativeCommunity;
      
      if (!con.user.is_active || con.status === 4) return;
      
      connectionArray.push(
        secondLayer ? (
          <FameUserItem
            key={con.id}
            user={con.user}
            name={`${con.user.first_name} ${con.user.last_name}` || con.invitee_name}
            order={con.order}
            is_kyced={con.user.is_kyced}
            shouldExpand={false}
            showOrder={false}
            userProfile={userProfile}
            fromFame={fromFame}
            showEndorsement={false}
            endorsedBy={endorsedBy}
            totalConnections={totalConnections}
            getAllFameConnections={getAllFameConnections}
            actualUser={user}
            fromEditOrDetail={fromEditOrDetail}
            secondLayer={true}
          />
        ) : (
          <FameUserItemWrapper
            key={con.id}
            user={con.user}
            name={`${con.user.first_name} ${con.user.last_name}` || con.invitee_name}
            order={con.order}
            is_kyced={con.user.is_kyced}
            shouldExpand={status !== 4}
            showOrder={false}
            userProfile={userProfile}
            fromFame={fromFame}
            showEndorsement={false}
            endorsedBy={endorsedBy}
            totalConnections={totalConnections}
            getAllFameConnections={getAllFameConnections}
            actualUser={user}
            fromEditOrDetail={fromEditOrDetail}
            secondLayer={true}
          />
        )
      );
    });
  };

  classifyConnections(subConnections?.verified_connections);
  classifyConnections(subConnections?.unverified_connections);

  return (
    <FameUserItem
      user={user}
      name={name}
      order={order}
      maxOrder={maxOrder}
      setMaxOrder={setMaxOrder}
      is_kyced={is_kyced}
      selectedOrderItems={selectedOrderItems}
      onDelete={onDelete}
      setSelectedOrderItems={setSelectedOrderItems}
      onClick={handleClick}
      shouldExpand={shouldExpand}
      isPending={isPending}
      fame={fame}
      getAllFameConnections={getAllFameConnections}
      allConnections={allConnections}
      showOrder={showOrder}
      userProfile={userProfile}
      fromFame={fromFame}
      showEndorsement={true}
      endorsedBy={endorsedBy}
      totalConnections={totalConnections}
      sender={sender}
      status={status}
      actualUser={user}
      fromEditOrDetail={fromEditOrDetail}
      secondLayer={secondLayer}
    >
      <Box sx={{ 
        marginLeft: { xs: 0, md: 0, lg: 2.5 }, 
        marginRight: { xs: 0, md: 0, lg: 2.5 } 
      }}>
        {(knownFor.worksKnownFor?.length > 0 || knownFor.collaboratorsKnownFor?.length > 0) && (
          <Box component="p" fontSize="0.8rem" color="gray" sx={{ marginLeft: "0px" }}>KNOWN FOR</Box>
        )}
        {knownFor.worksKnownFor?.map((work, index) => (
          <Box key={index} sx={{ marginBottom: 1, padding: "5px", borderBottom: knownFor.worksKnownFor?.length + knownFor.collaboratorsKnownFor?.length > 1 ? 1 : 0, borderColor: "#D3D3D3", marginTop: index === 0 ? 0 : 1 }}>
            <Box sx={{ display: "flex", flexFlow: "row wrap", alignItems: "center" }}>
              {work.work_copy_name ? (
                <img style={{ marginRight: 15, objectFit: "cover", width: 120}} src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(work.work_copy_name)}`} alt="profileimg" />
              ) : (
                <ArtTrackIcon sx={{ marginRight: "15px" }} />
              )}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <button
                  type="button"
                  style={{ background: "none", border: "none", padding: "0", color: "#808080", cursor: "pointer", textAlign: "left" }}
                  onClick={() => window.open(`/works/${work.artis_code}`, '_blank')}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "16px", marginBottom: 0 }}>
                    {work.title}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "#808080", marginBottom: 0, lineHeight: 1 }}>
                    {work.description}
                  </Typography>
                  <br />
                </button>
              </Box>
            </Box>
          </Box>
        ))}
        {knownFor.collaboratorsKnownFor?.map((work, index) => (
          <Box key={index} sx={{ marginBottom: 1, padding: "5px", borderBottom: knownFor.worksKnownFor?.length + knownFor.collaboratorsKnownFor?.length > 1 ? 1 : 0, borderColor: "#D3D3D3", marginTop: index === 0 ? 0 : 1 }}>
            <Box sx={{ display: "flex", flexFlow: "row wrap", alignItems: "center" }}>
              {work.work_copy_name ? (
                <img style={{ marginRight: 15, objectFit: "cover", width: 120}} src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(work.work_copy_name)}`} alt="profileimg" />
              ) : (
                <ArtTrackIcon sx={{ marginRight: "15px" }} />
              )}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <button
                  type="button"
                  style={{ background: "none", border: "none", padding: "0", color: "#808080", cursor: "pointer", textAlign: "left" }}
                  onClick={() => window.open(`/works/${work.artis_code}`, '_blank')}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "16px", marginBottom: 0 }}>
                    {work.work_title}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "#808080", marginBottom: 0, lineHeight: 1 }}>
                    {work.work_description}
                  </Typography>
                  <br />
                </button>
              </Box>
            </Box>
          </Box>
        ))}
        {(knownFor.worksKnownFor?.length > 0 || knownFor.collaboratorsKnownFor?.length > 0) && (
          <Typography fontSize="0.6rem" gutterBottom width="100%" float="right" textAlign="right">
            TAP ON WORK TO SEE ITS REGISTRATION
          </Typography>
        )}

        {professionalEndorsements.length > 0 && (
          <>
            <Box component="p" fontSize="0.8rem" color="gray" sx={{ marginLeft: "0px" }}><br />PROFESSIONALLY ENDORSED BY</Box>
            {professionalEndorsements}
          </>
        )}

        {user?.artist_bio && (
          <Box sx={{ mt: 2, mb: 2 }}>
            <br /><br />
            <Box component="p" fontSize="0.8rem" color="gray">BIOGRAPHY</Box>
            {user.artist_bio.split('\n').map((paragraph, index) => (
              <Typography key={index} sx={{ fontSize: "14.5px", color: "gray", mb: paragraph ? 1 : 0 }}>
                {paragraph}
              </Typography>
            ))}
          </Box>
        )}

        {allExhibitions[user.id]?.length > 0 && (
          <>
            <Box component="p" fontSize="0.8rem" color="gray" sx={{ marginLeft: "0px" }}>
              <br /><br />{allExhibitions[user.id][0].eventname.toUpperCase()}
            </Box>
            {allExhibitions[user.id].map((exhibition) => (
              <Exhibition key={exhibition.id} data={exhibition} />
            ))}
          </>
        )}

        {creativeCommunity.length > 0 && (
          <>
            <Box component="p" fontSize="0.8rem" color="gray" sx={{ marginLeft: "0px", marginTop: 2.2 }}><br /><br />COMMUNITY</Box>
            {creativeCommunity}
          </>
        )}

        {user.id !== userProfile.user.id && shouldShowInvitationButton(user?.user_id) && !fromEditOrDetail && (
          <ArtisButton
            name={`Invite ${name} to join your community`}
            textColor="teal"
            onClick={handleInvite}
            sx={{ 
              fontSize: "1.5rem", 
              backgroundColor: "teal", 
              mt: 2,
              ml: { xs: 0.75, md: 2, lg: 2.5 },
              mr: { xs: 0.75, md: 2, lg: 2.5 }
            }}
          />
        )}

        <Dialog open={isVisible} onClose={() => setIsVisible(false)}>
          <DialogTitle sx={{ textAlign: "center" }}>Are you sure you want to invite {name}?</DialogTitle>
          <DialogActions>
            <ArtisButton
              name="Yes"
              onClick={() => {
                sendInvitees(actualUser, user, getAllFameConnections);
                setIsVisible(false);
              }}
              sx={{ fontSize: "1.5rem" }}
            />
            <ArtisButton name="No" onClick={() => setIsVisible(false)} sx={{ fontSize: "1.5rem" }} />
          </DialogActions>
        </Dialog>
      </Box>
    </FameUserItem>
  );
};
