import { CircularProgress, Container } from '@mui/material';

export default function Loading(props) {
    return (
        <Container style={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh' // Optional: makes it full viewport height
        }}>
            <div>
                <CircularProgress thickness={1.8} size="3.5rem" color="success"/>
            </div>
        </Container>
    );
}
