/**
 * AiAgent Component
 * 
 * This component implements an AI-powered chat interface for the Artis.app platform.
 * Key features include:
 * - Real-time messaging with an AI assistant
 * - User profile management (AI bio and goals) with modal forms
 * - Message history persistence in localStorage (last 10 messages)
 * - Responsive design for different screen sizes
 * - Markdown support for message formatting
 * - Typing indicators and loading states
 * - Error handling with toast notifications
 * - Integration with UserService and WorkService APIs
 * 
 * The component maintains a conversation context and allows users to:
 * - Chat about creative work and projects
 * - Update their AI bio and goals
 * - View message history with proper formatting
 * - Receive personalized responses based on their profile
 */

import React, { useEffect, useRef, useState } from "react";
// Import React and necessary hooks for state management, effects, and refs

import {
  Box, CircularProgress, Dialog, DialogActions, DialogContent,
  IconButton, List, ListItem, ListItemText, Paper, TextField,
  Typography, useMediaQuery, useTheme, InputAdornment,
} from "@mui/material";
// Import Material-UI components for styling and layout

import { Controller, useForm } from "react-hook-form";
// Import form handling utilities from react-hook-form

import ArtisInput from "../components/inputs/textfield";
// Custom input component

import UserService from "../api/auth";
import WorkService from "../api/service";
// Import API services for user authentication and work-related operations

import { toast } from "react-toastify";
// Import toast notifications for user feedback

import { useUser } from "../hooks/user";
// Custom hook for user context management

import ArtisButton from "../components/buttons/button";
// Custom button component

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpIcon from "@mui/icons-material/ArrowUpward";
// Material-UI icons for navigation and sending messages

import ReactMarkdown from "react-markdown";
// For rendering markdown content in messages

export default function AiAgent(props) {
  // Main component function that takes props

  // Get user context and setter from custom hook
  const { user, setUser } = useUser();
  
  // Initial bot welcome message
  const botMessage = {
    sender: "bot",
    text: `Hi ${user?.profile?.first_name}, how can we help you? You can ask us how to register a work to protect it or to create a Certificate of Authenticity. We can help you brainstorm ideas or organize your project. To maintain your confidentiality, we never share these discussions with anyone else, nor train our AI model on your work. You're safe to ask us anything. --your Artis.app AI Team.`,
    timestamp: new Date(),
  };

  // Initialize form handling with default values from user profile
  const { handleSubmit, control } = useForm({
    defaultValues: {
      ai_bio: user?.profile?.user?.ai_bio,
      ai_goals: user?.profile?.user?.ai_goals,
    },
  });

  // State management for chat functionality
  const [messages, setMessages] = useState([botMessage]); // Chat message history
  const [inputValue, setInputValue] = useState(""); // Current message input
  const [modalInputValue, setModalInputValue] = useState(""); // Modal input value
  const [isTyping, setIsTyping] = useState(false); // Bot typing indicator
  const [loading, setLoading] = useState(false); // Loading state
  const [openAiBioModal, setOpenAiBioModal] = useState(false); // Bio modal visibility
  const [openAiGoalsModal, setOpenAiGoalsModal] = useState(false); // Goals modal visibility
  
  const listRef = useRef(null); // Reference to chat container for scrolling
  
  // Media queries for responsive design
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Initial setup effect
  useEffect(() => {
    // Show modals if user profile data is missing
    if (!user?.profile?.user?.ai_bio) {
      setOpenAiBioModal(true);
    } else if (!user?.profile?.user?.ai_goals) {
      setOpenAiGoalsModal(true);
    }
    
    // Load stored messages from localStorage
    const storedMessages = localStorage.getItem("artis_messages");
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }
  }, [user]);

  // Scroll to bottom and save messages effect
  useEffect(() => {
    // Auto-scroll to latest message
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
    // Save last 10 messages to localStorage
    if (messages.length > 0 && messages.length <= 10) {
      localStorage.setItem("artis_messages", JSON.stringify(messages));
    } else if (messages.length > 10) {
      localStorage.setItem(
        "artis_messages",
        JSON.stringify(messages.slice(-10))
      );
    }
  }, [messages]);

  // Cleanup effect for page unload
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("artis_messages"); // Clear messages on page unload
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Handle AI bio update
  const handleUpdateBio = async (data) => {
    const response = await UserService.updateAIBio_AIGoals(data);
    if (response?.result) {
      // Update user context with new bio
      setUser((prevUser) => ({
        ...prevUser,
        profile: {
          ...prevUser.profile,
          user: {
            ...prevUser.profile.user,
            ai_bio: response.result.data.ai_bio,
          },
        },
      }));
      toast.success("AI Bio updated successfully!");
      setOpenAiBioModal(false);
      // Show goals modal if not set
      if (!user?.profile?.user?.ai_goals) {
        setTimeout(() => setOpenAiGoalsModal(true), 1000);
      }
      setModalInputValue("");
    } else if (response?.error) {
      toast.error("error updating Ai bio");
    }
  };

  // Handle AI goals update
  const handleUpdateGoals = async (data) => {
    const response = await UserService.updateAIBio_AIGoals(data);
    if (response?.result) {
      setUser((prevUser) => ({
        ...prevUser,
        profile: {
          ...prevUser.profile,
          user: {
            ...prevUser.profile.user,
            ai_goals: response.result.data.ai_goals,
          },
        },
      }));
      toast.success("AI Goals updated successfully!");
      setOpenAiGoalsModal(false);
    } else if (response?.error) {
      toast.error("error updating Ai goals");
    }
  };

  // Transform message history for API request
  const transformMessages = (messages) => {
    return messages.reduce((history, current, index, array) => {
      if (current.sender === "user" && array[index + 1]?.sender === "bot") {
        history.push({
          question: current.text,
          answer: array[index + 1].text,
        });
      }
      return history;
    }, []);
  };

  // Handle sending user message
  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;

    const userMessage = {
      sender: "user",
      text: inputValue,
      timestamp: new Date(),
    };
    
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputValue("");
    setIsTyping(true);

    try {
      const history = transformMessages(messages);
      const response = await WorkService.getQueryAgent({ query: inputValue, history });
      
      setIsTyping(false);
      
      const botMessage = {
        sender: "bot",
        text: response?.result,
        timestamp: new Date(),
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setLoading(false);
    } catch (error) {
      console.error("Error sending message:", error);
      // Add error message to chat
      setMessages((prevMessages) => [...prevMessages, {
        sender: "bot",
        text: "Failed to send message. Please try again.",
        timestamp: new Date(),
      }]);
    }
    setIsTyping(false);
  };

  // JSX render
  return (
    <Box
      sx={{
        height: isSmallScreen ? "65vh" : "80vh",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: "2%",
      }}
    >
      {/* Chat Window */}
      <Paper
        ref={listRef}
        elevation={3}
        sx={{
          height: "100%",
          overflowY: "auto",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          borderRadius: 2,
          position: "relative",
          overflowX: "hidden",
        }}
      >
        <List>
          {messages.map((message, index) => (
            <ListItem
              key={index}
              sx={{
                display: "flex",
                flexDirection: message.sender === "user" ? "row-reverse" : "row",
                alignItems: "flex-start",
                pl: message.sender === "user" ? 8 : 0,
              }}
            >
              {message.sender !== "user" && (
                <img
                  style={{ height: 40, marginTop: "15px" }}
                  alt="identity validated"
                  src="/images/ArtisManSmall.jpg"
                />
              )}
              <ListItemText
                sx={{
                  textAlign: message.sender === "user" ? "right" : "left",
                  borderRadius: message.sender === "user" ? 6 : 0,
                  backgroundColor: message.sender === "user" ? "#DDDDDD" : "",
                  display: "inline-block",
                  pt: message.sender !== "user" ? 0.5 : 0,
                  maxWidth: "fit-content",
                  wordWrap: "break-word",
                  letterSpacing: "normal"
                }}
              >
                <Typography
                  sx={{
                    width: "fit-content",
                    display: "inline-block",
                    px: 2,
                    py: message.sender === "user" ? 1 : 0,
                    textAlign: "left",
                    wordBreak: "break-word",
                  }}
                >
                  <ReactMarkdown linkTarget="_blank">
                    {message.text}
                  </ReactMarkdown>
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
        {isTyping && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              marginTop: 1,
            }}
          >
            <CircularProgress size={20} />
            <Typography variant="body2" color="text.secondary">
              {"    "}Artis is thinking...
            </Typography>
          </Box>
        )}
      </Paper>

      {/* Message Input */}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          bottom: "20px",
          justifyContent: "center",
          width: "100%",
          "& .css-zoz8jl-MuiFormControl-root-MuiTextField-root .MuiInputBase-root": {
            maxWidth: "100%"
          }
        }}
      >
        <TextField
          fullWidth
          placeholder={`${isMediumScreen ? "Type a message" : "Type a message to your Artis Team..."}`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          multiline
          maxRows={5}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box
                  onClick={handleSendMessage}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    width: "33px",
                    height: "33px",
                    backgroundColor: loading ? "grey" : "teal",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                  disabled={isTyping}
                >
                  <ArrowUpIcon sx={{ color: "white" }} />
                </Box>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": { borderColor: "teal" },
            },
            "& .MuiInputBase-root": {
              overflowY: "auto",
              maxHeight: "170px",
              alignSelf: "center",
              borderRadius: "10px",
            },
          }}
        />
      </Box>

      {/* AI Bio Modal */}
      <Dialog
        open={openAiBioModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setOpenAiBioModal(false);
          }
        }}
      >
        <DialogContent sx={{ position: "relative" }}>
          <Box
            m={4}
            sx={{ flexGrow: 1 }}
            component="form"
            ml={1.5}
            mr={1.5}
            noValidate
          >
            <Typography fontSize="1rem">
              Hi {user?.profile?.first_name}, I’d love to be your ultimate creative assistant...
            </Typography>
            <Controller
              control={control}
              name="ai_bio"
              render={({ ref, formState, fieldState, ...rest }) => (
                <ArtisInput
                  label="AI Bio"
                  id="ai-bio"
                  multiline
                  InputLabelProps={{
                    required: false,
                    sx: { color: "teal" },
                    shrink: true,
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value.slice(0, 768);
                    rest.field.onChange(newValue);
                    setModalInputValue(newValue);
                  }}
                  value={rest.field.value}
                  fontFamily="Montserrat', sans-serif"
                  {...rest}
                  fontSize="0.5 rem"
                  maxLength={500}
                />
              )}
            />
          </Box>
          <IconButton
            sx={{ position: "absolute", top: "10px", left: "20px" }}
            onClick={() => props.setTab(0)}
          >
            <ArrowBackIcon />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <ArtisButton
            onClick={handleSubmit((data) => handleUpdateBio(data))}
            name="Update"
            size="medium"
            id="update"
            sx={{ fontSize: "1.5rem" }}
            disabled={!modalInputValue?.trim(" ")}
          />
        </DialogActions>
      </Dialog>

      {/* AI Goals Modal */}
      <Dialog
        open={openAiGoalsModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setOpenAiGoalsModal(false);
          }
        }}
      >
        <DialogContent sx={{ position: "relative" }}>
          <Box
            m={4}
            sx={{ flexGrow: 1 }}
            component="form"
            ml={1.5}
            mr={1.5}
            noValidate
          >
            <Typography fontSize="1rem">
              Ok {user?.profile?.first_name}, Now, to really best help you...
            </Typography>
            <Controller
              control={control}
              name="ai_goals"
              render={({ ref, formState, fieldState, ...rest }) => (
                <ArtisInput
                  label="AI Goals"
                  id="ai-goals"
                  multiline
                  InputLabelProps={{
                    required: false,
                    sx: { color: "teal" },
                    shrink: true,
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value.slice(0, 768);
                    rest.field.onChange(newValue);
                    setModalInputValue(newValue);
                  }}
                  value={rest.field.value}
                  fontFamily="Montserrat', sans-serif"
                  {...rest}
                  fontSize="0.5 rem"
                  maxLength={500}
                />
              )}
            />
          </Box>
          <IconButton
            sx={{ position: "absolute", top: "10px", left: "20px" }}
            onClick={() => props.setTab(0)}
          >
            <ArrowBackIcon />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <ArtisButton
            onClick={handleSubmit((data) => handleUpdateGoals(data))}
            name="Update"
            size="medium"
            id="update"
            sx={{ fontSize: "1.5rem" }}
            disabled={!modalInputValue?.trim(" ")}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
}
