import React from "react";
import { TextField } from "@mui/material";

export default function ArtisInput(props, maxLength) {
  return (
    <TextField
      variant="standard"
      style={{
        textTransform: "none",
        fontFamily: props.fontFamily || "Montserrat, sans-serif",
      }}
      InputProps={{
        style: {
          fontSize: props.fontSize || "1em",
          fontFamily: props.fontFamily || "Montserrat, sans-serif",
        },
        inputProps: { maxLength },
      }}
      margin="normal"
      fullWidth
      {...props}
    />
  );
}
