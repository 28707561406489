import { useEffect, useState, useRef, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { Container, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import ArtisInput from "../components/inputs/textfield";
import ArtisButton from "../components/buttons/button";
import { AuthService } from "../api";
import { useUser } from "../hooks/user";
import Loader from "../components/buttons/loader";
import heic2any from "heic2any";

export const FormikField = ({ options }) => {
  return (
    <Field name={options.name} placeholder={options.placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => (
        <>
          <ArtisInput
            label={options.label}
            disabled={options.disabled}
            type={options.type || "text"}
            onClick={options.onClick}
            {...options}
            {...field}
          />
          {meta.touched && meta.error && (
            <Typography color="red">{meta.error}</Typography>
          )}
        </>
      )}
    </Field>
  );
};

const initVal = {
  user: { password: "", confirmPassword: "", email: "", artist_type: "Artist" },
  profile: {
    first_name: "",
    last_name: "",
    organization: "",
    website: "",
    photo: null,
    photoName: "",
    bio: "",
  },
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    password: Yup.string().required("Field is required").min(6, "Min 6 chars"),
    confirmPassword: Yup.string()
      .required("Field is required")
      .oneOf([Yup.ref("password"), null], "Password do not match"),
  }),
  profile: Yup.object().shape({
    first_name: Yup.string().nullable().required("Field is required"),
    last_name: Yup.string().nullable().required("Field is required"),
    website: Yup.string()
      .nullable()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
        "Please enter a valid website URL"
      ),
    photoName: Yup.mixed().test(
      "fileType",
      "Please select an image",
      (value) => {
        if (value === "") return true;
        if (value === undefined) return true;
        return (
          value.toLowerCase().endsWith("jpg") ||
          value.toLowerCase().endsWith("jpeg") ||
          value.toLowerCase().endsWith("png") ||
          value.toLowerCase().endsWith("heic") ||
          value.toLowerCase().endsWith("gif")
        );
      }
    ),
  }),
});

export default function SignUp(props) {
  const uploadRef = useRef();

  const { uid, inviteCode } = useParams();
  const userHook = useUser();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(initVal);
  const [errorData, setErrorData] = useState(null);

  const [imageBeingConverted, setImageBeingConverted] = useState(false);

  const fetchUser = async () => {
    try {
      const result = await AuthService.signUpWithInviteCode(uid, inviteCode);
      setInitialValues({
        user: { ...initialValues.user, email: result?.user?.email },
        profile: { ...initialValues.profile, ...result?.profile },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserCallback = useCallback(() => {
    if (uid && inviteCode) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, inviteCode]);
  useEffect(() => {
    fetchUserCallback();
  }, [fetchUserCallback]);

  const handleCreateAccount = async (values) => {
    setIsLoading(true);
    const { user, profile } = values;

    const userProfile = {
      ...profile,
      artist_bio: profile.bio,
      artist_website: profile.website,
    };
    const updatedUser = {
      ...user,
      username: `${profile.first_name}${profile.last_name}${profile.id}`,
    };
    const formData = new FormData();
    formData.append(`profile`, JSON.stringify(userProfile));
    formData.append(`user`, JSON.stringify(updatedUser));

    if (profile.photo) {
      formData.append(`photo`, profile.photo);
    }

    try {
      const response = await AuthService.signUp(uid, inviteCode, formData);
      if (response.error) {
        setErrorData({
          message: "Something went wrong. Please try again later.",
        });
      }
      if (response.result) {
        setIsLoading(false);
        if (response?.result?.is_active === true) {
          await userHook.login(
            user.email,
            user.password,
            profile.website,
            profile.bio,
            profile.photo
          );
          history.push("/");
        } else {
          history.push("/signup/verify");
        }
      }
    } catch (error) {
      setErrorData(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (event, setFieldValue, setFieldTouched) => {
    if (event?.target && event.target?.files.length) {
      const name = event.target.files[0].name;
      setFieldValue("profile.photoName", name);

      setTimeout(() => {
        setFieldTouched("profile.photoName", true);
      }, 0);

      if (name.toLowerCase().endsWith("heic")) {
        setImageBeingConverted(true);

        const convertedImageBlob = await heic2any({
          blob: event.target.files[0],
          toType: "image/jpeg",
        });

        const convertedImage = new File(
          [convertedImageBlob],
          event.target.files[0].name.replace(".heic", "").replace(".HEIC", "") +
            ".jpg",
          {
            type: "image/jpeg",
            lastModified: event.target.files[0].lastModified,
          }
        );

        setFieldValue("profile.photo", convertedImage);
        setImageBeingConverted(false);
      } else {
        setFieldValue("profile.photo", event.target.files[0]);
      }
    }
  };

  return (
    <Box
      sx={{
        pt: 0,
      }}
    >
      <Container maxWidth="sm" style={{ textAlign: "center" }}>
        <Box mt={2}>
          {/* <Typography
            component="h6"
            gutterBottom
            fontSize="2.5rem"
            align="center"
          >
            Artis.app
          </Typography> */}

       <Box
            component="video"
            src={`${process.env.PUBLIC_URL}/images/SmallArtisMan.mov`}
            autoPlay
            loop
            muted
            sx={{
              objectFit: "cover",
              padding: 0,
              margin: 0,
              clipPath: "inset(1px 3px)",
              width: "150",
            }}
          />
          <br />
          <br />

          {!initialValues.user?.email && (
            <>
              <Typography
                component="h6"
                gutterBottom
                fontSize="1.5rem"
                align="center"
              >
                Woops. You already signed-up using this invite.
              </Typography>
              <Loader isLoading={isLoading} />
              <ArtisButton
                name="Back to Login"
                size="medium"
                onClick={() => history.push("/login")}
                sx={{
                  fontSize: "1.5rem",
                }}
              />
            </>
          )}

          {initialValues.user?.email && (
            <>
              <Typography
                component="h6"
                gutterBottom
                fontSize="1.2rem"
                align="center"
              >
                Complete the four steps below to get your free account
              </Typography>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleCreateAccount}
                enableReinitialize
              >
                {({
                  handleSubmit,
                  isValid,
                  setFieldValue,
                  setFieldTouched,
                  values,
                  dirty,
                  errors,
                }) => {
                  return (
                    <Form>
                      <Box alignItems="center" mr={5} ml={5} mt={2}></Box>
                      <Box textAlign="center" mb={3}>
                        <br />
                        <br />

                        <img
                          alt="Step One"
                          src="https://artis.app/images/Artis_one.jpg"
                          width="100px"
                          height="123px"
                        />
                        <p>Basic info</p>
                        <Box
                          display="grid"
                          gridTemplateColumns="1fr 1fr"
                          gap={4}
                        >
                          <Box gridColumn="span 1">
                            <FormikField
                              options={{
                                name: "user.password",
                                label: "Password",
                                type: "password",
                              }}
                            />
                          </Box>
                          <Box gridColumn="span 1">
                            <FormikField
                              options={{
                                name: "user.confirmPassword",
                                label: "Confirm Password",
                                type: "password",
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                          display="grid"
                          gridTemplateColumns="1fr 1fr"
                          gap={4}
                        >
                          <Box gridColumn="span 1">
                            <FormikField
                              options={{
                                name: "profile.first_name",
                                label: "First name",
                              }}
                            />
                          </Box>
                          <Box gridColumn="span 1">
                            <FormikField
                              options={{
                                name: "profile.last_name",
                                label: "Last name",
                              }}
                            />
                          </Box>
                        </Box>
                      
                        <br />
                        <br />

                        <FormikField
                          options={{
                            name: "user.email",
                            label: "Email",
                            disabled: true,
                          }}
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <img
                          alt="Step Two"
                          src="https://artis.app/images/Artis_two.jpg"
                          width="100px"
                          height="123px"
                        />
                        <p>
                          OPTIONAL: Enhance your public profile on Artis.app by adding your photo, website, and biography. 
                          A detailed profile showcases your professionalism, promotes you, can raise the value of your work, and helps you stand out in the creative community. Or you can update these items later in Settings.
                        </p>
                        <FormikField
                          options={{
                            name: "profile.website",
                            label: "Website (https://example.com)",
                            placeholder: "https://www.example.com",
                          }}
                        />

                        <FormikField
                          options={{
                            name: "profile.photoName",
                            label: "Profile Photo",
                            onClick: () => uploadRef.current.click(),
                            disabled: imageBeingConverted,
                          }}
                        />
                        <input
                          id="upload_photo"
                          type="file"
                          ref={uploadRef}
                          onChange={(e) =>
                            handleFileUpload(e, setFieldValue, setFieldTouched)
                          }
                          hidden
                        />

                        {(values.profile.photo &&
                          !errors.profile?.photoName &&
                          !imageBeingConverted) === true ? (
                          <Box
                            textAlign="center"
                            sx={{
                              mb: "2%",
                            }}
                          >
                            <img
                              src={URL.createObjectURL(values.profile.photo)}
                              style={{ maxHeight: "200px", maxWidth: "200px" }}
                              alt="ProfileImage"
                            />
                          </Box>
                        ) : (
                          <Loader isLoading={imageBeingConverted}></Loader>
                        )}
                        <FormikField
                          options={{
                            name: "profile.bio",
                            label: "Bio ( you can write paragraphs here)",
                            multiline: true,
                          }}
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <img
                          alt="Step Two"
                          src="https://artis.app/images/Artis_three.jpg"
                          width="100px"
                          height="123px"
                        />
                        <p>
                          OPTIONAL: By default, Artis.app refers to you as an Artist. If
                          you want to change the name of your 
                          profession -- like
                          Writer, Designer, Inventor, Art Collector -- change it
                          below. Or you can update this later in Settings.
                        </p>
                        <FormikField
                          options={{
                            name: "user.artist_type",
                          }}
                        />

                        <br />
                        <br />
                        <br />
                        <br />
                        <img
                          alt="Step Three"
                          src="https://artis.app/images/Artis_four.jpg"
                          width="100px"
                          height="123px"
                        />

                        <p>Press the magic button... </p>
                        <ArtisButton
                          disabled={
                            !(
                              isValid &&
                              dirty &&
                              !isLoading &&
                              !imageBeingConverted
                            )
                          }
                          onClick={handleSubmit}
                          name="Create my Free Account"
                          size="medium"
                          id="sign_up"
                          sx={{
                            fontSize: "1.5rem",
                          }}
                          startIcon={isLoading && <CircularProgress />}
                        />
                        <br />
                        {errorData && (
                          <Typography
                            component="h6"
                            gutterBottom
                            align="center"
                            color="red"
                          >
                            {errorData.message}
                          </Typography>
                        )}

                        <br />
                        <br />
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
}
