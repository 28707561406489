import { CloudOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import CollapsibleBody from '../collapsible';
import ArtisButton from '../buttons/button';
import ArtisInput from '../inputs/textfield';
import Loader from '../buttons/loader';
import CountrySelect from '../country-select';

export default function ContactInfo(props) {
    const [country, setCountry] = useState(props.country || '');
    const [city, setCity] = useState(props.city || '');
    const [street1, setStreet1] = useState(props.street1 || '');
    const [street2, setStreet2] = useState(props.street2 || '');
    const [zip, setZip] = useState(props.zip || '');
    const [state, setState] = useState(props.state || '');
    const [phone, setPhone] = useState(props.phone || '');
    const [website, setWebsite] = useState(props.website || '');

    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        setIsLoading(true);
        setError('');

        // Validate URL only if website is provided and not empty
        if (website && website.trim() !== '') {
            const pattern = new RegExp('^(https?:\\/\\/)?'+
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ 
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ 
                '(\\?[;&a-z\\d%_.~+=-]*)?'+
                '(\\#[-a-z\\d_]*)?$','i'); //eslint-disable-line
            if (!pattern.test(website)) {
                setError('Please enter a valid URL.');
                setIsLoading(false);
                return;
            }
        }

        // Prepare contact info object
        const contactInfo = {
            country,
            city,
            street_1: street1,
            street_2: street2,
            zip_code: zip,
            state,
            phone_number: phone,
            artist_website: website.trim() === '' ? null : website, // Explicitly use null to clear
            artist_bio: props.bio?props.bio:null
        };

        // Log the data being sent for debugging
        console.log('Sending contact info to update:', contactInfo);

        try {
            const response = await props.handleContactInfoUpdate(contactInfo);
            console.log('Update response:', response);
            // If website was cleared, ensure local state reflects this
            if (website.trim() === '') {
                setWebsite('');
            }
        } catch (err) {
            console.error('Update error:', err);
            setError('Failed to update contact information. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <CollapsibleBody 
            expanded={props.openId === "contact"}
            id="contact"
            title="Contact Information"
            icon={<CloudOutlined />}
            handleClick={props.setOpenId}
            mainSx={{
                alignItems: "stretch",
                fontFamily: "'Bellefair', sans-serif;",
                fontWeight: "normal",
            }}
        >
            <Box container m={3}>
                <div>
                    <center>
                        <h3>Displayed on Proof of Registration, etc</h3>
                    </center>
                </div>
                <br />
                {error !== "" && (
                    <Box textAlign="center" color="red">
                        {error}
                    </Box>
                )}
                <Grid
                    container
                    spacing={1}
                    sx={{ 
                        flexGrow: 1,
                        justifyContent: "center",
                    }}
                    display="flex"
                    columns={2}
                    direction="row"
                >
                    <Grid
                        item
                        xs={1}
                        color="transparent"
                        sx={{
                            boxShadow: 0,
                            alignSelf: "flex-start"
                        }}
                    >
                        <ArtisInput
                            label="Website (https://example.com)"
                            value={website}
                            InputLabelProps={{
                                required: false, 
                                sx: {color: "teal"}, 
                                shrink: true
                            }}
                            onChange={(e) => setWebsite(e.target.value)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        sx={{
                            alignSelf: "flex-end"
                        }}
                    >
                        <ArtisInput
                            label="Phone"
                            value={phone}
                            InputLabelProps={{
                                required: false, 
                                sx: {color: "teal"}, 
                                shrink: true
                            }}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    sx={{ 
                        flexGrow: 1,
                        justifyContent: "center",
                    }}
                    display="flex"
                    rows={5}
                    column={1}
                    direction="column"
                >
                    <Grid
                        item
                        xs={1}
                        color="transparent"
                        sx={{
                            boxShadow: 0,
                            alignSelf: "stretch"
                        }}
                    >
                        <ArtisInput
                            label="Street 1"
                            value={street1}
                            InputLabelProps={{
                                required: false,
                                sx: {color: "teal"},
                                shrink: true
                            }}
                            onChange={(e) => setStreet1(e.target.value)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        color="transparent"
                        sx={{
                            boxShadow: 0,
                            alignSelf: "stretch"
                        }}
                    >
                        <ArtisInput
                            label="Street 2"
                            value={street2}
                            InputLabelProps={{
                                required: false,
                                sx: {color: "teal"},
                                shrink: true
                            }}
                            onChange={(e) => setStreet2(e.target.value)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        color="transparent"
                        sx={{
                            boxShadow: 0,
                            alignSelf: "stretch"
                        }}
                    >
                        <ArtisInput
                            label="City"
                            value={city}
                            InputLabelProps={{
                                required: false,
                                sx: {color: "teal"},
                                shrink: true
                            }}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        color="transparent"
                        sx={{
                            boxShadow: 0,
                            alignSelf: "stretch"
                        }}
                    >
                        <ArtisInput
                            label="State/Region"
                            value={state}
                            InputLabelProps={{
                                required: false,
                                sx: {color: "teal"},
                                shrink: true
                            }}
                            onChange={(e) => setState(e.target.value)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        color="transparent"
                        sx={{
                            boxShadow: 0,
                            alignSelf: "stretch"
                        }}
                    >
                        <ArtisInput
                            label="Zip / Region Code"
                            value={zip}
                            InputLabelProps={{
                                required: false,
                                sx: {color: "teal"},
                                shrink: true
                            }}
                            onChange={(e) => setZip(e.target.value)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        sx={{
                            boxShadow: 0,
                            alignSelf: "stretch"
                        }}
                        mb={2}
                    >
                        <CountrySelect 
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            name="Country"
                        />
                    </Grid>
                </Grid>
                <Loader isLoading={isLoading} />
                <ArtisButton
                    id="update_contacts"
                    onClick={handleSubmit}
                    name="Update"
                    sx={{
                        fontSize: "1.5rem",
                        mb: 3,
                    }}
                /> 
            </Box>      
        </CollapsibleBody>
    );
}
